// src/redux/reducers/idReducer.js

import {SET_ID, SET_VIDEO_CALL_PATIENT_NAME} from "../../slice/videoCallId";

const initialState = {
  value: 0, // Initial state of the ID
  videoCallPatientName: ''
};

const videoCallIdReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        value: action.payload,
      };
    case SET_VIDEO_CALL_PATIENT_NAME:
      return {
        ...state,
        videoCallPatientName: action.payload,
      };
    default:
      return state;
  }
};

export default videoCallIdReducer;
