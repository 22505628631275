import {SET_FULL_NAME} from "./userData";

export const SET_ID = 'SET_ID';
export const SET_VIDEO_CALL_PATIENT_NAME = 'SET_VIDEO_CALL_PATIENT_NAME';

export const videoCallId = (id: number) => {
  return {
    type: SET_ID,
    payload: id,
  };
};

export const setVideoCallPatientName = (value: string) => ({
  type: SET_VIDEO_CALL_PATIENT_NAME,
  payload: value,
});