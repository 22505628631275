import React, {useEffect, useState} from "react";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import DatePickerField from "../../../../general/form/datePickerField";
import Tooltip from "../../../../general/tooltip";
import {TIhioDataAndPrescriptionParaclinicalItemFields} from "../../../../../../types/prescription";
import DebounceField from "../../../../general/form/debounceField";
import {
  ISSUER_TYPE,
  PhysiotherapyIllnessConstant,
  PhysiotherapyTreatmentPlanDetails, VISIT_MODE
} from "../../../../../../constants/constant/enum";
import SelectField from "../../../../general/form/selectField";
import TextAreaField from "../../../../general/form/textAreaField";
import {useLocation} from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccordionButtonIcon from "../../../../../../assets/images/svgComponents/accordionButtonIcon";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت پاراکلینیک برای نسخه
    Parent: <PrescriptionParaclinicalItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های پاراکلینیک تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type ParaclinicalPrescriptionRowItemResponsiveProps = {
  item: any;
  index: number;
  insurance?: number;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  addParaclinicalItemToFavorites: any;
  handleParaclinicalModalSubmit: any;
  deleteParaclinicalItemFromList: any;
  register: any;
  watch: any
}


const ParaclinicalPrescriptionRowItemResponsive = ({
 item,
 index,
 insurance,
 control,
 errors,
 onFieldsChange,
 addParaclinicalItemToFavorites,
 handleShowModal,
 handleParaclinicalModalSubmit,
 deleteParaclinicalItemFromList,
 register,
 watch
}: ParaclinicalPrescriptionRowItemResponsiveProps) => {

  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const [arrowColor, setArrowColor] = useState('black')
  const [fieldValue, setFieldValue] = useState<TIhioDataAndPrescriptionParaclinicalItemFields>()

  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e: any)=> {
    const { name, value } = e?.target;
    onFieldsChange(name, value, index, item);
  };

  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={`bg-primary bg-opacity-10 rounded my-4`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <div className='w-100 d-flex justify-content-start'>
            <div>
              {mode === VISIT_MODE.EDIT.value && (
                <>
                  <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                       onClick={() => deleteParaclinicalItemFromList(index)}
                  >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='trash'/>
                </span>
                  </div>
                  <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                       onClick={() => addParaclinicalItemToFavorites(index)}
                  >
                    <span
                      className='mx-2 font-20 d-flex  align-items-center h-100'>
                        <Icon name={fieldValue?.paraId ? 'star-fill' : 'star'}/>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {
          (item?.service?.type?.id === 2 && insurance === ISSUER_TYPE.TAMIN.value) ?
            <div className="d-flex row">
              <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                <div className="form-group mb-2">
                  <DebounceField
                    onChange={handleFieldChange}
                    name={`itemCount_${index}`}
                    className={`text-center`}
                    placeholder="تعداد"
                    control={control}
                    error={errors}
                    disabled={mode === VISIT_MODE.SHOW.value}
                    register={register(`root.${index}.itemCount`)}
                    watch={watch(`root.${index}.itemCount`)}
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-6 col-6">
                <div className="form-group mb-2">
                  <SelectField
                    width={'10px'}
                    options={PhysiotherapyIllnessConstant}
                    name={`illness_${index}`}
                    placeholder='بیماری'
                    control={control}
                    error={errors}
                    onChange={handleFieldChange}
                    isDisabled={mode === VISIT_MODE.SHOW.value}
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                <div className="form-group mb-2">
                  <SelectField
                    width={'10px'}
                    options={PhysiotherapyTreatmentPlanDetails}
                    name={`plan_${index}`}
                    placeholder='طرح درمان'
                    control={control}
                    error={errors}
                    onChange={handleFieldChange}
                    isDisabled={mode === VISIT_MODE.SHOW.value}
                    isMulti
                  />
                </div>
              </div>
            </div> :
            <div className="d-flex row">
              <div className="col-6">
                <div className="form-group mb-2">
                  <DebounceField
                    onChange={handleFieldChange}
                    name={`itemCount_${index}`}
                    placeholder="تعداد"
                    control={control}
                    error={errors}
                    disabled={mode === VISIT_MODE.SHOW.value}
                    register={register(`root.${index}.itemCount`)}
                    watch={watch(`root.${index}.itemCount`)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <DatePickerField
                    name={`activeDate_${index}`}
                    placeholder="ثبت تاریخ"
                    control={control}
                    error={errors}
                    onChange={handleFieldChange}
                    disabled={mode === VISIT_MODE.SHOW.value}
                    register={register(`root.${index}.activeDate`)}
                    watch={watch(`root.${index}.activeDate`)}
                  />
                </div>
              </div>
            </div>
        }
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              onChange={handleFieldChange}
              rows={1}
              name={`note_${index}`}
              placeholder="توضیحات خدمت"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.note`)}
              watch={watch(`root.${index}.note`)}
            />
          </div>
        </div>
      </Accordion.Body>
      <div
        className={`border border-dark-subtle rounded mb-2 mx-3 ${mode === VISIT_MODE.SHOW.value ? '' : 'cur-pointer'}`}
        onClick={() => mode === VISIT_MODE.EDIT.value && handleShowModal('addPrescriptionParaclinicalItemModal', {
          index: index,
          item: item,
          handleParaclinicalModalSubmit: handleParaclinicalModalSubmit,
          insurance: insurance
        })}
      >
        <div className="d-flex w-100">
          <div className="d-flex w-100 pe-3 my-3">
            <div className="d-flex flex-row-reverse w-100">
              <div className="d-flex justify-content-start ps-1">
                <div className='d-flex justify-content-start align-items-center pl-5'>
                  <div className='ltr text-nowrap'>
                    <span className='fw-bold text-primary font-en ms-1 '> {(index + 1) + '. '} </span>
                    <span
                      className={`rounded rounded-3 bg-primary text-white border border-2 border-primary font-12 text-primary text-nowrap px-2 py-1`}>
                      {item?.service?.type?.name}
                     </span>
                  </div>
                  {
                    fieldValue?.message?.info?.map((item: string) => {
                      return (
                        <Tooltip alt={item} className='p-1 '>
                      <span className="d-flex align-content-center text-primary font-20 me-2"><Icon
                        name={'info-circle'}/></span>
                        </Tooltip>
                      );
                    })
                  }
                  {
                    fieldValue?.message?.warning?.map((item: string) => {
                      return (
                        <Tooltip alt={item} className='p-1'>
                      <span className="d-flex align-content-center text-yellow font-20 "><Icon
                        name={'info-circle'}/></span>
                        </Tooltip>
                      );
                    })
                  }
                </div>
              </div>
              <span className="d-flex flex-fill font-9 fw-semibold">
                {item?.service.name}
              </span>
            </div>
          </div>
        </div>
        <div className="text-start px-3">
          <span className="font-en font-9">
            {item?.service.enName ? item?.service?.enName : item?.service.name}
          </span>
        </div>
      </div>
      {
        fieldValue?.message?.error?.map((item: string) => {
          return (
            <div className='bg-light-red mb-1'>
              <Icon name={'info'}/>
              <span>{item}</span>
            </div>
          );
        })
      }
    </Accordion.Item>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ParaclinicalPrescriptionRowItemResponsive);