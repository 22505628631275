import moment from 'moment';
import momentJ from 'moment-jalaali';
import momentTz from 'moment-timezone';
import 'moment/locale/fa';
momentJ.loadPersian();

export const baseFormat = (date) => { // 2018/12/20 >> Fri May 28 2021 00:00:00 GMT+0430 (Iran Daylight Time)
  return date ? new Date(date) : '';
}

export const mFormat = (date, format) => { // Fri May 28 2021 00:00:00 GMT+0430 (Iran Daylight Time) >> 2018/12/20
  return moment(date).format(format || 'YYYY-MM-DD');
}

export const MtoJFullDateFormat = (date) => { // 2018/12/20 >> 1400/07/24 12:35:12
  const _date = date || new Date();
  return momentJ(_date).format('jYYYY/jM/jD HH:mm:ss');
}

export const MtoJDateFormat = (date) => { // 2018/12/20 >> 1400/07/24
  const _date = date || new Date();
  return momentJ(_date).format('jYYYY/jM/jD');
}

export const ToFullDateTimeFormat = (data) => { // Wed Apr 17 2024 00:00:00 GMT+0330 >> 2024-04-16T16:32:39.331Z
  return data ? moment(data).toISOString() : null;
}

export const JtoMDateFormat = (data) => { // 1400/07/24 >> 2018/12/20
  return momentJ(data, 'jYYYY/jM/jD').format('YYYY/M/D');
}

export const MtoJTimeFormat = (date) => { // 2018-12-20T10:59:46 >> 18:12:34
  return moment(date).format('HH:mm:ss');
}

export const MtoJHHMMFormat = (date) => { // 2018-12-20T10:59:46 >> 18:12
  return moment(date).format('HH:mm');
}

export const customDateFormat = (date) => { // 2019-05-11 >> 2019/05/11
  return moment(date).format('YYYY/MM/DD');
}

export const isExpiredDate = (time, due, type = 'days') => { //type: days, seconds, ...
  return moment().diff(moment(time), type) > (due || moment());
}

export const isToday = (date) => { // true or false
  return moment(date).isSame(moment(), 'day');
}

export const isPastDay = (date, today) => { // true or false
  const _today = today || new Date();
  return moment(date).diff(moment(_today), 'days') < 0;
}

export const isBetween = (date, from, to) => {
  const _date = date || new Date();
  return moment(_date, "DD-MM-YYYY").isBetween(from, to, "days");
}

export const diffDay = (date) => {
  return moment(date).diff(moment(mFormat(new Date())), 'days');
}

export const diffDayBasedOnStartOfDay = (date) => {
  // Get the start of today and the start of the given date
  const todayStart = moment().startOf('day');
  const dateStart = moment(date).startOf('day');

  // Calculate the difference in days
  return dateStart.diff(todayStart, 'days');
}

export const diffYear = date => { // 1379/01/01 >> 23.666875
  return moment(new Date()).diff(mFormat(date), 'years', true);
}

export const addDays = (date, count) => { // 2019/05/11 >> 2019/05/12
  return moment(date).add(count, 'days').format('YYYY/MM/DD');
}

export const subtractDays = (date, count) => { // 2019/05/11 >> 2019/05/10
  return moment(date).subtract(count, 'days').format('YYYY/MM/DD');
}

export const getTimeInterval = (startTime, endTime) => {
  return moment(moment(startTime,"hh:mm").diff(moment(endTime,"hh:mm"))).format("hh:mm"); 
}

export const getTimeDiff = (startTime, endTime) => {
  return moment(moment(startTime,"YYYY/MM/DD/HH:mm").diff(moment(endTime,"YYYY/MM/DD/HH:mm"))).format("HH:mm");
}

export const dayTitle = (date) => { // 2018-12-20 >> یکشنبه
  const _date = date || new Date();
  return moment(_date).format('dddd');
}

export const dayNumber = (date, isGregorian) => { // 2018-12-20 >> ۱۵ or 15
  if(!date) return false;
  return isGregorian ? moment(date).format('D') : momentJ(date).format('jD');
}

export const yearTitle = (date, isGregorian) => { // 2018-12-20 >> اردیبهشت or may
  if(!date) return false;
  isGregorian ? moment.locale('en') : momentJ.loadPersian();
  return isGregorian ? moment(date).format('YYYY').toLocaleString() : momentJ(date).format('jYYYY');
}

export const monthTitle = (date, isGregorian) => { // 2018-12-20 >> اردیبهشت or may
  if(!date) return false;
  isGregorian ? moment.locale('en') : momentJ.loadPersian();
  return isGregorian ? moment(date).format('MMMM').toLocaleString() : momentJ(date).format('jMMMM');
}

export const monthDay = (date, isGregorian) => { // 2018-12-20T10:59:46 >> ۱۵ اردیبهشت or 15 may
  const _date = date || new Date();
  return `${dayNumber(_date, isGregorian)} ${monthTitle(_date, isGregorian)}`;
}

export const fullDate = (date) => { // سه شنبه ۹ شهریور
  const _date = date || new Date();
  return `${dayTitle(_date)} ${monthDay(_date)}`
}

export const getStartOfTodayAsString = () => {
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0); // Set to start of today
  return startOfToday.toISOString()
}

export const getStartOfTomorrowAsDate = () => {
  const startOfNextDay = new Date();
  startOfNextDay.setHours(0, 0, 0, 0); // Set to start of today
  return startOfNextDay.setDate(startOfNextDay.getDate() + 1); // Move to the next day
}

export const getTimeLeftUntilDate = (date) => {
  const start = moment(date);
  const now = moment(new Date());
  if(now.isAfter(date)) {
    return '0:00'
  }
  const duration = moment.duration(now.diff(start));
  const hours = Math.floor(Math.abs(duration.asHours()));
  const minutes = Math.abs(duration.minutes());
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}