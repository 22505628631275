import React, {useEffect, useMemo, useState} from "react";
import Modal from "../general/modal";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import {connect} from "react-redux";

import {SubmitHandler, useForm} from "react-hook-form";
import TextField from "../general/form/textField";
import SelectField from "../general/form/selectField";
import {DEVICE_TYPE_OPTIONS} from "../../../constants/constant/enum";
import {useApprovePendingDevice, useGetPendingDevice} from "../../../hook/request/pendingDevices";
import {yupResolver} from "@hookform/resolvers/yup";
import {approveDeviceSchema} from "../../../schema/device-schema";
import {TApprovePendingDeviceFields} from "../../../types/device";
import DatePickerField from "../general/form/datePickerField";
import momentJ from "moment-jalaali";
import {useWindowSize} from "../../../hook/useWindowSize";


type ApprovePendingDeviceModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const ApprovePendingDeviceModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: ApprovePendingDeviceModalProps) => {

  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const fieldValueInitialState = {
    deviceCode: "",
    deviceType: "",
    imei: "",
    serialCode: "",
    updateDate: null
  }
  const [fieldValue, setFieldValue] = useState<TApprovePendingDeviceFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TApprovePendingDeviceFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(approveDeviceSchema),
  });

  // services
  const approvePendingDeviceRequest = useApprovePendingDevice({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPendingDeviceRequest = useGetPendingDevice();


  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'approvePendingDeviceModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData
      }
      handleGetPendingDevice(data)
    }
  }, [modalData, modalName])

  const handleGetPendingDevice = (data: any) => {
    getPendingDeviceRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPendingDeviceRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPendingDeviceRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate: updateDate ? momentJ(updateDate) : null,

        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPendingDeviceRequest.data])

  const approvePendingDevice: SubmitHandler<TApprovePendingDeviceFields> = (data) => {
    const { deviceType, imei, deviceCode, serialCode, updateDate } = data,
     body = {
       id: modalInfo,
       imei, deviceCode, serialCode, updateDate,
       type: {
         id: deviceType,
         name: ""
       }
    };
    approvePendingDeviceRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {
        getPendingDeviceRequest?.isPending ||
        approvePendingDeviceRequest?.isPending
        && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-5 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              فرم اطلاعات دستگاه
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(approvePendingDevice)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="deviceCode">کد دستگاه</label>
                <TextField
                  name="deviceCode"
                  placeholder="کد دستگاه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="deviceType">‌مدل دستگاه</label>
                <SelectField
                  options={DEVICE_TYPE_OPTIONS}
                  name="deviceType"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="imei">IMEI</label>
                <TextField
                  name="imei"
                  placeholder="IMEI"
                  control={control}
                  error={errors}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="serialCode">سریال دستگاه</label>
                <TextField
                  name="serialCode"
                  placeholder="سریال دستگاه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="updateDate">تاریخ تولید</label>
                <DatePickerField
                  name="updateDate"
                  placeholder="تاریخ تولید"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
              <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  تایید دستگاه
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
);
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovePendingDeviceModal);