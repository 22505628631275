import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import EmptyBox from "../../../../general/emptyBox";
import {useGetPatientConditions} from "../../../../../../hook/request/ehr/patientCondition";
import AddPatientConditionModal from "../../condition/addPatientConditionModal";
import PatientConditionSummary_rowItem from "./dataList/patientConditionSummary_rowItem";
import {TDataList} from "../../../../../../types/general";

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type patientConditionSummaryProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const PatientConditionSummary = ({
 patientData,
 openTab,
 handleShowModal,
 visitEHRCheck = false,
}: patientConditionSummaryProps) => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [conditionsData, setConditionsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData ? patientData : '0',
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getConditionsRequest = useGetPatientConditions(getQueryParams(filterValues), openTab === 'patientSummary' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getConditionsRequest?.data?.data?.result &&
    setConditionsData(getConditionsRequest?.data?.data.result)
  }, [getConditionsRequest.data])

  const _dataList = conditionsData?.data && conditionsData?.data?.length > 0 && conditionsData?.data?.map((item: any, i: number) => {
    return(
      <div className={`py-3 ${(i + 1) !== conditionsData?.data?.length ? 'border-bottom' : ''}`}>
        <PatientConditionSummary_rowItem key={i} item={item}/>
      </div>
    )
  });

  return (
    <>
      <div className="rounded">
        <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
          <div className="form-group ms-md-auto fw-bold mx-3 my-2">
            شرایط پزشکی
          </div>
          {visitEHRCheck &&
            <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 mx-3 my-2'
              onClick={() => handleShowModal('addPatientConditionModal', {
                patientId: patientData,
                patientData: patientData
              })}>
              <span className="m-1">افزودن شرایط پزشکی</span>
            </a>
          }
        </section>
        <section className="mx-3 my-2">
          {
            conditionsData?.data?.length === 0 ? <EmptyBox/> : _dataList
          }
        </section>
      </div>
      {openTab === 'patientSummary' && <AddPatientConditionModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientConditionSummary);
