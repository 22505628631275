import { useNavigate, generatePath } from 'react-router-dom';
import {TVisitFields} from "../../../types/visit";
import {useChangeStatusVisit} from "../../../hook/request/visit/visits";
import {ROUTES} from "../../../constants";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../constants/constant/enum";
import {toast} from "react-hot-toast";
import Icon from "../general/icon";
import BrokenVideoAdd from "../../../assets/images/svgComponents/icon/brokenVideoAdd";
import bellNotification from "../../../assets/audio/notification/Bell.mp3"
import {useEffect, useState} from "react";
import {videoCallId} from "../../../store/slice/videoCallId";
import {useDispatch, useSelector} from "react-redux";



export const usePatientPresenceToast = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientPresenceData, setPatientPresenceData] = useState<TVisitFields | null>(null)
  const videoCallId = useSelector((state: any) => state.general.videoCallIdReducer.value); // Access the ID from the Redux store


  const changeStatusVisitRequest = useChangeStatusVisit({
    onSuccess: () => {
      navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: patientPresenceData?.id }), { state: { mode: VISIT_MODE.EDIT.value } });
      patientPresenceData?.id && dispatch(videoCallId(+patientPresenceData?.id)); // Dispatch the action to store the ID
      setPatientPresenceData(null);
    }
  });

  const triggerPatientPresenceToast = (data: TVisitFields) => {
    setPatientPresenceData(data)

    const audio = new Audio(bellNotification);

    const handleNavigateToVisit = () => {
      if (data?.status?.id && +data?.status?.id === VISIT_STATUS_ENUM.RESERVED.value) {
        changeStatusVisitRequest.mutate({
          id: data?.id,
          status: VISIT_STATUS_ENUM.IN_PROGRESS.value
        });
      } else if (data?.status?.id && +data?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value && patientPresenceData?.id) {
        navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, { id: data?.id }), { state: { mode: VISIT_MODE.EDIT.value } });
        dispatch(videoCallId(+patientPresenceData?.id));

      }
    };

    if (+data?.status?.id === VISIT_STATUS_ENUM.RESERVED.value || +data?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value) {
      audio.play();
      toast(
        <div>
          <div className="d-flex align-items-center text-white">
            <span className='d-flex align-items-center font-25 ms-2'>
              <Icon name='phone' />
            </span>
            <span>
              بیمار شما {data?.patient?.name} در صفحه ویزیت است و منتظر شماست.
            </span>
          </div>
          {
            videoCallId !== 0 &&
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-end m-md-0 px-2"
              onClick={() => handleNavigateToVisit()}
            >
            <span
              className="btn btn-light-blue rounded rounded-3 font-10 d-flex align-items-center fw-bold px-3"
            >
              <span className='ms-2'>
                <BrokenVideoAdd heightAndWidth={20}/>
              </span>
              برو به ویزیت آنلاین
            </span>
            </div>
          }
        </div>,
        {
          position: 'top-left',
          duration: 10000,
          style: {
            borderRadius: '10px',
            background: "rgba(31, 31, 47, 0.7)",  // Semi-transparent background
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
            overflow: "hidden",
            backdropFilter: 'blur(10px)',  // Blur effect behind the component
            WebkitBackdropFilter: 'blur(10px)', // For compatibility with WebKit browsers
            width: '1000px'
          },
        }
      );
    }
  };

  return { triggerPatientPresenceToast };
};
