import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {monthDay, MtoJHHMMFormat, yearTitle} from "../../../../../../utils/date";
import Tooltip from "../../../../general/tooltip";

type PatientVitalCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any;
  readonly?: boolean;
}

const PatientVitalCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal,
  readonly
}: PatientVitalCardsBoxItemProps) => {

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">
          {
            getCellValue(monthDay(item?.observationDateTime)) + ' ' +
            getCellValue(yearTitle(item?.observationDateTime)) + ' - ' +
            getCellValue(MtoJHHMMFormat(item?.observationDateTime))
          }
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.temperature?.magnitude)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[2]}</label>
        <span
          className="mr-2">{getCellValue(item?.bpSystolic?.magnitude) + '/' + getCellValue(item?.bpDiastolic?.magnitude)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[3]}</label>
        <span
          className="mr-2">{getCellValue(item?.pulseRate?.magnitude)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.respiratoryRate?.magnitude)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[5]}</label>
        <span className="mr-2">{getCellValue(item?.spo2?.magnitude)}</span>
      </div>
      {
        !readonly &&
        <div className="d-flex align-items-center justify-content-between my-3">
          <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
          <div className="d-flex align-items-center justify-content-end my-3">
            <div className="d-flex align-items-center">
              <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deletePatientVitalModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
              </Tooltip>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVitalCardsBoxItem);