import React, {useRef} from 'react';
import Icon from '../general/icon';
import { monthDay } from '../../../utils/date';
import Breadcrumb from './breadcrumb';
import { useScroll } from '../../../hook/useScroll';
import { useWindowSize } from '../../../hook/useWindowSize';
import Image from '../general/image';
import hamrahDoctorLogo from '../../../assets/images/svg/hamrah-doctor-logo.svg';
import DatePickerField from '../general/form/datePickerField';
import ProfileMenu from './profileMenu';
import {Dispatch} from "redux";
import {general} from "../../../store/action";
import {connect, useSelector} from "react-redux";
import {Carousel, Dropdown, DropdownButton, DropdownHeader} from "react-bootstrap";
import InsuranceStabilityModal from "../setting/insuranceStabilityModal";
import {ISSUER_TYPE} from "../../../constants/constant/enum";
import OnlineStatusDropdown from "./onlineStatusDropdown";


const Header = ({
  handleShowDrawer,
  handleShowModal,
  openModal
}: any) => {

  const [width] = useWindowSize();
  const [scrolling, scrollTop] = useScroll();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const isInVideoCall = useSelector((state: any) => state.general.videoCallIdReducer.value);
  const videoCallPatientName = useSelector((state: any) => state.general.videoCallIdReducer.videoCallPatientName);


  const toggleShowCalendar = () => {
    // const newValue = calendarInpRef && !calendarInpRef?.current?.state?.isOpen;
    // calendarInpRef?.current?.setOpen(newValue);
  }

  return (
    <header className={`header ${width < 992 && scrolling && +scrollTop > 20 ? 'scrolling-position' : ''} ${+scrollTop > 100 ? 'scrolling-shadow' : ''}`}>
      <div className='header-cont d-flex align-items-center h-100 px-3'>
        {
          width < 992 &&
          <button
            onClick={handleShowDrawer}
            className='sidebar-toggle-btn btn btn-white d-flex font-25 rounded-circle me-0 me-md-2 p-2'
          >
            <Icon name='menu'/>
          </button>
        }
        <OnlineStatusDropdown openVideoCallOverlay={openModal}/>
        <span className="btn btn-white d-flex font-25 rounded-circle me-0 me-md-2 p-2">
          <Icon name='book-open'/>
        </span>
        <Carousel indicators={false} interval={5000}
                  className='d-flex justify-content-center remove-carousel-arrows w-51'>
          <Carousel.Item>
            <span className="btn btn-white d-flex font-25 rounded-circle me-0 me-md-2 p-2"
                  onClick={() => handleShowModal('insuranceStabilityModal', {issuerType: ISSUER_TYPE.TAMIN.value})}
            >
              <Icon name='tamin-logo'/>
            </span>
          </Carousel.Item>
          <Carousel.Item>
            <span className="btn btn-white d-flex font-25 rounded-circle me-0 me-md-2 p-2"
                  onClick={() => handleShowModal('insuranceStabilityModal', {issuerType: ISSUER_TYPE.IHIO.value})}
            >
              <Icon name='salamat-logo'/>
            </span>
          </Carousel.Item>
        </Carousel>
        {isProvider &&
          <span
            className={`d-flex align-items-center rounded border border-success cur-pointer mx-2 py-1 px-2 bg-not-in-video-call`}
            onClick={() => openModal()}
          >
            <span className={`font-30 d-flex align-items-center h-100 rotate-135 ${isInVideoCall !== 0 ? 'show text-green ms-1' : ''}`}>
              <Icon name='phone'/>
            </span>
                <div className={`d-flex align-items-center expand-call-button-container ${isInVideoCall !== 0 ? 'show' : ''}`}>
                  {isInVideoCall !== 0 && (
                    <div className='d-flex w-100 align-items-center'>
                      <span className='fw-bold text-green mx-1'>در حال ویزیت</span>
                      <div className="blinking-dot me-auto"></div>
                    </div>
                  )}
                </div>
          </span>
        }
        <Breadcrumb/>
        <div className="d-flex align-items-center me-auto ms-3">
          <div className='d-md-none size-50 rounded-circle overflow-hidden border'>
            <Image src={hamrahDoctorLogo} alt="Hamrah Doctor Logo"/>
          </div>
          <span className='d-none d-md-flex align-items-center text-gray2'>
            <span className="user-position">امروز {monthDay()}</span>
            <span onClick={toggleShowCalendar} className='d-flex text-hover cur-pointer font-25 me-2'><Icon
              name="calendar"/></span>
          </span>
          <ProfileMenu/>
        </div>
      </div>
      <InsuranceStabilityModal/>
    </header>
)
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(Header);