import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import PatientVisitCardsBox from "../../visit/dataList/patientVisitCardsBox";
import PatientVisitsTable from "../../visit/dataList/patientVisitsTable";
import {useGetVisits} from "../../../../../../hook/request/visit/visits";
import ShowPatientVisitModal from "../../visit/showPatientVisitModal";
import {TDataList} from "../../../../../../types/general";


const dataListTitles = [
  "نام پزشک",
  "تاریخ ویزیت",
  "نوع ویزیت",
  "وضعیت",
  "",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type patientVisitSummaryProps = {
  patientData?: any;
  openTab?: string;
  setOpenTab?: any;
}

const PatientVisitSummary = ({
 patientData,
 openTab,
 setOpenTab,
}: patientVisitSummaryProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [visitsData, setVisitsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData,
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getVisitsRequest = useGetVisits(getQueryParams(filterValues), openTab === 'patientSummary' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getVisitsRequest?.data?.data?.result &&
    setVisitsData(getVisitsRequest?.data?.data.result)
  }, [getVisitsRequest.data])

  return (
    <>
      <div className="rounded">
        <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
          <div className="form-group ms-md-auto fw-bold mx-3 my-2">
            ویزیت ها
          </div>
          <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 mx-3 my-2'
             href='#visits'
             onClick={() => setOpenTab('visits')}
          >
            <span className="m-1">همه ویزیت ها</span>
          </a>
        </section>
        <section className="mx-3 my-2">
          <section className="table-cont table-responsive">
            {
              width < 900 ?
                <PatientVisitCardsBox
                  dataList={visitsData}
                  dataListTitles={dataListTitles}
                  isRequesting={getVisitsRequest.isPending}
                /> :
                <PatientVisitsTable
                  dataList={visitsData}
                  dataListTitles={dataListTitles}
                  isRequesting={getVisitsRequest.isPending}
                />
            }
          </section>
        </section>
      </div>
      {openTab === 'patientSummary' && <ShowPatientVisitModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVisitSummary);
