// import React, {useEffect, useState} from "react";
// import {connect} from "react-redux";
// import Modal from "../../../general/modal";
// import {
//   isHideModalDataSelector,
//   isShowModalDataSelector,
//   modalDataSelector,
//   modalNameSelector
// } from "../../../../../store/selector/general";
// import {general} from "../../../../../store/action";
// import {TPrescriptionFields} from "../../../../../types/prescription";
// import VideoCall from "../../../../pages/videoCall/videoCall";
//
// type VideoCallOverlayProps = {
//   modalData: any;
//   modalName: string;
//   isShowModalData: boolean;
//   handleHideModal: any;
//   handleResetModal: any;
//   handleShowModal: any;
// }
//
// type ModalInfoProps = {
//   id: string;
//   visitId: string;
//   patientData?: any;
//   updateFloatingDiv: any
// }
//
// const VideoCallOverlay = ({
//  modalData,
//  modalName,
//  isShowModalData,
//  handleHideModal,
//  handleResetModal,
//  handleShowModal,
// }: VideoCallOverlayProps) => {
//   const [showState, setShowState] = useState(false);
//   const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
//     id: '',
//     visitId: '',
//     patientData: '',
//     updateFloatingDiv: null
//   });
//
//   useEffect(() => {
//     if (
//       isShowModalData &&
//       modalName === 'videoCallOverlay'
//     ) {
//       setShowState(true);
//       setModalInfo(modalData);
//       handleResetModal();
//     }
//   }, [modalData, modalName])
//
//
//   const handleSetShowModal = () => {
//     setShowState(false);
//     handleHideModal();
//   }
//
//   return (
//     <Modal
//       setShow={handleSetShowModal}
//       showState={showState}
//       noCloseBtn={true}
//       fullscreen={true}
//       className="overflow-hidden vh-100 h-100"
//       bodyClassName="overflow-hidden vh-100 h-100 p-0"
//     >
//       <VideoCall handleSetShowModal={handleSetShowModal} updateFloatingDiv={modalInfo?.updateFloatingDiv?.updateFloatingDiv}/>
//     </Modal>
//   );
// };
//
// const mapStateToProps = (state: any) => ({
//   modalData: modalDataSelector(state),
//   modalName: modalNameSelector(state),
//   isShowModalData: isShowModalDataSelector(state),
//   isHideModalData: isHideModalDataSelector(state),
// });
//
// const mapDispatchToProps = (dispatch: any) => ({
//   handleHideModal: () => dispatch(general.handleHideModal()),
//   handleResetModal: () => dispatch(general.handleResetModal()),
//   handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
// });
//
// export default connect(mapStateToProps, mapDispatchToProps)(VideoCallOverlay);


// Modal.js
import VideoCall from "../../../../pages/videoCall/videoCall";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setVideoCallPatientName, videoCallId} from "../../../../../store/slice/videoCallId"; // Import Bootstrap CSS

type VideoCallOverlayProps = {
  isVisible: boolean
  onClose: () => void;
  updateFloatingDiv: any;
}

const VideoCallOverlay = ({
  isVisible,
  onClose,
  updateFloatingDiv
}: VideoCallOverlayProps) => {

  const id = useSelector((state: any) => state.general.videoCallIdReducer.value); // Access the ID from the Redux store
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape' && isVisible) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVisible]);

  const setVisitIdToZero = () => {
    dispatch(videoCallId(0));
    dispatch(setVideoCallPatientName(''));
  }

  const setVideoCallId = (callId: number) => {
    console.log('call', callId)
    if(callId !== id) {
      dispatch(videoCallId(callId));
      dispatch(setVideoCallPatientName('در حال ویزیت...'));
    }
  }

  return (
    <div className={`modal fade ${isVisible ? 'show' : 'd-none'}`} tabIndex={-1}
         style={{ display: isVisible ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="width-full height-screen-100 overflow-hidden">
            <VideoCall handleSetShowModal={onClose} updateFloatingDiv={updateFloatingDiv} currentCallId={id} setVisitIdToZero={setVisitIdToZero} setVideoCallId={setVideoCallId}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCallOverlay;
