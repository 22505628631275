import {useNavigate} from "react-router-dom";
import {getCellValue} from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Icon from "../../general/icon";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../general/tooltip";

type AppointmentTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const AppointmentTableRowItem = ({
 item,
 handleShowModal
}: AppointmentTableRowItemProps) => {
  const navigate = useNavigate();

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>{getCellValue(item?.patient?.firstName)} {getCellValue(item?.patient?.lastName)}</td>
      <td>{getCellValue(item?.healthUnit?.name)}</td>
      <td>{getCellValue(item?.provider?.firstName)} {getCellValue(item?.provider?.lastName)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.dateTime))}</bdi>
      </td>
      <td>{getCellValue(item?.visitType?.name)}</td>
      <td>
        <div>
          <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()} `}>
            {getCellValue(item?.status?.name)}
          </span>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'جزئیات'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('changeStatusAppointmentModal', {id: item?.id})}
              className="d-flex text-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='eye'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AppointmentTableRowItem);
