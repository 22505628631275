import React, {useEffect, useState} from "react";
import SelectField from "../../../../general/form/selectField";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import {useGetMedicationBrand} from "../../../../../../hook/request/medication";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import Tooltip from "../../../../general/tooltip";
import {Accordion} from "react-bootstrap";
import {TIhioDataAndPrescriptionMedicationItemFields} from "../../../../../../types/prescription";
import DebounceField from "../../../../general/form/debounceField";
import {ISSUER_TYPE, MEDICATION_SHAPE_IHIO, VISIT_MODE} from "../../../../../../constants/constant/enum";
import TextAreaField from "../../../../general/form/textAreaField";
import {useLocation} from "react-router-dom";
import {getCellValue} from "../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../utils/date";
import AccordionButtonIcon from "../../../../../../assets/images/svgComponents/accordionButtonIcon";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت دارو برای نسخه
    Parent: <PrescriptionMedicationItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های دارو تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type MedicationPrescriptionRowItemResponsiveProps = {
  item: any;
  index: number;
  insurance?: number;
  frequency: any;
  dosage: any;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  addMedicationItemToFavorites: any;
  handleMedicationModalSubmit: any;
  deleteMedicationItemFromList: any;
  addIhioActiveDates: any;
  addTaminActiveDates: any;
  register: any;
  watch?: any
}

type TBrandFilterValues = {
  molecule?: string;
  dose?: string;
  doseForm?: string;
}

const MedicationPrescriptionRowItemResponsive = ({
 item,
 index,
 insurance,
 frequency,
 dosage,
 handleShowModal,
 control,
 errors,
 onFieldsChange,
 addMedicationItemToFavorites,
 deleteMedicationItemFromList,
 handleMedicationModalSubmit,
 addIhioActiveDates,
 addTaminActiveDates,
 register,
 watch
}: MedicationPrescriptionRowItemResponsiveProps) => {

  //<editor-fold desc="States">
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const { getQueryParams } = useMySearchParams();
  const [arrowColor, setArrowColor] = useState('black')
  const [fieldValue, setFieldValue] = useState<TIhioDataAndPrescriptionMedicationItemFields>()
  const brandFilterValuesInitialState = {
    molecule: "",
    dose: "",
    doseForm: "",
  }
  const [brandFilterValues, setBrandFilterValues] = useState<TBrandFilterValues>(brandFilterValuesInitialState);
  const [brandList, setBrandList] = useState<any>([])
  const [showBrand, setShowBrand] = useState(mode === VISIT_MODE.SHOW.value)
  //</editor-fold>

  //<editor-fold desc="Services">
  const getMedicationBrandRequest = useGetMedicationBrand(getQueryParams(brandFilterValues), showBrand);
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    دریافت برند های یک دارو برای سلکت و حذف برند هایی که توسط بیمه بیمار ارائه نمی‌شوند.
    @Dependency: getMedicationBrandRequest
  */
  useEffect(() => {
    if (getMedicationBrandRequest?.data?.data?.result) {
      const data  = getMedicationBrandRequest.data.data.result.filter((item: any) => {
        if (insurance === ISSUER_TYPE.IHIO.value) {
          return item.ihioActive !== false; // Filter out if ihioActive is false
        } else if (insurance === ISSUER_TYPE.TAMIN.value) {
          return item.taminActive !== false; // Filter out if taminActive is false
        }
        return true; // If no specific insurance type, include all items
      })
      const _data = data.map((item: any) => (
        { label: item?.brandName ?
            <Tooltip alt={`${item?.brandName}`} placement='right-start' show={300} hide={200} className="tooltip-pre-line"><span className={`${determineBrandSupportAndActive(item, true)}`}>{item?.brand ? item?.brand : "No Brand"}</span></Tooltip>
            : <span className={`${determineBrandSupportAndActive(item, true)}`}>{item?.brand ? item?.brand : "No Brand"}</span>,
          value: item?.id,
          erx: item?.erx,
          generic: item?.generic,
          titleText: item?.brand ? item?.brand : "No Brand",
          ihioSupport: item?.ihioSupport,
          taminSupport: item?.taminSupport,
        }
      ))
      setBrandList(_data);
    }
  }, [getMedicationBrandRequest.data])

  /*
    فاکتور های پیدا کردن برند دارو بر اساس اطلاعات دارو تغییر میکنند.
    @Dependency: item
  */
  useEffect(() => {
    setBrandFilterValues({
      molecule: item?.item?.molecule,
      dose: item?.item?.dose,
      doseForm: item?.item?.doseForm,
    })
  }, [item])
  //</editor-fold desc="UseEffects">

  /*
 تغییر رنگ تکست بر اساس ساپورت بودن و نبودن بیمه بیمار.
 @param: item
*/
  const determineBrandSupportAndActive = (item: any, brandOrFieldValue: boolean)=> {
    if(brandOrFieldValue) {
      if(insurance === ISSUER_TYPE.IHIO.value){
        if(!item?.ihioSupport){
          return 'text-red'
        } else {
          return 'text-primary'
        }
      } else if(insurance === ISSUER_TYPE.TAMIN.value){
        if(!item?.taminSupport){
          return 'text-red'
        } else {
          return 'text-primary'
        }
      }
    } else {
      if(insurance === ISSUER_TYPE.IHIO.value){
        if(!item?.support){
          return 'text-red'
        } else {
          return 'text-primary'
        }
      } else if(insurance === ISSUER_TYPE.TAMIN.value){
        if(!item?.support){
          return 'text-red'
        } else {
          return 'text-primary'
        }
      }
    }

  };

  const handleTaminActiveDates = (dates: any[]) => {
    addTaminActiveDates(dates, index);
  }

  const handleIhioActiveDates = (dates: any[]) => {
    const formattedDates = dates?.map((item: any) => {
      const newDate = new Date(item);
      return newDate.toISOString();
    })
    addIhioActiveDates(formattedDates, index);
  }

  const removeFutureDates = () => {
    onFieldsChange('futureDates_', [], index, item)
  }

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e?: any)=> {
    const { name, value } = e?.target;
    console.log(name, value)
    const fieldName = name.split('_')[0];
    let brand = {}
    if(fieldName === 'brand') {
      brand = brandList && brandList?.find((item: any) => item?.value === value)
    }
    onFieldsChange(name, value, index, item, brand);
  };

  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={`bg-primary bg-opacity-10 rounded my-4`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <div className='w-100 d-flex justify-content-start'>
            <div>
              {mode === VISIT_MODE.EDIT.value && (
                <>
                  <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                       onClick={() => deleteMedicationItemFromList(index)}
                  >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='trash'/>
                </span>
                  </div>
                  <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                     onClick={() => handleShowModal('prescriptionActiveDateModal',
                       {
                         insurance: insurance,
                         index: index,
                         item: item,
                         handleIhioActiveDates: handleIhioActiveDates,
                         handleTaminActiveDates: handleTaminActiveDates
                       })
                     }
                  >
                    <span className='mx-2 font-20 d-flex  align-items-center h-100'>
                      <Icon name='calendar'/>
                    </span>
                  </div>
                  <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                       onClick={() => addMedicationItemToFavorites(index)}
                  >
                    <span
                      className='mx-2 font-20 d-flex  align-items-center h-100'>
                      <Icon name={fieldValue?.medId ? 'star-fill' : 'star'}/>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body className="d-flex row">
        {!showBrand ?
          <div className="col-lg-2 col-md-3 col-sm-3 col-6 mb-2"
               onClick={() => setShowBrand(true)}
          >
            <div className='btn btn-primary d-flex align-items-center rounded px-2'>
              <span
                className='d-flex justify-content-center align-items-center w-100 font-27'>
                <Icon name='color-swatch'/>
              </span>
            </div>
          </div> :
          <div className=" col-lg-2 col-md-3 col-sm-3 col-6 mb-2">
            <div className="form-group">
              <SelectField
                width={'50px'}
                options={brandList}
                name={`brand_${index}`}
                placeholder='برند'
                control={control}
                error={errors}
                onChange={handleFieldChange}
                isDisabled={mode === VISIT_MODE.SHOW.value}
              />
            </div>
          </div>
        }
        <div className=" col-lg-2 col-md-4 col-sm-4 col-6 mb-2">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={dosage}
              name={`dosage_${index}`}
              placeholder='دستور مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              isDisabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.dosage`)}
              watch={watch(`root.${index}.dosage`)}
            />
          </div>
        </div>
        <div className=" col-lg-2 col-md-3 col-sm-3 col-6 mb-2">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={frequency}
              name={`frequency_${index}`}
              placeholder='تواتر مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              isDisabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.frequency`)}
              watch={watch(`root.${index}.frequency`)}
            />
          </div>
        </div>
        <div className=" col-6 col-lg-2 col-md-2 col-sm-2 mb-2">
          <div className="form-group">
            <DebounceField
              onChange={handleFieldChange}
              name={`itemCount_${index}`}
              placeholder="تعداد"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.itemCount`)}
              watch={watch(`root.${index}.itemCount`)}
            />
          </div>
        </div>
        {
          insurance === ISSUER_TYPE.TAMIN.value && item?.futureDates && item?.futureDates?.length > 0 &&
          <div className='d-flex align-items-center flex-row-reverse pb-3 ms-2'>
          <span className='font-12 fw-semibold'>
            تاریخ تکرار دارو
          </span>
            <span className='rounded rounded-3 bg-white ms-3 px-2 py-1'>
            <div className='d-flex'>
              {
                item?.futureDates?.map((date: any, index: number) => {
                  return (
                    <span className='mx-1'>
                      {MtoJDateFormat(date)} {(index + 1) !== item?.futureDates?.length && ' و '}
                    </span>
                  )
                })
              }
              <span className='d-flex text-red-hover align-items-center font-20 me-2'
                    onClick={() => removeFutureDates()}>
                <Icon name='close-square'/>
              </span>
            </div>
          </span>
          </div>
        }
        {
          insurance === ISSUER_TYPE.IHIO.value && item?.activeDate &&
          <div className='d-flex align-items-center flex-row-reverse ps-4'>
            <span className='font-12 fw-semibold'>
              تاریخ ارائه دارو
            </span>
            <span className='rounded rounded-3 bg-white ms-3 px-2 py-1'>
              {getCellValue(MtoJDateFormat(item?.activeDate))}
            </span>
          </div>
        }
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              rows={1}
              onChange={handleFieldChange}
              name={`drugDescription_${index}`}
              placeholder="توضیحات دارو"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.drugDescription`)}
              watch={watch(`root.${index}.drugDescription`)}
            />
          </div>
        </div>
      </Accordion.Body>
      <div className='d-flex justify-content-end align-items-center prescription-accordion-footer'>
        <div className="rounded border bg-white w-100 px-1 py-1 cur-pointer"
             onClick={() => mode === VISIT_MODE.EDIT.value && handleShowModal('addPrescriptionMedicationItemModal', {
               index: index,
               item: item,
               handleMedicationModalSubmit: handleMedicationModalSubmit,
               insurance: insurance
             })}
        >
          <bdi className='d-flex justify-content-end me-2'>
            <div className='d-flex align-items-center w-100 font-en ltr'>
              <div className='d-flex align-items-center ms-1'>
                <span
                  className={`rounded-3 bg-primary text-white border border-2 border-primary font-12 fw-bold text-nowrap p-2 me-2`}>
                  {item?.item?.category?.name}
                 </span>
              </div>
              <div className='d-flex justify-content-start w-100'>
                <span className='d-flex align-items-center font-20 text-hover font-en'>
                  {
                    fieldValue?.message?.warning?.map((item: string) => {
                      return (
                        <Tooltip alt={item} className='p-1 '>
                      <span className="d-flex align-content-center text-yellow font-20 me-2"><Icon
                        name={'info-circle'}/></span>
                        </Tooltip>
                      );
                    })
                  }
                  {
                    fieldValue?.message?.info?.map((item: string) => {
                      return (
                        <Tooltip alt={item} className='p-1 '>
                      <span className="d-flex align-content-center text-primary font-20 me-2"><Icon
                        name={'info-circle'}/></span>
                        </Tooltip>
                      );
                    })
                  }
                </span>
                <div className="d-flex flex-column">
                  <span
                    className={`d-flex justify-content-start text-start fw-bold font-en ${determineBrandSupportAndActive(fieldValue?.item, false)}`}>
                    {(index + 1) + '. ' + MEDICATION_SHAPE_IHIO.find((item: any) => item?.value === fieldValue?.item?.shape?.id)?.enName + ' - ' + fieldValue?.item?.molecule} - &nbsp;
                    {fieldValue?.item?.dose} - &nbsp;
                    {fieldValue?.itemCount} - &nbsp;
                    {frequency && frequency?.find((item: any) => item?.value === fieldValue?.frequency)?.maxaValue} - &nbsp;
                    {dosage && dosage?.find((item: any) => item?.value === fieldValue?.dosage)?.label} &nbsp;
                    {brandList && brandList?.find((item: any) => item?.value === fieldValue?.item?.id)?.titleText}
                  </span>
                  {arrowColor === 'blue' && (
                    <span className='d-flex justify-content-start font-en'>
                      {item?.item?.generic &&
                        <span className='text-dark fw-bolder me-1'>
                          {'(' + item?.item?.generic + ')'}
                        </span>
                      }
                      {item?.item?.molecule}
                    </span>
                  )}
                </div>
                <div className="flex-fill"></div>
                <span className='d-flex align-items-center font-20 text-hover font-en'>
                  <Icon name='pen-square'/>
                </span>
              </div>
            </div>
          </bdi>
        </div>
      </div>
      {
        fieldValue?.message?.error?.map((item: string) => {
          return (
            // <div className="pb-2">
            //   <span className='d-flex text-danger mb-1 me-4'>
            //     <span className='d-flex align-items-center font-20 ms-2'>
            //
            //     </span>
            //     <span className='text-danger bg-danger-subtle py-1 px-2'>{item}</span>
            //   </span>
            // </div>
            <div className="pb-2 w-100">
            <span className='d-flex w-100 text-red text-nowrap pb-1'>
              <span className='d-flex w-100 rounded bg-danger-subtle border-2 border-red px-3 py-2'>
                <span className='d-flex align-items-center text-red font-20 ms-2'>
                  <Icon name={'info-circle'}/>
                </span>
                {item}
              </span>
            </span>
            </div>
          );
        })
      }
    </Accordion.Item>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicationPrescriptionRowItemResponsive);