import React from "react";
import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import { MtoJDateFormat, MtoJFullDateFormat, isBetween, isPastDay } from "../../../../utils/date";
import Tooltip from "../../general/tooltip";

const ScheduleTableRowItem = ({
  item,
  handleShowModal
}) => {
  const navigate = useNavigate();

  const getScheduleType = () => {
    let _type = "";
    const isEndedSchedule = isPastDay(item?.toDate),
      isCurrentSchedule = isBetween(new Date() ,item.fromDate, item.toDate),
      isCurrentWithNullToDateSchedule = item?.toDate == null && isPastDay(item?.fromDate),
      isFutureSchedule = !isPastDay(item.fromDate);
    if(isEndedSchedule) _type = "برنامه پایان یافته";
    else if(isCurrentSchedule || isCurrentWithNullToDateSchedule) _type = "برنامه جاری";
    else if(isFutureSchedule) _type = "برنامه آینده";
    return _type;
  }

  return(
    <tr>
      <td>{getCellValue(item?.name)}</td>
      <td>{getCellValue(item?.provider?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJDateFormat(item?.fromDate))}</bdi>
      </td>
      <td>
        <bdi className="word-spacing-10">{item?.toDate ? getCellValue(MtoJDateFormat(item?.toDate)) : '-'}</bdi>
      </td>
      <td>
        {getScheduleType()}
        <span className="mx-1">{item?.draft ? "(پیش نویس)" : ""}</span>
      </td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'اتمام برنامه'} className="tooltip-pre-line">
            <span
              onClick={() => !item?.toDate && handleShowModal('closeScheduleModal', item?.id)}
              className={`d-flex r mx-2 font-20 cur-pointer ${!item?.toDate ? 'text-red-hover' : 'disabled text-gray3'}`}
            >
              <Icon name='close-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
            <span
              onClick={() => navigate(generatePath(ROUTES.SCHEDULE_EDIT.PATH, {id: item?.id}))}
              className="d-flex text-hover mx-2 font-18 cur-pointer"
            >
              <Icon name='pen-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'جزئیات'} className="tooltip-pre-line">
            <span
              onClick={() => navigate(generatePath(ROUTES.SCHEDULE_ADD_ITEM.PATH, {id: item?.id}))}
              className="d-flex text-hover mx-2 font-18 cur-pointer"
            >
              <Icon name='eye'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ScheduleTableRowItem);
