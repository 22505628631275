import React from "react";
import {connect} from "react-redux";
import {generatePath, useNavigate} from "react-router-dom";
import {general} from "../../../../../store/action";
import Icon from "../../../general/icon";
import {getCellValue} from "../../../../../utils/utils";
import {Dispatch} from "redux";
import Tooltip from "../../../general/tooltip";
import {ROUTES} from "../../../../../constants";

type MedicationItemCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const MedicationItemCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}: MedicationItemCardsBoxItemProps) => {
  const navigate = useNavigate();

  const toolTipTemplate = `کد مکسا: ${getCellValue(item?.maxa?.code)}\n
    مقدار مکسا: ${getCellValue(item?.maxa?.value)}\n
    ترمینولوژی: ${getCellValue(item?.maxa?.terminologyId)}
    `

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.description)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="category">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.category?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="maxa">{dataListTitles[3]}</label>
        <Tooltip alt={toolTipTemplate} className="tooltip-pre-line" placement='right'>
          <span className="item-lnk cur-pointer">
              <span className="item-txt hide-collapsed">{getCellValue(item?.maxa?.value)}</span>
          </span>
        </Tooltip>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[4]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            <Tooltip alt={'ویرایش'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.MEDICATION_EDIT.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='pen-square'/>
              </span>
            </Tooltip>
            <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteMedicationItemModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicationItemCardsBoxItem);