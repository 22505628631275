import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const ScheduleService = {
  getSchedulesList(params){ return axiosRequest('GET', API_PATH.SCHEDULE.LIST(params)) },
  getSchedule(data){ return axiosRequest('POST', API_PATH.SCHEDULE.INFO, data) },
  addSchedule(data){ return axiosRequest('POST', API_PATH.SCHEDULE.ADD, data) },
  closeSchedule(data){ return axiosRequest('POST', API_PATH.SCHEDULE.CLOSE, data) },
  getScheduleItemsList(data){ return axiosRequest('POST', API_PATH.SCHEDULE.LIST_ITEM, data) },
  addItemToSchedule(data){ return axiosRequest('POST', API_PATH.SCHEDULE.ADD_ITEM, data) },
  activeSchedule(data){ return axiosRequest('POST', API_PATH.SCHEDULE.ACTIVE, data) },
  deleteScheduleItem(data){ return axiosRequest('POST', API_PATH.SCHEDULE.DELETE_ITEM, data) },
}