import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useAddPatientProgram, useGetPatientProgram} from "../../../../../hook/request/ehr/patientProgram";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import TextField from "../../../general/form/textField";
import {AsyncSelectField} from "../../../general/form/selectField";
import DatePickerField from "../../../general/form/datePickerField";
import {TAddPatientProgramFields} from "../../../../../types/patient";
import {addPatientProgramSchema} from "../../../../../schema/patient-schema";
import TextAreaField from "../../../general/form/textAreaField";
import {useGetPrograms} from "../../../../../hook/request/program";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import {asyncSelectLoadOptions} from "../../../../../utils/asyncSelectLoadOptions";


type AddPatientProgramModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}


const AddPatientProgramModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientProgramModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [programsList, setProgramsList] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const fieldValueInitialState = {
    program: "",
    description: "",
    location: "",
    startDateTime: null,
    endDateTime: null
  }
  const [fieldValue, setFieldValue] = useState<TAddPatientProgramFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddPatientProgramFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientProgramSchema),
  });

  // services
  const addPatientProgramRequest = useAddPatientProgram({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientProgramRequest = useGetPatientProgram();
  const getProgramsRequest = useGetPrograms(getQueryParams(filterValues), modalName === 'addPatientProgramModal');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getProgramsRequest?.data?.data?.result) {
      const {data} = getProgramsRequest?.data?.data.result,
        _data = data.map((item: any) => (
          {label: item?.name, value: item?.id}
        ))
      setProgramsList(_data);
    }
  }, [getProgramsRequest.data])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientProgramModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientProgram(data)
    }
  }, [modalData, modalName])

  const handleGetPatientProgram = (data: any) => {
    data.id && getPatientProgramRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPatientProgramRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPatientProgramRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientProgramRequest.data])

  const addPatientProgram: SubmitHandler<TAddPatientProgramFields> = (data) => {
    const { program, description, location, startDateTime, endDateTime } = data,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        patient: {
          id: modalInfo?.patientId
        },
        entrySource:{
          id:200
        },
        description, location, startDateTime, endDateTime,
        program: {
          id: program,
        }
      };
    addPatientProgramRequest.mutate(body);
  }

  const programsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, filterValues, setFilterValues, getProgramsRequest);
  };

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientProgramRequest?.isPending ||
          getProgramsRequest?.isPending ||
          addPatientProgramRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش برنامه" : "ثبت برنامه"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientProgram)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="program">عنوان برنامه</label>
                <AsyncSelectField
                  options={programsList}
                  name="program"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                  asyncLoadOptions={programsLoadOptions}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="location">محل برگزاری</label>
                <TextField
                  name="location"
                  placeholder="محل برگزاری"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="startDateTime">تاریخ شروع برنامه</label>
                <DatePickerField
                  name="startDateTime"
                  placeholder="تاریخ شروع برنامه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="endDateTime">تاریخ پایان برنامه</label>
                <DatePickerField
                  name="endDateTime"
                  placeholder="تاریخ پایان برنامه"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  {modalInfo?.id ? "ویرایش برنامه" : "ثبت برنامه"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientProgramModal);