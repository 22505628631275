import React, {useEffect, useState} from "react";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import DatePickerField from "../../../../general/form/datePickerField";
import Tooltip from "../../../../general/tooltip";
import {Collapse} from "react-bootstrap";
import {TIhioDataAndPrescriptionParaclinicalItemFields} from "../../../../../../types/prescription";
import DebounceField from "../../../../general/form/debounceField";
import {
  ISSUER_TYPE,
  PhysiotherapyIllnessConstant,
  PhysiotherapyTreatmentPlanDetails, VISIT_MODE
} from "../../../../../../constants/constant/enum";
import SelectField from "../../../../general/form/selectField";
import TextAreaField from "../../../../general/form/textAreaField";
import {useLocation} from "react-router-dom";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت پاراکلینیک برای نسخه
    Parent: <PrescriptionParaclinicalItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های پاراکلینیک تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type ParaclinicalPrescriptionRowItemProps = {
  item: any;
  index: number;
  insurance?: number;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  addParaclinicalItemToFavorites: any;
  handleParaclinicalModalSubmit: any;
  deleteParaclinicalItemFromList: any;
  register: any;
  watch: any
}


const ParaclinicalPrescriptionRowItem = ({
  item,
  index,
  insurance,
  control,
  errors,
  onFieldsChange,
  addParaclinicalItemToFavorites,
  handleShowModal,
  handleParaclinicalModalSubmit,
  deleteParaclinicalItemFromList,
  register,
  watch
}: ParaclinicalPrescriptionRowItemProps) => {

  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const [showDescription, setShowDescription] = useState(false)
  const [fieldValue, setFieldValue] = useState<TIhioDataAndPrescriptionParaclinicalItemFields>()

  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e: any)=> {
    const { name, value } = e?.target;
    console.log(name, value)
    onFieldsChange(name, value, index, item);
  };

  return (
    <div className='bg-primary bg-opacity-10 rounded my-4 px-4 pb-3'>
      <div className="d-flex">
        <div className="w-100">
          <div className='d-flex fw-bold font-14 mt-4'>
            {mode === VISIT_MODE.EDIT.value && (
              <>
                <div className='p-1 btn btn-gray rounded rounded-3'>
                  <span
                    onClick={() => deleteParaclinicalItemFromList(index)}
                    className='mx-2 font-20 d-flex  align-items-center h-100'>
                    <Icon name='trash'/>
                  </span>
                </div>
                <div className='p-1 btn btn-gray rounded rounded-3 mx-2'>
                  <span
                    onClick={() => addParaclinicalItemToFavorites(index)}
                    className='mx-2 font-20 d-flex  align-items-center h-100'>
                    <Icon name={fieldValue?.paraId ? 'star-fill' : 'star'}/>
                  </span>
                </div>
              </>
            )}
            <div
              onClick={() => setShowDescription(!showDescription)}
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
                <span
                  className="btn btn-gray rounded d-flex align-items-center px-4 mx-2">
                  <span className="d-inline-flex font-25 ms-2 ps-2">
                    <Icon name={`${showDescription ? 'chat-close' : 'chat'}`}/>
                  </span>
                  توضیحات خدمت
                </span>
            </div>
            {
              (item?.service?.type?.id === 2 && insurance === ISSUER_TYPE.TAMIN.value) ?
                <div className="d-flex me-auto">
                  <div className=" me-auto">
                    <div className="form-group mb-2">
                      <SelectField
                        options={PhysiotherapyIllnessConstant}
                        name={`illness_${index}`}
                        placeholder='بیماری'
                        control={control}
                        error={errors}
                        onChange={handleFieldChange}
                        isDisabled={mode === VISIT_MODE.SHOW.value}
                        width={'250px'}
                      />
                    </div>
                  </div>
                  <div className="me-2">
                    <div className="form-group mb-2">
                      <SelectField
                        options={PhysiotherapyTreatmentPlanDetails}
                        name={`plan_${index}`}
                        placeholder='طرح درمان'
                        control={control}
                        error={errors}
                        onChange={handleFieldChange}
                        isDisabled={mode === VISIT_MODE.SHOW.value}
                        isMulti
                        width={'250px'}
                      />
                    </div>
                  </div>
                  <div className="w-70 me-2">
                    <div className="form-group mb-2">
                      <DebounceField
                        onChange={handleFieldChange}
                        name={`itemCount_${index}`}
                        className={`text-center`}
                        placeholder="تعداد"
                        control={control}
                        error={errors}
                        disabled={mode === VISIT_MODE.SHOW.value}
                        register={register(`root.${index}.itemCount`)}
                        watch={watch(`root.${index}.itemCount`)}
                      />
                    </div>
                  </div>
                </div> :
                <div className="d-flex me-auto">
                  <div className="col-3 me-auto">
                    <div className="form-group mb-2">
                      <DebounceField
                        onChange={handleFieldChange}
                        name={`itemCount_${index}`}
                        placeholder="تعداد"
                        control={control}
                        error={errors}
                        disabled={mode === VISIT_MODE.SHOW.value}
                        register={register(`root.${index}.itemCount`)}
                        watch={watch(`root.${index}.itemCount`)}
                      />
                    </div>
                  </div>
                  <div className="col-3 me-2">
                    <div className="form-group mb-2">
                      <DatePickerField
                        name={`activeDate_${index}`}
                        placeholder="ثبت تاریخ"
                        control={control}
                        error={errors}
                        onChange={handleFieldChange}
                        disabled={mode === VISIT_MODE.SHOW.value}
                        register={register(`root.${index}.activeDate`)}
                        watch={watch(`root.${index}.activeDate`)}
                      />
                    </div>
                  </div>
                </div>
            }

          </div>
          <div className="d-flex mb-2">
            {/*<div className="col-12 col-md-6 ps-2 me-4">*/}
            {/*  <div className="form-group">*/}
            {/*    <div className='d-flex border form-control mb-3 pl-5'>*/}
            {/*      <span>پاراکلینیک اورژانسی </span>*/}
            {/*      <span className='font-20 me-auto ms-1'>*/}
            {/*      <SwitchField*/}
            {/*        label={''}*/}
            {/*        onChange={handleFieldChange}*/}
            {/*        name={`emergency_${index}`}*/}
            {/*        control={control}*/}
            {/*        error={errors}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className={`border border-dark-subtle rounded mb-2 ${mode === VISIT_MODE.SHOW.value ? '' : 'cur-pointer'}`}
           onClick={() => mode === VISIT_MODE.EDIT.value && handleShowModal('addPrescriptionParaclinicalItemModal', {
             index: index,
             item: item,
             handleParaclinicalModalSubmit: handleParaclinicalModalSubmit,
             insurance: insurance
           })}
      >
        <div className="d-flex">
          <div className="d-flex col-9 pe-3 my-3">
            <div className="col-6 pe-3">
              <span className="font-9 fw-semibold">
                {item?.service.name}
              </span>
            </div>
            <div className="col-6 text-start pe-3">
              <span className="font-en font-9">
                {item?.service.enName ? item?.service?.enName : item?.service.name}
              </span>
            </div>
          </div>
          <div className="col-3 ps-2 my-3">
            <div className='d-flex justify-content-end align-items-center pl-5'>
              <div className='ltr'>
              <span className='fw-bold text-primary font-en ms-1 '> {(index + 1) + '. '} </span>
                <span
                  className={`rounded rounded-3 bg-primary text-white border border-2 border-primary font-12 text-primary text-nowrap px-2 py-1`}>
                      {item?.service?.type?.name}
                     </span>
              </div>
              {
                fieldValue?.message?.info?.map((item: string) => {
                  return (
                    <Tooltip alt={item} className='p-1 '>
                      <span className="d-flex align-content-center text-primary font-20 me-2"><Icon
                        name={'info-circle'}/></span>
                    </Tooltip>
                  );
                })
              }
              {
                fieldValue?.message?.warning?.map((item: string) => {
                  return (
                    <Tooltip alt={item} className='p-1'>
                      <span className="d-flex align-content-center text-yellow font-20 "><Icon
                        name={'info-circle'}/></span>
                    </Tooltip>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Collapse in={showDescription}>
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              onChange={handleFieldChange}
              rows={1}
              name={`note_${index}`}
              placeholder="توضیحات خدمت"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.note`)}
              watch={watch(`root.${index}.note`)}
            />
          </div>
        </div>
      </Collapse>
      {
        fieldValue?.message?.error?.map((item: string) => {
          return (
            <div className='bg-light-red mb-1'>
              <Icon name={'info'}/>
              <span>{item}</span>
            </div>
          );
        })
      }
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ParaclinicalPrescriptionRowItem);