import React from "react";
import {getCellValue} from "../../../../../../utils/utils";
import {TGQuantityData} from "../../../../../../types/general";

type EcgSummaryProps = {
  heartRate?: TGQuantityData
  pulseRate?: TGQuantityData
  leadDuration?: number
}

const EcgSummary = ({
  heartRate,
  pulseRate,
  leadDuration
}: EcgSummaryProps) => {

  return (
    <div className="d-flex justify-content-center align-items-center border-bottom pb-2">
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span className='font-en fw-semibold'>
          Heart Rate
        </span>
        <span className='d-flex align-items-center m-1 font-en'>
          <span className='font-9 text-primary fw-light'>HR ({heartRate?.unit})</span>
          <span className='font-30 text-primary fw-bold me-1'>{(heartRate?.magnitude)?.toFixed(0)}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span className='font-en fw-semibold'>
          Pulse Rate
        </span>
        <span className='d-flex align-items-center m-1 font-en'>
          <span className='font-9 text-primary fw-light'>({pulseRate?.unit})</span>
          <span className='font-30 text-primary fw-bold me-1'>{(pulseRate?.magnitude)?.toFixed(0)}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span>
          زمان نمونه
        </span>
        <span className='d-flex align-items-center m-1 font-en'>
          <span className='font-9 text-primary fw-light'>(minutes)</span>
          <span className='font-30 text-primary fw-bold me-1'>{getCellValue(leadDuration)}</span>
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center col-lg-3 col-md-6 col-sm-6">
        <span>
          مدل نمونه‌گیری
        </span>
        <span className='d-flex align-items-center bg-primary-subtle ps-4 pe-1 font-en'>
          <span className='d-flex align-items-center font-14 text-primary'>tandard</span>
        </span>
        <span className='d-flex align-items-center font-34 text-primary fw-bolder me-n4 mt-1'>S</span>
      </div>
    </div>
  );
};

export default EcgSummary;