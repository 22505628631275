import React, { useEffect, useMemo, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxLoader from "../../../components/general/boxLoader";
import TextField from "../../../components/general/form/textField";
import SelectField from "../../../components/general/form/selectField";
import {ROUTES} from "../../../../constants";
import {addMenuSchema} from "../../../../schema/menu-schema";
import {TAddMenuFields} from "../../../../types/menu";
import {useAddMenu, useGetAccessGroups} from "../../../../hook/request/menus";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}


const AddMenu = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [fieldValue, setFieldValue] = useState<TAddMenuFields>({
    name: '',
    url: '',
    icon: '',
    viewOrder: '',
    accessGroup: '',
    accessProcess: '',
  });
  const [accessGroupsList, setAccessGroupsList] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddMenuFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addMenuSchema),
  });


  // services
  // const getMenuRequest = useGetMenu();
  const addMenuRequest = useAddMenu();
  const getAccessGroupsRequest = useGetAccessGroups();

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
    handleGetMenusList(_filterValues)
  }, [searchParams]);

  const handleGetMenusList = (params: TFilterValues) => {
    getAccessGroupsRequest.mutate(params)
  }

  useEffect(() => {
    if (getAccessGroupsRequest?.data?.data?.result) {
      const { result } = getAccessGroupsRequest?.data?.data,
        _data = result.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setAccessGroupsList(_data);
    }
  }, [getAccessGroupsRequest.data])



  // useEffect(() => {
  //   id && getMenuRequest.mutate({id});
  // }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  // useEffect(() => {
  //   if (getMenuRequest?.data?.data?.result) {
  //     const { name, description, maxa } = getMenuRequest.data.data.result,
  //       _fieldValue = {
  //         name, description, maxa
  //       };
  //     setFieldValue(_fieldValue);
  //   }
  // }, [getMenuRequest.data])



  const addMenuSubmit: SubmitHandler<TAddMenuFields> = (data) => {
    const { name, url, icon, viewOrder, accessGroup} = data,
      body = {
        ...(id ? {id} : {}),
        name, url, icon, viewOrder,
        accessGroup: {
          id: accessGroup
        },
        accessProcess: {
          id: 1010
        }
      };
    addMenuRequest.mutate(body);
  }


  return (
    <div className="card-box mb-3">
      {
        (
          // getMenuRequest?.isPending ||
          addMenuRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addMenuSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات منو</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام</label>
                <TextField
                  name="name"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">آدرس</label>
                <TextField
                  name="url"
                  placeholder="آدرس"
                  className="plc-right text-start ltr"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">آیکن</label>
                <TextField
                  name="icon"
                  placeholder="آیکن"
                  className="plc-right text-start ltr"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">ترتیب</label>
                <TextField
                  name="viewOrder"
                  placeholder="ترتیب"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="category">گروه دسترسی</label>
                <SelectField
                  options={accessGroupsList}
                  name="accessGroup"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.MENU?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  {id ? "ویرایش منو" : "افزودن منو"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMenu;