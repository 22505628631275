import {getCellValue} from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Icon from "../../general/icon";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../general/tooltip";

type DeviceAssignedTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const DeviceAssignedTableRowItem = ({
 item,
 handleShowModal
}: DeviceAssignedTableRowItemProps) => {

  return (
    <tr>
      <td>{getCellValue(item?.healthUnit?.name)}</td>
      <td>{getCellValue(item?.account?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deleteAssignedDeviceModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DeviceAssignedTableRowItem);
