import React, {FC, useEffect, useRef, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import { createBrowserHistory } from "history";
// import indexRoutes from "./routes/index";
// import 'animate.css';
import { Toaster } from 'react-hot-toast';
import './assets/scss/style.scss';
import Login from './view/pages/auth/login';
import ExternalLayout from './view/layout/externalLayout';
import InternalLayout from './view/layout/internalLayout';
// Organization
import Organization from './view/pages/organizations';
import OrganizationsList from './view/pages/organizations/organizationsList';
import AddOrganization from './view/pages/organizations/addOrganization';
import AddRelation from './view/pages/organizations/addRelation';
// Doctor
import Doctor from './view/pages/doctors';
import DoctorsList from './view/pages/doctors/doctorsList';
import AddDoctor from './view/pages/doctors/addDoctor';
// Schedule
import Schedule from './view/pages/schedules';
import SchedulesList from './view/pages/schedules/schedulesList';
import AddSchedule from './view/pages/schedules/addSchedule';
import AddItemToSchedule from './view/pages/schedules/addItemToSchedule';
// Vacation
import Vacation from './view/pages/vacations';
import VacationsList from './view/pages/vacations/vacationsList';
import AddVacation from './view/pages/vacations/addVacation';
// Device
import Device from './view/pages/devices';
import DevicesList from './view/pages/devices/devicesList';
import AddDevice from './view/pages/devices/addDevice';
import AssignDevice from './view/pages/devices/assignDevice';
// PendingDevice
import PendingDevice from './view/pages/pendingDevices';
import PendingDevicesList from './view/pages/pendingDevices/pendingDevicesList';
// Patient
import Patient from './view/pages/patients';
import PatientsList from './view/pages/patients/patientsList';
// User
import User from './view/pages/users';
import UsersList from './view/pages/users/usersList';
import AddUser from './view/pages/users/addUser';
import UserAccess from './view/pages/users/userAccess';
// Appointment
import AppointmentsList from "./view/pages/appointments/appointmentsList";
import Appointments from "./view/pages/appointments";
// Visit
import Visits from "./view/pages/visits";
import VisitsList from "./view/pages/visits/visitsList";
// Basic Information
import BasicInformation from "./view/pages/basicInformation";
import VaccinesList from "./view/pages/basicInformation/vaccine/vaccinesList";
import ConditionsList from "./view/pages/basicInformation/condition/conditionsList";
import MedicationItemsList from "./view/pages/basicInformation/medication/medicationItemsList";
import AddMedicationItem from "./view/pages/basicInformation/medication/addMedicationItem";
import MedicationCategoriesList from "./view/pages/basicInformation/medicationCategory/medicationCategoriesList";
import ProgramsList from "./view/pages/basicInformation/program/programsList";
import MedicationItem from "./view/pages/basicInformation/medication";
import MedicationCategory from "./view/pages/basicInformation/medicationCategory";
import AddMedicationCategory from "./view/pages/basicInformation/medicationCategory/addMedicationCategory";
import Condition from "./view/pages/basicInformation/condition";
import AddCondition from "./view/pages/basicInformation/condition/addCondition";
import Vaccine from "./view/pages/basicInformation/vaccine";
import AddVaccine from "./view/pages/basicInformation/vaccine/addVaccine";
import Program from "./view/pages/basicInformation/program";
import AddProgram from "./view/pages/basicInformation/program/addProgram";
import Menu from "./view/pages/basicInformation/menu";
import MenusList from "./view/pages/basicInformation/menu/menusList";
import AddMenu from "./view/pages/basicInformation/menu/addMenu";
import ImagingService from "./view/pages/basicInformation/imagingService";
import ImagingServicesList from "./view/pages/basicInformation/imagingService/imagingServicesList";
import AddImagingService from "./view/pages/basicInformation/imagingService/addImagingService";
import LaboratoryService from "./view/pages/basicInformation/laboratoryService";
import LaboratoryServicesList from "./view/pages/basicInformation/laboratoryService/laboratoryServicesList";
import AddLaboratoryService from "./view/pages/basicInformation/laboratoryService/addLaboratoryService";
import LaboratoryServiceCategory from "./view/pages/basicInformation/laboratoryServiceCategory";
import LaboratoryServiceCategoriesList
  from "./view/pages/basicInformation/laboratoryServiceCategory/laboratoryServiceCategoriesList";
import AddLaboratoryServiceCategory
  from "./view/pages/basicInformation/laboratoryServiceCategory/addLaboratoryServiceCategory";
import Ehr from "./view/pages/patients/ehr";
import DeviceAssignedList from "./view/pages/devices/deviceAssignedList";
// Account
import Account from './view/pages/account';
import AccountPage from './view/pages/account/account';
import Profile from './view/pages/account/profile';
import ChangePassword from './view/pages/account/changePassword';
import LoginHistory from './view/pages/account/loginHistory';
import VisitDetail from "./view/pages/visits/visitDetail";
import Setting from './view/pages/setting';
import SettingInsurance from './view/pages/setting/settingInsurance';
import Video from "./view/pages/videoCall";
import VideoCall from "./view/pages/videoCall/videoCall";
import {Rnd} from "react-rnd";
import Dashboard from "./view/pages/dashboards";
import MainDashboard from "./view/pages/dashboards/mainDashboard";
import Examination from "./view/pages/examinations";
import ExaminationsList from "./view/pages/examinations/examinationsList";

// const history = createBrowserHistory();
const App = () => {
  return(
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="auth" element={<ExternalLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Route>

          <Route path="/" element={<PrivateRoute element={InternalLayout} />}>
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<MainDashboard />} />
            </Route>
            <Route path="/account" element={<Account />}>
              <Route index element={<AccountPage />} />
              <Route path="profile" element={<Profile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="login-history" element={<LoginHistory />} />
            </Route>
            <Route path="/organization" element={<Organization />}>
              <Route index element={<OrganizationsList />} />
              <Route path="new" element={<AddOrganization />} />
              <Route path=":id" element={<AddOrganization />} />
              <Route path="relation/:id" element={<AddRelation />} />
            </Route>
            <Route path="setting-issuer" element={<Setting />}>
              <Route index element={<SettingInsurance />} />
            </Route>
            <Route path="/doctor" element={<Doctor />}>
              <Route index element={<DoctorsList />} />
              <Route path="new" element={<AddDoctor />} />
              <Route path=":id" element={<AddDoctor />} />
            </Route>
            <Route path="/schedule" element={<Schedule />}>
              <Route index element={<SchedulesList />} />
              <Route path="new" element={<AddSchedule />} />
              <Route path=":id" element={<AddSchedule />} />
              <Route path="item/:id" element={<AddItemToSchedule />} />
            </Route>
            <Route path="/vacation" element={<Vacation />}>
              <Route index element={<VacationsList />} />
              <Route path="new" element={<AddVacation />} />
              <Route path=":id" element={<AddVacation />} />
            </Route>
            <Route path="/device" element={<Device />}>
              <Route index element={<DevicesList />} />
              <Route path="new" element={<AddDevice />} />
              <Route path=":id" element={<AddDevice />} />
              <Route path="assign/:id" element={<AssignDevice />} />
              <Route path="assign-list/:id" element={<DeviceAssignedList />} />
            </Route>
            <Route path="/pending-device" element={<PendingDevice />}>
              <Route index element={<PendingDevicesList />} />
            </Route>
            <Route path="/patient" element={<Patient />}>
              <Route index element={<PatientsList />} />
              <Route path="ehr/:id" element={<Ehr />} />
            </Route>
            <Route path="/user" element={<User />}>
              <Route index element={<UsersList />} />
              <Route path="new" element={<AddUser />} />
              <Route path=":id" element={<AddUser />} />
              <Route path="access/:id/:accountId" element={<UserAccess />} />
            </Route>
            <Route path="/appointment" element={<Appointments />}>
              <Route index element={<AppointmentsList />} />
            </Route>
            <Route path="/setting" element={<BasicInformation />}>
              <Route index element={<MedicationItemsList />} />
              <Route path="prescription" element={<MedicationItem />}>
                <Route index element={<MedicationItemsList />} />
                <Route path="new" element={<AddMedicationItem />} />
                <Route path=":id" element={<AddMedicationItem />} />
              </Route>
              <Route path="category" element={<MedicationCategory />}>
                <Route index element={<MedicationCategoriesList />} />
                <Route path="new" element={<AddMedicationCategory />} />
                <Route path=":id" element={<AddMedicationCategory />} />
              </Route>
              <Route path="vaccine" element={<Vaccine />}>
                <Route index element={<VaccinesList />} />
                <Route path="new" element={<AddVaccine />} />
                <Route path=":id" element={<AddVaccine />} />
              </Route>
              <Route path="condition" element={<Condition />}>
                <Route index element={<ConditionsList />} />
                <Route path="new" element={<AddCondition />} />
                <Route path=":id" element={<AddCondition />} />
              </Route>
              <Route path="program" element={<Program />}>
                <Route index element={<ProgramsList />} />
                <Route path="new" element={<AddProgram />} />
                <Route path=":id" element={<AddProgram />} />
              </Route>
              <Route path="menu" element={<Menu />}>
                <Route index element={<MenusList />} />
                <Route path="new" element={<AddMenu />} />
                <Route path=":id" element={<AddMenu />} />
              </Route>
            </Route>
            <Route path="/visit" element={<Visits />}>
              <Route index element={<VisitsList />} />
              <Route path=":id" element={<VisitDetail />} />
            </Route>
            <Route path="/examination" element={<Examination />}>
              <Route index element={<ExaminationsList />} />
              <Route path=":id" element={<VisitDetail />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

interface PrivateRouteProps {
  element: React.ComponentType;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ element: Element }) => {
  const token = localStorage.getItem('USER_DATA'); // Replace 'authToken' with your token key

  return token ? <Element /> : <Navigate to="/auth/login" replace />;
};


export default App;