import { useMutation, useQuery } from "@tanstack/react-query";
import { ScheduleService } from "../../services"
import { useNavigate } from "react-router-dom";
import { handleResponse, queryClient } from "./setup";
import Noti from "../../view/components/general/noti";
import { ROUTES } from "../../constants";

const useGetSchedules = (params: any) => {
  return useQuery({
    queryKey: ["getSchedules", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ScheduleService.getSchedulesList(params);
    },
  });
}

const useGetSchedule = () => {
  return useMutation({
    mutationFn: ScheduleService.getSchedule,
  })
}

const useAddSchedule = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ScheduleService.addSchedule,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.SCHEDULE.PATH);
        }
      })
    },
  })
}

const useCloseSchedule = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: ScheduleService.closeSchedule,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getSchedules"] })
          onSuccess();
        }
      })
    },
  })
}

const useGetScheduleItemsList = () => {
  return useMutation({
    mutationFn: ScheduleService.getScheduleItemsList,
  })
}

const useAddItemToSchedule = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: ScheduleService.addItemToSchedule,
    onSuccess: (data: any) => {
      onSuccess();
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
        }
      })
    },
  })
}

const useDeleteScheduleItem = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: ScheduleService.deleteScheduleItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          // queryClient.invalidateQueries({ queryKey: ["getDevices", variables] })
          onSuccess();
        }
      })
    },
  })
}

const useActiveSchedule = ({
                            onSuccess
                          }: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: ScheduleService.activeSchedule,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getSchedules"] })
          onSuccess();
        }
      })
    },
  })
}

export {
  useGetSchedules,
  useGetSchedule,
  useAddSchedule,
  useCloseSchedule,
  useGetScheduleItemsList,
  useAddItemToSchedule,
  useDeleteScheduleItem,
  useActiveSchedule
}
