import React from "react";
import { connect } from "react-redux";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";

const PendingDeviceTableRowItem = ({
  item,
  handleShowModal
}) => {

  return(
    <tr>
      <td>{getCellValue(item?.serial)}</td>
      <td>{getCellValue(item?.device)}</td>
      <td>{getCellValue(item?.imei)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        {item?.pending ?
          <div>
            <span className='bg-gray4 px-3 py-1 rounded text-nowrap'>
              در انتظار بررسی
            </span>
          </div>
          :
          <div>
            <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
              تایید شده
            </span>
          </div>
        }
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'رد کردن'} className="tooltip-pre-line">
            <span
              onClick={() => item?.pending && handleShowModal('rejectPendingDeviceModal', item?.id)}
              className={`d-flex mx-2 font-20 cur-pointer ${item?.pending ? 'text-red-hover' : 'disabled text-gray3'}`}
            >
              <Icon name='close-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'تایید کردن'} className="tooltip-pre-line">
            <span
              onClick={() => item?.pending && handleShowModal('approvePendingDeviceModal', item?.id)}
              className={`d-flex text-green2-hover r mx-2 font-20 cur-pointer ${item?.pending ? 'text-green2-hover' : 'disabled text-gray3'}`} aria-disabled
            >
              <Icon name='check-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'جزئیات'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('pendingDeviceInfoModal', item?.id)}
              className="d-flex text-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='eye'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PendingDeviceTableRowItem);
