import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {isBetween, isPastDay, MtoJFullDateFormat} from "../../../../../../utils/date";
import Tooltip from "../../../../general/tooltip";


type PatientConditionCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const PatientConditionCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}: PatientConditionCardsBoxItemProps) => {

  const getConditionState = () => {
    let state = "";
    const isEndedSchedule = isPastDay(item?.endDateTime),
      isCurrentSchedule = isBetween(new Date() ,item.startDateTime, item.endDateTime),
      isCurrentWithNullToDateSchedule = item?.endDateTime == null && isPastDay(item?.startDateTime);
    if(isEndedSchedule) state = "پایان یافته";
    else if(isCurrentSchedule || isCurrentWithNullToDateSchedule) state = "جاری";
    return state;
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.condition?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[1]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.startDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{item?.endDateTime ? getCellValue(MtoJFullDateFormat(item?.endDateTime)) : '-'}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="description">{dataListTitles[3]}</label>
        <span
          className="mr-2">{getConditionState()}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deletePatientConditionModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientConditionCardsBoxItem);