import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useGetPatientSummary } from "../../../../../hook/request/ehr/patientSummary";
import { getCellValue } from "../../../../../utils/utils";
import {
  monthDay,
  MtoJHHMMFormat,
  yearTitle,
} from "../../../../../utils/date";
import PatientAllergySummary from "../../../../components/patients/ehr/patientSummary/patientAllergySummary/patientAllergySummary";
import PatientVaccineSummary from "../../../../components/patients/ehr/patientSummary/patientVaccineSummary/patientVaccineSummary";
import PatientAttachmentSummary from "../../../../components/patients/ehr/patientSummary/patientAttachmentSummary/patientAttachmentSummary";
import PatientConditionSummary from "../../../../components/patients/ehr/patientSummary/patientConditionSummary/patientConditionSummary";
import PatientProgramSummary from "../../../../components/patients/ehr/patientSummary/patientProgramSummary/patientProgramSummary";
import PatientVisitSummary from "../../../../components/patients/ehr/patientSummary/patientVisitSummary/patientVisitSummary";
import PatientBiometricSummary from "../../../../components/patients/ehr/patientSummary/patientBiometricSummary/patientBiometricSummary";
import PatientVitalSummary from "../../../../components/patients/ehr/patientSummary/patientVitalSummary/patientVitalSummary";
import PatientSummaryHistoryCard from "./patientSummaryHistoryCard";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector,
} from "../../../../../store/selector/general";
import { general } from "../../../../../store/action";
import { connect } from "react-redux";
import {
  isBetween,
  isPastDay,
  MtoJDateFormat,
} from "../../../../../utils/date";
import Cigarettes from "../../../../../assets/images/svg/cigarettes.svg";
import Family from "../../../../../assets/images/svg/family.svg";
import History from "../../../../../assets/images/svg/history.svg";
import Surgery from "../../../../../assets/images/svg/surgery.svg";
import {CIGARETTE_CONSUMPTION, getValueById} from "../../../../../constants/constant/enum";
import {truncate} from "../../../../../utils/validation";
import AddPatientFamilyHistoryModal
  from "../../../../components/patients/ehr/patientSummary/familyHistory/addPatientFamilyHistoryModal";
import ShowPatientFamilyHistoryModal
  from "../../../../components/patients/ehr/patientSummary/familyHistory/showPatientFamilyHistoryModal";
import AddPatientCigarettesModal
  from "../../../../components/patients/ehr/patientSummary/cigarettes/addPatientCigarettesModal";
import ShowPatientListCigarettesModal
  from "../../../../components/patients/ehr/patientSummary/cigarettes/showPatientCigarettesList";
import AddPatientMisuseModal from "../../../../components/patients/ehr/patientSummary/misuse/addPatientMisuseModal";
import ShowPatientListMisuseModal
  from "../../../../components/patients/ehr/patientSummary/misuse/showPatientMisuseListModal";
import AddPatientSurgeryModal from "../../../../components/patients/ehr/patientSummary/surgery/addPatientSurgeryModal";
import ShowPatientListSurgeryModal
  from "../../../../components/patients/ehr/patientSummary/surgery/showPatientSurgeryListModal";

type TSummaryFieldValue = {
  vital: any;
  biometric: any;
  cigarette?: any;
  cigaretteCount?: any;
  familyHistory?: any;
  familyHistoryCount?: any;
  misuse?: any;
  misuseCount?: any;
  surgery?: any;
  surgeryCount?: any;
};

type patientSummaryProps = {
  patientId?: string;
  openTab?: string;
  setOpenTab?: any;
  handleShowModal?: any;
  visitEHRCheck?: boolean;
};

const PatientSummary = ({
  patientId,
  openTab,
  setOpenTab,
  handleShowModal,
  visitEHRCheck = false,
}: patientSummaryProps) => {
  const [fieldValue, setFieldValue] = useState<TSummaryFieldValue>({
    vital: "",
    biometric: "",
    cigarette: "",
    cigaretteCount: 0,
    familyHistory: "",
    familyHistoryCount: 0,
    misuse: "",
    misuseCount: 0,
    surgery: "",
    surgeryCount: 0,
  });

  // services
  const getPatientSummaryRequest = useGetPatientSummary();

  useEffect(() => {
    var data = {
      id: patientId,
    };
    patientId !== '0' && handleGetPatientSummary(data);
  }, [patientId]);

  const handleGetPatientSummary = (data: any) => {
    getPatientSummaryRequest.mutate(data);
  };

  useEffect(() => {
    if (getPatientSummaryRequest?.data?.data?.result) {
      const {
          vital,
          biometric,
          cigarette,
          cigaretteCount,
          familyHistory,
          familyHistoryCount,
          misuse,
          misuseCount,
          surgery,
          surgeryCount,
        } = getPatientSummaryRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          vital,
          biometric,
          cigarette,
          cigaretteCount,
          familyHistory,
          familyHistoryCount,
          misuse,
          misuseCount,
          surgery,
          surgeryCount,
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientSummaryRequest.data]);

  const getStatus = (startDate: any, endDate: any) => {
    let state = "";
    const isEndedSchedule = isPastDay(endDate),
      isCurrentSchedule = isBetween(
        new Date(),
        startDate,
        endDate
      ),
      isCurrentWithNullToDateSchedule =
        endDate == null && isPastDay(startDate);
    if (isEndedSchedule) state = "پایان یافته";
    else if (isCurrentSchedule || isCurrentWithNullToDateSchedule)
      state = "جاری";
    return state;
  };
  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <div className="border rounded">
          <div className="px-2">
            <h4 className="mx-1 mb-3 mt-1">
              <span className="font-16">آخرین شاخص ها و بیومتریک ها&emsp;</span>
              <span className="text-black-50 font-11">
                {monthDay(fieldValue?.vital?.updateDate) +
                  " " +
                  yearTitle(fieldValue?.vital?.updateDate) +
                  " ، " +
                  MtoJHHMMFormat(fieldValue?.vital?.updateDate)}
              </span>
            </h4>
          </div>
          <div className="row row-cols-2 row-cols-md-4 row-cols-lg-8">
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">فشار خون</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50"> mmHg &ensp; </span>
                <span className="font-weight-bold">
                  <bdi>
                    {getCellValue(fieldValue?.vital?.bpSystolic?.magnitude)} /{" "}
                    {getCellValue(fieldValue?.vital?.bpDiastolic?.magnitude)}
                  </bdi>
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">ضربان قلب</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> beats/min &ensp; </span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.vital?.pulseRate?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">نرخ تنفس</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> breaths/min &ensp; </span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.vital?.respiratoryRate?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">اکسیژن اشباع خون</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> ٪ &ensp;</span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.vital?.spo2?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">درجه حرارت</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> DEG C &ensp; </span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.vital?.temperature?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">وزن</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> kg &ensp;</span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.biometric?.weight?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">قد</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> cm &ensp;</span>
                <span className="font-weight-bold">
                  {getCellValue(fieldValue?.biometric?.height?.magnitude)}
                </span>
              </div>
            </div>
            <div className="col px-2 mb-2 text-center">
              <div className="font-14 mb-1">شاخص توده بدنی</div>
              <div className="font-11 d-flex justify-content-center">
                <span className="text-black-50 rtl"> kg/m &ensp;</span>
                <span className="font-weight-bold">
                  {
                    fieldValue?.biometric?.bmi ? (Math.round(
                    getCellValue(fieldValue?.biometric?.bmi?.magnitude) * 100
                    ) / 100) : '-'
                  }
                </span>
              </div>
            </div>
          </div>
        </div>

        <Row className='d-flex flex-wrap'>
          <Col sm={6} md={6} lg={3} className='d-flex flex-column flex-fill mt-4'>
            <PatientSummaryHistoryCard
              title="جراحی"
              count={fieldValue?.surgeryCount}
              titleName="surgery"
              date={MtoJDateFormat(fieldValue?.surgery?.updateDate)}
              description={getCellValue(fieldValue?.surgery?.surgery?.name)}
              status={getCellValue(getStatus(fieldValue?.surgery?.surgeryDate, null))}
              visitEHRCheck={visitEHRCheck}
              onClick={() =>
                handleShowModal("showAddSurgery", {
                  id: fieldValue?.surgery?.id,
                  patientId: patientId,
                  handleGetPatientSummary: handleGetPatientSummary,
                })
              }
              onClickShowList={() =>
                handleShowModal("showListSurgery", {
                  patientId: patientId,
                })
              }
              icon={Surgery}
            />
          </Col>
          <Col sm={6} md={6} lg={3} className='d-flex flex-column flex-fill mt-4'>
            <PatientSummaryHistoryCard
              title="سابقۀ سوء مصرف"
              count={fieldValue?.misuseCount}
              titleName="misuse"
              date={MtoJDateFormat(fieldValue?.misuse?.updateDate)}
              description={getCellValue(fieldValue?.misuse?.substanceMisuse?.name)}
              status={getCellValue(getStatus(fieldValue?.misuse?.startDate, fieldValue?.misuse?.endDate))}
              visitEHRCheck={visitEHRCheck}
              onClick={() =>
                handleShowModal("showAddMisuse", {
                  id: fieldValue?.misuse?.id,
                  patientId: patientId,
                  handleGetPatientSummary: handleGetPatientSummary,
                })
              }
              onClickShowList={() =>
                handleShowModal("showListMisuse", { patientId: patientId })
              }
              icon={History}
            />
          </Col>
          <Col sm={6} md={6} lg={3} className='d-flex flex-column flex-fill mt-4'>
            <PatientSummaryHistoryCard
              title="میزان مصرف سیگار"
              count={fieldValue?.cigaretteCount}
              titleName="cigarettes"
              date={MtoJDateFormat(fieldValue?.cigarette?.updateDate)}
              description={getValueById(fieldValue?.cigarette?.frequency?.id, CIGARETTE_CONSUMPTION, 'enName') ?? '-'}
              status={getCellValue(getStatus(fieldValue?.cigarette?.startDate, fieldValue?.cigarette?.endDate))}
              visitEHRCheck={visitEHRCheck}
              onClick={() =>
                handleShowModal("showAddCigarettes", {
                  patientId: patientId,
                  handleGetPatientSummary: handleGetPatientSummary,
                })
              }
              onClickShowList={() =>
                handleShowModal("showListCigarettes", { patientId: patientId })
              }
              icon={Cigarettes}
            />
          </Col>
          <Col sm={6} md={6} lg={3} className='d-flex flex-column flex-fill mt-4'>
            <PatientSummaryHistoryCard
              title="سابقۀ خانوادگی"
              count={fieldValue?.familyHistoryCount}
              titleName="Family"
              date={truncate(getCellValue(fieldValue?.familyHistory?.icd11?.name), 35)}
              description={getCellValue(fieldValue?.familyHistory?.relation?.name)}
              status={'-'}
              visitEHRCheck={visitEHRCheck}
              onClick={() =>
                handleShowModal("showAddFamily", {
                  patientId: patientId,
                  handleGetPatientSummary: handleGetPatientSummary,
                })
              }
              onClickShowList={() => handleShowModal("showListFamily", {patientId: patientId})}
              icon={Family}
            />
          </Col>
        </Row>
        <div className="d-flex flex-wrap">
          <div className="d-flex flex-wrap w-100">
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="ms-md-3 bg-gray rounded h-100 d-flex flex-column">
                <PatientVitalSummary
                  patientData={patientId}
                  initialOpenTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="me-md-3 bg-gray rounded h-100 d-flex flex-column">
                <PatientBiometricSummary
                  patientData={patientId}
                  initialOpenTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap w-100">
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="ms-md-3 border rounded h-100 d-flex flex-column">
                <PatientAllergySummary
                  patientData={patientId}
                  openTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="me-md-3 border rounded h-100 d-flex flex-column">
                <PatientVaccineSummary
                  patientData={patientId}
                  openTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap w-100">
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="ms-md-3 border rounded h-100 d-flex flex-column">
                <PatientAttachmentSummary
                  patientData={patientId}
                  openTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="me-md-3 border rounded h-100 d-flex flex-column">
                <PatientConditionSummary
                  patientData={patientId}
                  openTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap w-100">
            <div className="col-lg-6 col-12 col-md-12 mt-4">
              <div className="ms-md-3 border rounded h-100 d-flex flex-column">
                <PatientProgramSummary
                  patientData={patientId}
                  openTab={openTab}
                  visitEHRCheck={visitEHRCheck}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="ms-md-3 border rounded">
              <PatientVisitSummary
                patientData={patientId}
                openTab={openTab}
                setOpenTab={setOpenTab}
              />
            </div>
          </div>
        </div>
      </div>

      <AddPatientSurgeryModal />
      <AddPatientMisuseModal />
      <AddPatientCigarettesModal />
      <AddPatientFamilyHistoryModal />
      <ShowPatientListSurgeryModal />
      <ShowPatientListMisuseModal />
      <ShowPatientListCigarettesModal />
      <ShowPatientFamilyHistoryModal />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientSummary);
