import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import { MtoJFullDateFormat } from "../../../../utils/date";
import Tooltip from "../../general/tooltip";

const DoctorTableRowItem = ({
  item,
  handleShowModal
}) => {
  const navigate = useNavigate();

  return(
    <tr>
      <td>{getCellValue(item?.firstName)} {getCellValue(item?.lastName)}</td>
      <td>{getCellValue(item?.specialty?.name)}</td>
      <td>{getCellValue(item?.nationalCode)}</td>
      <td>{getCellValue(item?.mobile)}</td>
      <td>{getCellValue(item?.medId)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </td>
      <td>
        {item?.banned ?
          <div>
            <span className='bg-light-red text-red px-3 py-1 rounded text-nowrap'>
              غیر فعال
            </span>
          </div>
          :
          <div>
            <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
              فعال
            </span>
          </div>
        }
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={item?.banned ? 'فعال سازی حساب کاربری' : 'غیر فعال سازی حساب کاربری'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('blockingUserModal', {accountId: item?.accountId, banned: item?.banned, queryKey: "getDoctors"})}
              className={`d-flex mx-2 font-20 cur-pointer ${item?.banned ? 'text-hover' : 'text-red-hover'}`}
            >
              <Icon name={item?.banned ? 'check-square' : 'close-square'}/>
            </span>
          </Tooltip>
          <Tooltip alt={'تغییر کلمه عبور'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('changeAccountPasswordModal', {firstName: item?.firstName, lastName: item?.lastName, accountId: item?.accountId})}
              className="d-flex text-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='key'/>
            </span>
          </Tooltip>
          <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
            <span
              onClick={() => navigate(generatePath(ROUTES.DOCTOR_EDIT.PATH, {id: item?.id}))}
              className="d-flex text-hover mx-2 font-18 cur-pointer"
            >
              <Icon name='pen-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deleteDoctorModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DoctorTableRowItem);
