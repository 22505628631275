import {Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {TEcgQtc} from "../../../../../../types/ecg";
import {getCellValue} from "../../../../../../utils/utils";

type ExaminationFormulaTableProps = {
  ecgQtc: TEcgQtc
}

const ExaminationFormulaTable = ({
 ecgQtc
}: ExaminationFormulaTableProps) => {

  const [formulaTableData, setFormulaTableData] = useState([
    {
      qtc: 'Bazett',
      formula: 'QTc = QT / RR',
      value: 0,
      textColor: 'text-orange'
    }, {
      qtc: 'Fridericia',
      formula: 'QTc = QT / RR1 / 3',
      value: 0,
      textColor: 'text-green2'
    }, {
      qtc: 'Hodges',
      formula: 'QTc = QT + 0.00175 * (HR - 60)',
      value: 0,
      textColor: 'text-green2'
    }, {
      qtc: 'Framingham',
      formula: 'QTc = QT + 0.154 * (1 - RR)',
      value: 0,
      textColor: 'text-primary'
    }, {
      qtc: 'Rautaharju',
      formula: 'QTc = QT * (120 + HR) / 180',
      value: 0,
      textColor: 'text-primary'
    },
  ])

  useEffect(() => {
    const updatedFormulaTableData = formulaTableData.map(item => {
      const key = item.qtc.toLowerCase() as keyof TEcgQtc;
      return {
        ...item,
        value: ecgQtc[key] ?? item.value, // Use nullish coalescing to default to existing value
      };
    });
    setFormulaTableData(updatedFormulaTableData);
  }, [ecgQtc]);

  return (
    <div className='d-flex justify-content-center w-100'>
      <div className='d-flex justify-content-center w-50 h-100'>
        <Table bordered hover className='text-center ltr'>
          <thead>
          <tr>
            <th>QTc</th>
            <th>Formula</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          {
            formulaTableData?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td><span className={item?.textColor}>{item?.qtc}</span></td>
                  <td className='text-start'><span className={item?.textColor}>{item?.formula}</span></td>
                  <td><span className={item?.textColor}>{getCellValue(item?.value.toFixed(2))}</span></td>
                </tr>
              )
            })
          }
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ExaminationFormulaTable;