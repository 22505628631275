import React, { useEffect, useMemo, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {useAddMedicationItem, useGetMedicationItem} from "../../../../hook/request/medication";
import BoxLoader from "../../../components/general/boxLoader";
import TextField from "../../../components/general/form/textField";
import SelectField, {AsyncSelectField} from "../../../components/general/form/selectField";
import {ROUTES} from "../../../../constants";
import {TAddMedicationItemFields} from "../../../../types/medicationItem";
import TextAreaField from "../../../components/general/form/textAreaField";
import {useGetMAXAList} from "../../../../hook/request/maxa";
import {useGetMedicationCategories} from "../../../../hook/request/medicationCategory";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {asyncSelectLoadOptions} from "../../../../utils/asyncSelectLoadOptions";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const AddMedicationItem = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();

  const [fieldValue, setFieldValue] = useState<TAddMedicationItemFields>({
    name: '',
    description: '',
    category: {
      id: '',
      name: ''
    },
    maxa: ''
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddMedicationItemFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    // resolver: yupResolver(addMedicationItemSchema),
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [maxaList, setMAXAList] = useState([]);
  const [categoryFilterValues, setCategoryFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [MAXAFilterValues, setMAXAFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });


  // services
  const getMAXAListRequest = useGetMAXAList(getQueryParams(MAXAFilterValues));
  const getMedicationCategoriesRequest = useGetMedicationCategories(getQueryParams(categoryFilterValues));
  const getMedicationItemRequest = useGetMedicationItem();
  const addMedicationItemRequest = useAddMedicationItem();


  useEffect(() => {
    let _MAXAFilterValues = {};
    _MAXAFilterValues = {
      ...MAXAFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setMAXAFilterValues(_MAXAFilterValues)
  }, [searchParams]);

  useEffect(() => {
    let _CategoryFilterValues = {};
    _CategoryFilterValues = {
      ...categoryFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setCategoryFilterValues(_CategoryFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getMAXAListRequest?.data?.data?.result) {
      const { data } = getMAXAListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.value, value: item?.id }
        ))
      setMAXAList(_data);
    }
  }, [getMAXAListRequest.data])

  useEffect(() => {
    id && getMedicationItemRequest.mutate({id});
  }, [id])

  useEffect(() => {
    if (getMedicationCategoriesRequest?.data?.data?.result) {
      const { data } = getMedicationCategoriesRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setCategoriesList(_data);
    }
  }, [getMedicationCategoriesRequest.data])

  useEffect(() => {
    id && getMedicationItemRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getMedicationItemRequest?.data?.data?.result) {
      const { name, description, category, maxa } = getMedicationItemRequest.data.data.result,
        _fieldValue = {
          name, description,
          category: category?.id,
          maxa: maxa?.id
        };
      setFieldValue(_fieldValue);
    }
  }, [getMedicationItemRequest.data])

  const maxaListLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, MAXAFilterValues, setMAXAFilterValues, getMAXAListRequest,
      (item) => ({ label: item?.value, value: item?.id })
    );
  };

  const addMedicationItemSubmit: SubmitHandler<TAddMedicationItemFields> = (data) => {
    const { name, description, category, maxa } = data,
      body = {
        ...(id ? {id} : {}),
        name, description,
        category: {
          id: category,
          name: ""
        },
        maxa: {
          id: maxa,
          name: ''
        }
      };
    console.log(data);

    addMedicationItemRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getMAXAListRequest?.isPending ||
          getMedicationItemRequest?.isPending ||
          addMedicationItemRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addMedicationItemSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات دارو</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام</label>
                <TextField
                  name="name"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4"></div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="category">دسته بندی</label>
                <SelectField
                  options={categoriesList}
                  name="category"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="maxa">مکسا</label>
                <AsyncSelectField
                  options={maxaList}
                  name="maxa"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                  asyncLoadOptions={maxaListLoadOptions}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  placeholder="توضیاحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.MEDICATION?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  { id ? "ویرایش دارو" : "افزودن دارو" }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMedicationItem;