import React from 'react';
import { NavLink } from 'react-router-dom';
import { hasRoutePermission } from '../../../utils/hasPermission';
import Icon from '../general/icon';
import Tooltip from '../general/tooltip';

type TSidebarItem = {
  item: any;
  showDrawer: boolean
}

const SidebarItem = ({
  item,
  showDrawer
}: TSidebarItem) => {
  const subRoutesLength = () => {
    const subRoutes = item.subRoutes.filter((innerItem: any) => innerItem.isShowSideBar && hasRoutePermission(innerItem));
    return subRoutes.length
  }
  return(
    <>
      {
        item.subRoutes && subRoutesLength() > 1 ? 
          <div className="subroutes-box item-lst text-nowrap">
            <div className="item-lnk">
              <span className="icon-normal"><Icon name={item.icon} /></span>
              <span className="item-txt hide-collapsed">{item.title}</span>
              <span className='chevron-down mr-auto'><Icon name='chevron-down' /></span>
            </div>
            <div className='subroutes-items'>
              {
                item.subRoutes.map((innerItem: any, i: number) => {
                  if(innerItem.isShowSideBar && hasRoutePermission(innerItem)) 
                    return <SidebarInnerItem item={innerItem} showDrawer={showDrawer} key={i} />
                  return false;
                })
              }
            </div>
          </div> :
          <div className="item-lst text-nowrap">
            <SidebarInnerItem item={item} showDrawer={showDrawer} />
          </div>
      }
    </>
  )
}

const SidebarInnerItem = ({
  item,
  showDrawer
}: TSidebarItem) => {
  return (
    <>
      {
        showDrawer ? 
          <NavLink to={item.url} className="item-lnk my-1">
            <span className="icon-normal"><Icon name={item.icon} /></span>
            <span className="item-txt hide-collapsed">{item.name}</span>
          </NavLink> :
          <Tooltip alt={item.name} placement='left'>
            <NavLink to={item.url} className="item-lnk my-1">
              <span className="icon-normal"><Icon name={item.icon} /></span>
              <span className="item-txt hide-collapsed">{item.name}</span>
            </NavLink>
          </Tooltip>
      }
    </>
  )
}

export default SidebarItem;
