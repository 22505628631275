import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { general } from "../../../store/action";
import { isHideModalDataSelector, isShowModalDataSelector, modalDataSelector, modalNameSelector } from "../../../store/selector/general";
import BoxLoader from "../general/boxLoader";
import Icon from "../general/icon";
import Modal from "../general/modal";
import { useRejectPendingDevice } from "../../../hook/request/pendingDevices";

const RejectPendingDeviceModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    if(
      isShowModalData && 
      modalName === 'rejectPendingDeviceModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  // services
  // const getDevicesRequest = useGetDevices();
  const deletePendingDeviceRequest = useRejectPendingDevice({
    onSuccess: () => {
      // let _filterValues = {};
      // _filterValues = {
      //   pageNumber: searchParams.get("pageNumber") || "0",
      //   perPage: searchParams.get("perPage") || "10",
      //   search: searchParams.get("search") || ""
      // }
      handleSetShowModal();
      // getDevicesRequest.mutate(_filterValues);
    }
  });

  const deletePendingDeviceSubmit = () => {
    const body = {id: modalInfo};
    deletePendingDeviceRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      { deletePendingDeviceRequest?.isPending && <BoxLoader type="cover" /> }
      <div className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <span className="delete-item-bg d-flex justify-content-center align-items-center text-red font-25 pt-4">
            <Icon name='trash' />
          </span>
        </div>
        <h4 className="font-14 mb-2">کاربر گرامی</h4>
        <p className="text-black">
          آیا از ردکردن این دستگاه اطمینان دارید؟
        </p>
      </div>

      <div className="d-flex justify-content-center mt-4 pt-2">
        <button 
          onClick={() => handleSetShowModal()} 
          className="btn btn-outline-gray flex-grow-1 py-2 mx-1"
        >
          انصراف
        </button>
        <button
          onClick={deletePendingDeviceSubmit}
          className="btn btn-danger d-flex align-items-center py-2 mx-1"
        >
          بله رد شود
        </button>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectPendingDeviceModal);
