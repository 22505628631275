import React, {useEffect, useState} from "react";
import AddVisitModal from "../../components/visits/addVisitModal";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import {useGetDashboardStatistics} from "../../../hook/request/systemReport";
import PatientCountAnimated from "../../../assets/images/svgComponents/animate/patientCountAnimated";
import halfCircleRotated from "../../../assets/images/svg/halfCircleRotated.svg";
import halfCircle from "../../../assets/images/svg/halfCircle.svg";
import TwoPerson from "../../../assets/images/svgComponents/icon/twoPerson";
import DoctorsAnimated from "../../../assets/images/svgComponents/animate/doctorsAnimated";
import DoctorCountAnimated from "../../../assets/images/svgComponents/animate/doctorCountAnimated";
import OrganizationsAnimated from "../../../assets/images/svgComponents/animate/OrganizationsAnimated";
import DashboardSquareDiagonal from "../../../assets/images/svgComponents/icon/dashboardSquareDiagonal";
import DashboardSquare from "../../../assets/images/svgComponents/icon/dashboardSquare";
import DashboardCarouselCards from "../../components/dashboards/operator/dashboardCarouselCards";
import Chart from "../../../assets/images/svgComponents/icon/chart";
import OrganizationVisitCountChart from "../../components/dashboards/operator/organizationVisitCountChart";
import PrescriptionsByIssuerTypeChart from "../../components/dashboards/operator/prescriptionsByIssuerTypeChart";
import PendingDevices from "../../components/dashboards/operator/pendingDevices";
import RecentVisits from "../../components/dashboards/operator/recentVisits";
import DoctorsOnVacation from "../../components/dashboards/operator/doctorsOnVacation";
import CardiologistAnimated from "../../../assets/images/svgComponents/animate/cardiologistAnimated";
import MedicalKit from "../../../assets/images/svgComponents/icon/medicalKit";
import VisitCountAnimated from "../../../assets/images/svgComponents/animate/visitCountAnimated";
import Pills from "../../../assets/images/svgComponents/icon/pills";
import AssignedDevicesCountAnimated from "../../../assets/images/svgComponents/animate/assignedDevicesCountAnimated";
import MyAssignedDevicesCountAnimated
  from "../../../assets/images/svgComponents/animate/myAssignedDevicesCountAnimated";


type TOperatorDashboardStatistics = {
  device: number;
  assignedDevice: number;
  myAssignedDevice: number;
  patient: number;
  operator: number;
  provider: number;
  healthcareOrganization: number;
  visit: number;
}

const OperatorDashboard = () => {

  const [dashboardData, setDashboardData] = useState<TOperatorDashboardStatistics>({
    device: 0,
    assignedDevice: 0,
    myAssignedDevice: 0,
    patient: 0,
    operator: 0,
    provider: 0,
    healthcareOrganization: 0,
    visit: 0,
  });

  //services
  const getDashboardStatistics = useGetDashboardStatistics()


  useEffect(() => {
    if(!getDashboardStatistics.isPending && getDashboardStatistics?.data?.data?.result){
      const { device, assignedDevice, myAssignedDevice, patient, operator, provider, healthcareOrganization, visit } = getDashboardStatistics?.data?.data?.result;
      setDashboardData({device, assignedDevice, myAssignedDevice, patient, operator, provider, healthcareOrganization, visit})
    }
  }, [getDashboardStatistics.data])

  useEffect(() => {
    getDashboardStatistics.mutate({operator: true})
  }, []);


  return (
    <>
      <div className="row flex-wrap justify-content-center">
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="dashboard-card-box justify-content-center h-100"
               style={{
                 background: `url(${halfCircle}) no-repeat top left`,
                 backgroundColor: '#FFF'
               }}
          >
            <DashboardCarouselCards
              icon={[<Pills/>, <MedicalKit/>]}
              text={['بیماران', 'ویزیت‌ها']}
              amount={[dashboardData?.patient, dashboardData?.visit]}
              svg={[<PatientCountAnimated/>, <VisitCountAnimated/>]}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="dashboard-card-box justify-content-center h-100"
               style={{
                 background: `url(${halfCircleRotated}) no-repeat top right`,
                 backgroundColor: '#FFF'
               }}
          >
            <DashboardCarouselCards
              icon={[<DashboardSquareDiagonal/>, <TwoPerson/>]}
              text={['پزشکان', 'اپراتور‌‌ها']}
              amount={[dashboardData?.provider, dashboardData?.operator]}
              svg={[<DoctorCountAnimated/>, <DoctorsAnimated/>]}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="dashboard-card-box justify-content-center h-100"
               style={{
                 background: `url(${halfCircle}) no-repeat top left`,
                 backgroundColor: '#FFF'
               }}
          >
            <DashboardCarouselCards
              icon={[<DashboardSquare/>, <Chart/>]}
              text={['مراکز', 'دستگاه‌ها']}
              amount={[dashboardData?.healthcareOrganization, dashboardData?.device]}
              svg={[<OrganizationsAnimated/>, <CardiologistAnimated/>]}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
          <div className="dashboard-card-box justify-content-center h-100"
               style={{
                 background: `url(${halfCircleRotated}) no-repeat top right`,
                 backgroundColor: '#FFF'
               }}
          >
            <DashboardCarouselCards
              icon={[<Chart/>, <Chart/>]}
              text={['دستگاه‌های تخصیص یافته', 'دستگاه‌های من']}
              amount={[dashboardData?.assignedDevice, dashboardData?.myAssignedDevice]}
              svg={[<AssignedDevicesCountAnimated/>, <MyAssignedDevicesCountAnimated/>]}
            />
          </div>
        </div>
      </div>
      <div className='row flex-wrap justify-content-center'>
        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
          <OrganizationVisitCountChart/>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
          <PrescriptionsByIssuerTypeChart/>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <PendingDevices/>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <RecentVisits/>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <DoctorsOnVacation/>
        </div>
        <AddVisitModal/>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(OperatorDashboard);