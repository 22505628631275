import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useWindowSize} from "../../../../hook/useWindowSize";
import Icon from "../../../components/general/icon";
import {ROUTES} from "../../../../constants";
import {useGetMenus} from "../../../../hook/request/menus";
import MenusTable from "../../../components/basicInformation/menu/dataList/menusTable";
import MenuCardsBox from "../../../components/basicInformation/menu/dataList/menuCardsBox";
import DeleteMenuModal from "../../../components/basicInformation/menu/deleteMenuModal";

const dataListTitles = [
  "نام منو",
  "آدرس",
  "آیکن",
  "گروه دسترسی",
  "Access Process",
]

const MenusList = () => {

  const [width] = useWindowSize();
  const [menusData, setMenusData] = useState([]);

  // services
  const getMenusRequest = useGetMenus();

  useEffect(() => {
    getMenusRequest.mutate()
  }, []);

  useEffect(() => {
    getMenusRequest?.data?.data?.result &&
    setMenusData(getMenusRequest?.data?.data?.result)
  }, [getMenusRequest.data])

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-3 m-md-0">
            <Link to={ROUTES.MENU_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center px-3">
              <span className="d-inline-flex font-20 ms-2"><Icon name="add-square"/></span>
              افزودن منو
            </Link>
          </div>
          <div className="form-group me-md-auto">
          </div>
        </section>
        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <MenuCardsBox
                dataList={menusData}
                dataListTitles={dataListTitles}
                isRequesting={getMenusRequest.isPending}
              /> :
              <MenusTable
                dataList={menusData}
                dataListTitles={dataListTitles}
                isRequesting={getMenusRequest.isPending}
              />
          }
        </section>
      </div>
      <DeleteMenuModal/>
    </>
  );
};

export default MenusList;