import {generatePath, useNavigate} from "react-router-dom";
import {getCellValue} from "../../../../../utils/utils";
import Icon from "../../../general/icon";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../../general/tooltip";
import {ROUTES} from "../../../../../constants";

type MedicationCategoryTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const MedicationCategoryTableRowItem = ({
  item,
  handleShowModal
}: MedicationCategoryTableRowItemProps) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>{getCellValue(item?.name)}</td>
      <td>{getCellValue(item?.description)}</td>
      <td>{getCellValue(item?.parent?.name)}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
            <span
              onClick={() => navigate(generatePath(ROUTES.MEDICATION_CATEGORY_EDIT.PATH, { id: item?.id }))}
              className="d-flex text-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='pen-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deleteMedicationCategoryModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicationCategoryTableRowItem);
