import Highcharts, {SeriesOptionsType} from "highcharts";
import AnnotationsModule from 'highcharts/modules/annotations';
import HighchartsMore from 'highcharts/highcharts-more';
import {langDefaultOptions} from "./chartOptions";

HighchartsMore(Highcharts);
AnnotationsModule(Highcharts);

type EcgChartProps = {
  data: Highcharts.XrangePointOptionsObject[][],
  dataNames: string[],
  labels: string[],
  chartTitle: string,
  displayXLabels?: boolean,
  displayYLabels?: boolean,
  displayDataLabels?: boolean,
  showLegend?: boolean,
  showToolTip?: boolean,
  showExportButton?: boolean,
  maxPointToShow?: number,
  height?: string,
  plotBands?: any;
  dataRanges?: any,
  yValue?: number,
  activeSegment?: string;
  handleOpenReportModal?: any;
}

Highcharts.SVGRenderer.prototype.symbols.arrow = function (x: number, y: number, w: number, h: number) {
  return [
    'M', x, y + h / 2,     // Start at the middle of the left side
    'L', x + w, y,         // Draw to the top right
    'L', x + w / 2, y + h / 2,  // Draw to the middle right (arrow tip)
    'L', x + w, y + h,     // Draw to the bottom right
    'Z'                    // Close the path to form an arrowhead
  ];
};

Highcharts.SVGRenderer.prototype.symbols.arrowRotated = function (x: number, y: number, w: number, h: number) {
  return [
    'M', x + w, y + h / 2,      // Start at the middle of the right side
    'L', x, y + h,              // Draw to the bottom left
    'L', x + w / 2, y + h / 2,  // Draw to the middle left (arrow tip)
    'L', x, y,                  // Draw to the top left
    'Z'                         // Close the path to form an arrowhead
  ];
};


// Create highlighted series based on the x ranges
const highlightedSeries = (dataRanges: any, yValue: number, activeSegment?: string) => dataRanges && dataRanges?.map(([startX, endX]: number[], rangeIndex: number) => {
  return {
    name: `${(endX - startX) * 2} ms`,
    type: 'line',
    data: [
      {
        x: startX,
        y: activeSegment !== 'RR' ? yValue : rangeIndex % 2 === 0 ? yValue : yValue - 150,
        marker: {
          symbol: 'arrow',
        },
      }, {
        x: endX,
        y: activeSegment !== 'RR' ? yValue : rangeIndex % 2 === 0 ? yValue : yValue - 150,
        marker: {
          symbol: 'arrowRotated'
        },
        dataLabels: { // Add dataLabels for the start point
          enabled: true,
          format: `${(endX - startX) * 2} ms`,
          style: {
            color: '#155e75',
            fontWeight: 'bold',
          },
        },
      },
    ],
    color: '#155e75', // Customize the color for the highlighted range
    lineWidth: 2 // Optional: Make the line width thicker for emphasis
  } as SeriesOptionsType;
});

function handlePlotBandClick(index: number) {
  console.log('Plot band label clicked!', index);
  // You can also redirect, open a modal, or perform any other action here
}

let ecgFirstRender = false

export const EcgOptions = ({
  data,
  dataNames,
  chartTitle,
  showExportButton = true,
  maxPointToShow = 30000,
  height = '250px',
  plotBands,
  dataRanges,
  yValue,
  activeSegment,
  handleOpenReportModal
}: EcgChartProps) : Highcharts.Options => {

  // Define the annotations to add a label centered above the line
  // const annotations: Highcharts.AnnotationsOptions[] = dataRanges && dataRanges?.map(([startX, endX]: number[]) => {
  //   const midX = (startX + endX) / 2;
  //   return {
  //     labels: [{
  //       point: {
  //         xAxis: 0,
  //         yAxis: 0,
  //         x: midX,
  //         y: yValue
  //       },
  //       text: `${(endX - startX) * 2} ms`,
  //       style: {
  //         color: '#2E57FF',
  //         fontWeight: 'light',
  //       },
  //       backgroundColor: 'rgba(255, 255, 255, 0)',
  //       borderColor: 'rgba(255, 255, 255, 0)',
  //       align: 'center' as Highcharts.AlignValue,
  //       verticalAlign: 'top' as Highcharts.VerticalAlignValue, // Align label to the top of the point
  //       y: 5 // Offset the label below the line
  //     }],
  //     shapes: [{
  //       type: 'rect', // Example of rectangle shape
  //       points: [{
  //         x: 1,
  //         y: 1,
  //         xAxis: 0,
  //         yAxis: 0
  //       }, {
  //         x: 5,
  //         y: 5,
  //         xAxis: 0,
  //         yAxis: 0
  //       }],
  //       fill: 'rgba(255, 0, 0, 0.5)' // Fill color
  //     }],
  //     draggable: '' as Highcharts.AnnotationDraggableValue // Disable dragging
  //   };
  // });

  let isZoomed = false; // Flag to track zoom state

  Highcharts.setOptions({
    lang: {
      resetZoom: `<div class="font-vazir fw-medium font-12">حالت پیش‌فرض</div>`,
      resetZoomTitle: `<div class="font-vazir font-12">تنظیم مجدد بزرگنمایی</div>`
    }
  });

  return {
    title: {
      text: '' // Set an empty string to hide the title
    },
    chart: {
      height: height,
      backgroundColor: '#FFFFFF',
      borderRadius: 12,
      shadow: true,
      type: 'line',
      zooming: {
        type: 'x'
      },
      panning: {
        enabled: true,
        type: 'x'
      },
      panKey: 'shift',
      events: {
        render: function() {
          if(dataRanges) {
            for (let i = 0; i < dataRanges?.length; i++) {
              const elementId = 'plotBandLabel_' + i;
              const element = document.getElementById(elementId);

              if (element) {
                element.addEventListener('click', function () {
                  handleOpenReportModal(i);
                });
              }
            }
          }
        },
        selection: function (event) : boolean | undefined {
          isZoomed = true;
          if (event.xAxis && this.xAxis[0]) {
            const xAxis = event.xAxis[0]; // Get the xAxis after zoom
            const fullRange = this.xAxis[0].max !== undefined && this.xAxis[0].min !== undefined
              ? this.xAxis[0].max - this.xAxis[0].min
              : 0; // Check if max and min are defined before using them
            const zoomRange = xAxis.max - xAxis.min; // Zoomed range
            const zoomLevel = zoomRange / fullRange; // Calculate zoom level as a fraction

            // Example: Perform action based on zoom level
            if (zoomLevel < 0.5) { // Less than 50% zoom
              // Update plotBands to show labels
              this.update({
                xAxis: {
                  plotBands: plotBands && plotBands.map((plotDetail: any, index: number) => ({
                    from: plotDetail?.from,
                    to: plotDetail?.to,
                    color: plotDetail?.color,
                    label: {
                      text: '<div id="plotBandLabel_' + index + '" class="d-flex align-items-center font-en cur-pointer text-black fw-bold">' +
                        '<span class="d-flex align-items-center font-18 text-orange me-1"><i class="icon-info-circle"></i></span>' +
                        '<span class="font-18">' + plotDetail?.label + '</span>' +
                        '</div>',
                      align: 'left',
                      useHTML: true,
                      verticalAlign: 'top',
                      style: {
                        color: '#000000',
                        fontWeight: 'bold',
                      },
                      y: 20,
                      x: 5,
                    },
                  })),
                }
              });
              return true;
            }
          }
        },

      },
    },
    loading: {
      showDuration: 0,
      hideDuration: 500,
      style: {
        opacity: 0.9,
      },
      labelStyle: {
        color: '#000',
        opacity: 1,
        padding: '10px',
        fontSize: '16px',       // Make the text larger for better visibility
        fontWeight: 'bold',     // Make the text bolder
      }
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        turboThreshold: 75000,
        dataLabels: {
          enabled: false,
        }
      },
    },
    series: [...data.map((seriesData, index) => ({
      type: 'line',
      name: dataNames[index],
      data: seriesData,
      marker: {
        enabled: false
      }
    } as SeriesOptionsType)),
      ...(dataRanges ? highlightedSeries(dataRanges, yValue || -1200, activeSegment) : [])
    ],
    // annotations: annotations, // Add annotations here
    xAxis: {
      type: 'datetime', // Use datetime for the x-axis
      tickInterval: 200, // هر 0.2 ثانیه
      tickWidth: 0,
      gridLineWidth: 1,
      minorTicks: true,
      minorTickInterval: 40, // هر 0.04 ثانیه. در نتیجه، ۵ خط داخل یک مربع بزرگن
      gridLineColor: '#F991BC',
      lineColor: '#F991BC',
      minorGridLineColor: '#D1D5DB',
      minRange: 200,
      max: maxPointToShow,
      labels: {
        enabled: false
      },
      plotBands: plotBands && plotBands?.map((plotDetail: any, index: any) => ({
        from: plotDetail?.from,
        to: plotDetail?.to,
        color: plotDetail?.color,
        label: {
          text: '<div id="plotBandLabel_' + index + '" class="d-flex align-items-center font-en cur-pointer text-black fw-bold">' +
            '<span class="font-18">'+ plotDetail?.label +'</span>' +
            '</div>',
          align: 'left',
          useHTML: true,
          verticalAlign: 'top',
          style: {
            color: '#000000',
            fontWeight: 'bold'
          },
          y: 20,
          x: 5,
        }
      })),
    },
    colors: ['#000000'],
    yAxis: {
      // visible: displayYLabels,
      enabled: false,
      minorTicks: true,
      minorTickInterval: 120, // محاسبات مرتضی بر اساس تبدیل 3L به صدرا
      tickInterval: 600, // محاسبات مرتضی بر اساس تبدیل 3L به صدرا
      gridLineColor: '#F991BC',
      minorGridLineColor: '#D1D5DB',
      softMin: -2000,
      softMax: 2000,
      title: {
        text: ''
      },
      labels: {
        enabled: false,
      },
      // events: {
      //   afterSetExtremes: function (e: any) {
      //     var chart = this.chart;
      //     var newYValue = e.min + (e.max - e.min) * 0.1;  // Adjust the percentage as needed
      //
      //     // Update the highlighted series using the newYValue
      //     const updatedSeries = [
      //       ...data.map((seriesData, index) => ({
      //         type: 'line',
      //         name: dataNames[index],
      //         data: seriesData
      //       })),
      //       ...(dataRanges ? highlightedSeries(dataRanges, newYValue, activeSegment) : [])
      //     ];
      //
      //     // Set the new series data
      //     chart.update({
      //       series: updatedSeries
      //     }, true, false, true);
      //   }
      // }
    },
    tooltip: {
      enabled: false,
    },
    lang: langDefaultOptions,
    legend: {
      enabled: false,
      className: 'font-vazir'
    },
    noData: {
      position: {
        // Adjust the position of the noData text
        align: 'center',
        verticalAlign: 'top'
      },
      useHTML: true,  // Enable use of HTML for rendering the image
      style: {
        fontSize: '16px',
      },
      attr: {
        zIndex: 1
      },
    },
    exporting: {
      filename: chartTitle + ' - ' + new Date(),
      chartOptions: {
        title: {
          text: chartTitle
        },
        exporting: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: 'Vazir, sans-serif'
          }
        }
      },
      buttons: {
        contextButton: {
          enabled: false
        },
        exportButton: {
          enabled: showExportButton,
          className: 'rounded-circle font-vazir font-en',
          menuClassName:'rtl text-end font-vazir font-en',
          symbol: "menu",
          symbolSize: 12,
          // Use only the download related menu items from the default
          // context button
          menuItems: [
            "viewFullscreen",
            'printChart',
            "separator",
            'downloadPDF',
            'downloadPNG',
            'downloadJPEG',
            'downloadSVG',
          ]
        },
      }
    },
    credits: {
      enabled: false
    }
  }
};