import {OpenVidu} from 'openvidu-browser';
import axios from 'axios';
import React, {Component} from 'react';
import UserVideoComponent from '../../components/visits/visitDetail/videoCall/UserVideoComponent';
import Icon from "../../components/general/icon";
import UserModel from "../../../types/videoCallUserModel";
import Lobby from "../../components/visits/visitDetail/videoCall/lobby";
import HDLogo from "../../../assets/images/svgComponents/HDLogo";
import {API_PATH} from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import Noti from "../../components/general/noti";
import {toast} from "react-hot-toast";

var localUser = new UserModel();

class VideoCall extends Component {

  constructor(props) {
    super(props);
    this.pipRef = React.createRef();
    this.hasBeenUpdated = false;
    let sessionName = this.props.sessionName ? this.props.sessionName : 'SessionA';
    let userName = this.props.user ? this.props.user : 'OpenVidu_User' + Math.floor(Math.random() * 100);
    this.remotes = [];
    this.localUserAccessAllowed = false;
    this.state = {
      mySessionId: sessionName,
      meeting: undefined,
      participant: undefined,
      myUserName: userName,
      session: undefined,
      mainStreamManager: undefined,
      localUser: undefined,
      subscribers: [],
      showChatDrawer: false,
      showLobbyDrawer: true,
      currentVideoDevice: undefined,
      width: window.innerWidth
    };

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.checkNotification = this.checkNotification.bind(this);
    this.handleCloseLobbyDrawer = this.handleCloseLobbyDrawer.bind(this);

    // this.sendMessage = this.sendMessage.bind(this);
    // this.addMessageToChat = this.addMessageToChat.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSend = this.handleSend.bind(this);
  }

  updateWindowSize = () => {
    this.setState({width: window.innerWidth});
  };


  handleChange(event) {
    this.setState({message: event.target.value});
  }

  handleSend() {
    if (this.state.message.trim() !== '') {
      this.sendMessage(this.state.message);
      this.setState({message: ''});
    }
  }

  addMessageToChat(message) {
    this.setState((prevState) => ({
      messages: [...prevState.messages, message]
    }));
  }

  sendMessage(message) {
    const mySession = this.state.session;
    mySession.signal({
      data: message,
      to: [],  // Sends to all participants in the session
      type: 'text',  // The signal type
    })
      .then(() => {
        this.addMessageToChat({user: 'Me', text: message});
      })
      .catch((error) => {
      });
  }

  componentDidMount() {
    this.state.showChatDrawer ?
      document.body.classList.remove('chat-collapsed') :
      document.body.classList.add('chat-collapsed');
    this.state.showLobbyDrawer ?
      document.body.classList.remove('lobby-collapsed') :
      document.body.classList.add('lobby-collapsed');
    window.addEventListener('beforeunload', this.onbeforeunload);
    window.addEventListener('resize', this.updateWindowSize);
    if (this.props?.currentCallId) {
      this.joinSession(this.props?.currentCallId)
    }
  }


  startCheckingForPiP = () => {
    this.checkInterval = setInterval(() => {
      this.state.subscribers[0] && this.checkAndHandlePiP();
    }, 1000); // Check every 1 second
  };

  stopCheckingForPiP = () => {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }
  };

  checkAndHandlePiP = () => {
    if (this.pipRef.current) {
      this.pipRef.current.handlePiP(); // Perform action, e.g., click
      this.stopCheckingForPiP(); // Stop checking once ref is available
    } else {
      console.error('Ref is not assigned');
    }
  };

  componentDidUpdate(prevProps) {
    // Check if currentCallId has changed
    if (prevProps.currentCallId !== this.props.currentCallId) {
      this.joinSession(this.props.currentCallId);
      this.startCheckingForPiP();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onbeforeunload);
    window.removeEventListener('resize', this.updateWindowSize);
    this.stopCheckingForPiP();
    this.leaveSession();
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
      mySessionId: e.target.value,
    });
  }

  handleChangeUserName(e) {
    this.setState({
      myUserName: e.target.value,
    });
  }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream
      });
    }
  }

  deleteSubscriber(stream) {
    const remoteUsers = this.state.subscribers;
    const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
    let index = remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      remoteUsers.splice(index, 1);
      this.setState({
        subscribers: remoteUsers,
      });
    }
  }

  joinSession(visitId) {
    this.OV = new OpenVidu();
    if(this.OV) {
      this.setState(
        {
          session: this.OV?.initSession(),
        },
        async () => {
          this.subscribeToStreamCreated();
          visitId !== 0 && this.connectToSession(visitId)
        },
      );
    }
  }

  async connectToSession(visitId) {
    console.log(visitId);
    this.props.setVideoCallId(visitId)
    if (this.props.token !== undefined) {
      this.connect(this.props.token);
    } else {
      try {
        let response = await this.getToken(visitId);
        this.setState({
          mySessionId: response.result.sessionId,
          participant: response.result.participant,
          meeting: response.result.meeting,
          myUserName: response.result.participant.name,
        }, () => {
          // This callback is executed after the state has been updated
          let token = response.result.connectionToken;
          this.connect(token);
        });
      } catch (error) {
        console.error('There was an error getting the token:', error.code, error.message);
        if (this.props.error) {
          this.props.error({
            error: error.error,
            messgae: error.message,
            code: error.code,
            status: error.status
          });
        }
        alert('There was an error getting the token:', error.message);
      }
    }
  }

  connect(token) {
    this.state.session
      .connect(
        token,
      )
      .then((event) => {
        this.connectWebCam();
      })
      .catch((error) => {
        if (this.props.error) {
          this.props.error({
            error: error.error,
            messgae: error.message,
            code: error.code,
            status: error.status
          });
        }
        Noti('error', 'توکن تماس تصویری دریافت نشد.')
      });
  }

  async connectWebCam() {
    if (this.OV) {
      try {
        await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });
        var devices = await this.OV?.getDevices();
        var videoDevices = devices.filter(device => device.kind === 'videoinput');
        var audioDevices = devices.filter(device => device.kind === 'audioinput');

        // Abort the function if no audio devices are found
        if (audioDevices.length === 0) {
          Noti('error', 'میکروفون یافت نشد. لطفا میکروفون خود را وصل کرده و دوباره تلاش کنید.', 'top-center');
          this.leaveSession();
          return; // Abort the function
        }

        let videoSource = videoDevices.length > 0 ? videoDevices[0].deviceId : false; // Handle no video case

        let publisher = this.OV?.initPublisher(undefined, {
          audioSource: audioDevices[0].deviceId, // Set the available audio input
          videoSource: videoSource, // false if no video, otherwise set the deviceId
          publishAudio: localUser.isAudioActive(),
          publishVideo: videoSource !== false && localUser.isVideoActive(), // Only publish video if there's a valid video source
          resolution: '1280x720',
          frameRate: 30,
          insertMode: 'APPEND',
        });

        if (this.state.session.capabilities.publish) {
          publisher.on('accessAllowed', () => {
            this.state.session.publish(publisher).then(() => {
              this.updateSubscribers();
              this.localUserAccessAllowed = true;
              if (this.props.joinSession) {
                this.props.joinSession();
              }
            });
          });
        }

        localUser.setNickname(this.state.myUserName);
        localUser.setConnectionId(this.state.session.connection.connectionId);
        localUser.setScreenShareActive(false);
        localUser.setStreamManager(publisher);
        localUser.setConnection(this.state.session.connection);

        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();
        this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });

        this.setState({ currentVideoDevice: videoDevices[0] || null, localUser: localUser }, () => {
          if (this.state.localUser.getStreamManager()) {
            this.state.localUser.getStreamManager().on('streamPlaying', (e) => {
              if (publisher.videos.length > 0) {
                publisher.videos[0].video.parentElement.classList.remove('custom-class');
              }
            });
          }
        });
      } catch (error) {
        try {
          // This will trigger the browser's permission prompt (if not previously blocked)
          await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

          // Proceed with OpenVidu's getUserMedia call
          await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

          // Continue with your OpenVidu setup as before
          // ...

        } catch (error) {
          // Handle errors like permission being blocked
          if (error.name === 'NotAllowedError') {
            toast(
              <div>
                <div className="d-flex align-items-center text-white">
                  <span className=''>
                    <p>
                      <i className={`icon-info-circle text-orange font-20 ms-2`}></i>
                      لطفاً دسترسی به
                      <b className='text-orange'> میکروفون و دوربین </b>
                      را فعال کنید تا بتوانید بدون مشکل وارد ویزیت شوید. برای این کار:
                    </p>
                    <p>
                      بر روی
                      <b className='text-orange'> آیکون قفل کنار آدرس سایت </b>
                      در مرورگرتان (مثل Chrome یا Firefox) کلیک کنید و دسترسی به دوربین و میکروفون را روی
                      <b className='text-orange'> Allow </b>
                      بذارید. سپس صفحه را رفرش کنید.
                    </p>
                    <p>
                      اگر از مک استفاده می‌کنید، به System Settings سپس به Privacy & Security برید، بعد روی Camera و Microphone کلیک کنید و مرورگرتان را در لیست پیدا کنید و تیک آن را بزنید. سپس مرورگر را ببندید و دوباره باز کنید.
                      در صورتی که با انجام مراحل فوق همچنان مشکل داشتید، لطفاً اطمینان حاصل کنید که دستگاه شما مجهز به میکروفون و دوربین است. برخی دستگاه‌ها، مانند کامپیوترهای دسکتاپ، ممکن است فاقد این امکانات باشند. در این صورت، می‌توانید از یک دستگاه دیگر استفاده کرده یا میکروفون و دوربین خارجی متصل کنید.
                    </p>
                  </span>
                </div>
              </div>,
              {
                position: 'top-left',
                duration: 5000,
                style: {
                  borderRadius: '10px',
                  background: "#616161",  // Semi-transparent background
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
                  overflow: "hidden",
                },
              }
            );

            // Optionally, provide further instructions on how to unblock permissions
          } else {
            Noti('error', 'میکروفون و دوربین یافت نشد. لطفا میکروفون خود را وصل کرده و دوباره تلاش کنید.', 'top-left');
          }
        }
        this.leaveSession();
      }
    }
  }




  updateSubscribers() {
    var subscribers = this.remotes;
    console.log("updateSubscribers: ", subscribers)
    this.setState(
      {
        subscribers: subscribers,
      },
      () => {
        if (this.state.localUser) {
          this.sendSignalUserChanged({
            isAudioActive: this.state.localUser.isAudioActive(),
            isVideoActive: this.state.localUser.isVideoActive(),
            nickname: this.state.localUser.getNickname(),
            isScreenShareActive: this.state.localUser.isScreenShareActive(),
          });
        }
        // this.updateLayout();
      },
    );
  }

  leaveSession() {
    const mySession = this.state.session;

    if (mySession) {
      mySession.disconnect();
    }

    // Empty all properties...
    if(this.OV) {
      this.OV = null;
    }
    this.remotes = [];
    this.setState({
      session: undefined,
      meeting: undefined,
      subscribers: [],
      mySessionId: 'SessionA',
      myUserName: 'OpenVidu_User' + Math.floor(Math.random() * 100),
      localUser: undefined,
    });
    this.props.setVisitIdToZero()
  }

  async switchCamera() {
    try {
      const devices = await this.OV?.getDevices()
      var videoDevices = devices?.filter(device => device.kind === 'videoinput');

      if (videoDevices && videoDevices.length > 1) {

        var newVideoDevice = videoDevices.filter(device => device.deviceId !== this.state.currentVideoDevice.deviceId)

        if (newVideoDevice.length > 0) {
          // Creating a new publisher with specific videoSource
          // In mobile devices the default and first camera is the front one
          var newPublisher = this.OV?.initPublisher(undefined, {
            videoSource: newVideoDevice[0].deviceId,
            publishAudio: true,
            publishVideo: true,
            mirror: true
          });

          //newPublisher.once("accessAllowed", () => {
          await this.state.session.unpublish(this.state.mainStreamManager)

          await this.state.session.publish(newPublisher)
          this.setState({
            currentVideoDevice: newVideoDevice[0],
            mainStreamManager: newPublisher,
            publisher: newPublisher,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  camStatusChanged() {
    localUser.setVideoActive(!localUser.isVideoActive());
    localUser.getStreamManager().publishVideo(localUser.isVideoActive());
    this.sendSignalUserChanged({isVideoActive: localUser.isVideoActive()});
    this.setState({localUser: localUser});
  }

  micStatusChanged() {
    localUser.setAudioActive(!localUser.isAudioActive());
    localUser.getStreamManager().publishAudio(localUser.isAudioActive());
    this.sendSignalUserChanged({isAudioActive: localUser.isAudioActive()});
    this.setState({localUser: localUser});
  }

  subscribeToStreamCreated() {
    this.state.session.on('streamCreated', (event) => {
      const subscriber = this.state.session.subscribe(event.stream, undefined);
      // var subscribers = this.state.subscribers;
      subscriber.on('streamPlaying', (e) => {
        this.checkSomeoneShareScreen();
        subscriber.videos[0].video.parentElement.classList.remove('custom-class');
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType('remote');
      console.log('event connection: ', event.stream.connection, event.stream.connection.connectionId)
      newUser.setConnection(event.stream.connection)
      // Extracting and parsing clientData
      const connectionData = event.stream.connection.data;

      // const clientData = parsedData.clientData;
      const [json1, json2] = connectionData.split('%/%');
      const parsedJson1 = JSON.parse(json1);
      const parsedJson2 = JSON.parse(json2);
      newUser.setParticipant(parsedJson1?.id ? parsedJson1 : parsedJson2?.id ? parsedJson2 : null);  // Setting participant with clientData
      newUser.setNickname(parsedJson1?.id ? parsedJson1?.name : parsedJson2?.id ? parsedJson2?.name : null);  // Setting participant with clientData
      this.remotes.push(newUser);
      if (this.localUserAccessAllowed) {
        this.updateSubscribers();
      }
    });
  }

  subscribeToStreamDestroyed() {
    // On every Stream destroyed...
    this.state.session.on('streamDestroyed', (event) => {
      // Remove the stream from 'subscribers' array
      this.deleteSubscriber(event.stream);
      setTimeout(() => {
        this.checkSomeoneShareScreen();
      }, 20);
      event.preventDefault();
      // this.updateLayout();
    });
  }

  subscribeToUserChanged() {
    this.state.session.on('signal:userChanged', (event) => {
      console.log('EVENTO REMOTE: ', event);
      let remoteUsers = this.state.subscribers;
      remoteUsers.forEach((user) => {
        if (user.getConnectionId() === event.from.connectionId) {
          const data = JSON.parse(event.data);
          if (data.isAudioActive !== undefined) {
            user.setAudioActive(data.isAudioActive);
          }
          if (data.isVideoActive !== undefined) {
            user.setVideoActive(data.isVideoActive);
          }
          if (data.nickname) {
            user.setNickname(data.nickname);
          }
          if (data.isScreenShareActive !== undefined) {
            user.setScreenShareActive(data.isScreenShareActive);
          }
        }
      });
      this.setState(
        {
          subscribers: remoteUsers,
        },
        () => this.checkSomeoneShareScreen(),
      );
    });
  }

  sendSignalUserChanged(data) {
    const signalOptions = {
      data: JSON.stringify(data),
      type: 'userChanged',
    };
    this.state.session.signal(signalOptions);
  }

  toggleFullscreen(divContainerId) {
    const document = window.document;
    const fs = document.getElementById(divContainerId);
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (fs.requestFullscreen) {
        fs.requestFullscreen();
      } else if (fs.msRequestFullscreen) {
        fs.msRequestFullscreen();
      } else if (fs.mozRequestFullScreen) {
        fs.mozRequestFullScreen();
      } else if (fs.webkitRequestFullscreen) {
        fs.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  screenShare() {
    const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
    const publisher = this.OV?.initPublisher(
      undefined,
      {
        videoSource: 'screen',
        publishAudio: localUser?.isAudioActive(),
        publishVideo: localUser?.isVideoActive(),
        mirror: false,
      },
      (error) => {
        if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
          this.setState({showExtensionDialog: true});
        } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
          alert('Your browser does not support screen sharing');
        } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
          alert('You need to enable screen sharing extension');
        } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
          alert('You need to choose a window or application to share');
        }
      },
    );

    publisher.once('accessAllowed', () => {
      this.state.session.unpublish(localUser.getStreamManager());
      localUser.setStreamManager(publisher);
      this.state.session.publish(localUser.getStreamManager()).then(() => {
        localUser.setScreenShareActive(true);
        this.setState({localUser: localUser}, () => {
          this.sendSignalUserChanged({isScreenShareActive: localUser.isScreenShareActive()});
        });
      });
    });
    publisher.on('streamPlaying', () => {
      // this.updateLayout();
      publisher.videos[0].video.parentElement.classList.remove('custom-class');
    });
  }

  stopScreenShare() {
    this.state.session.unpublish(localUser.getStreamManager());
    this.connectWebCam();
  }

  checkSomeoneShareScreen() {
    let isScreenShared;
    // return true if at least one passes the test
    isScreenShared = this.state.subscribers.some((user) => user.isScreenShareActive()) || localUser.isScreenShareActive();
    // const openviduLayoutOptions = {
    //   maxRatio: 3 / 2,
    //   minRatio: 9 / 16,
    //   fixedRatio: isScreenShared,
    //   bigClass: 'OV_big',
    //   bigPercentage: 0.8,
    //   bigFixedRatio: false,
    //   bigMaxRatio: 3 / 2,
    //   bigMinRatio: 9 / 16,
    //   bigFirst: true,
    //   animate: true,
    // };
    // this.layout.setLayoutOptions(openviduLayoutOptions);
    // this.updateLayout();
  }

  handleCloseLobbyDrawer = () => {
    this.setState(
      {showLobbyDrawer: false},
      () => {
        if (this.state.showLobbyDrawer) {
          document.body.classList.remove('lobby-collapsed');
        } else {
          document.body.classList.add('lobby-collapsed');
        }
      },
    );
  };

  handleShowChatDrawer = () => {
    this.setState(
      {showChatDrawer: !this.state.showChatDrawer, showLobbyDrawer: false},
      () => {
        document.body.classList.add('lobby-collapsed');
        if (this.state.showChatDrawer) {
          document.body.classList.remove('chat-collapsed');
        } else {
          document.body.classList.add('chat-collapsed');
        }
      },
    );
  };

  handleShowLobbyDrawer = () => {
    this.setState(
      {showLobbyDrawer: !this.state.showLobbyDrawer, showChatDrawer: false},
      () => {
        document.body.classList.add('chat-collapsed');
        if (this.state.showLobbyDrawer) {
          document.body.classList.remove('lobby-collapsed');
        } else {
          document.body.classList.add('lobby-collapsed');
        }
      },
    );
  };

  checkNotification(event) {
    this.setState({
      messageReceived: this.state.chatDisplay === 'none',
    });
  }

  render() {
    const {width} = this.state;
    // const mySessionId = this.state.mySessionId;
    const myUserName = this.state.myUserName;
    const mySessionId = this.state.mySessionId;
    const meeting = this.state.meeting;
    const participant = this.state.participant;
    const subscriberCount = this.state.subscribers?.length || 0;
    const mainStreamManager = this.state.mainStreamManager || this.state.localUser
    const localUser = this.state.localUser;
    var chatDisplay = {display: this.state.chatDisplay};

    const getGridClasses = (index) => {
      const subscriberCount = this.state.subscribers.length + 1;
      if (subscriberCount === 1) return "col-12";
      if (subscriberCount === 2) return "col-12 col-md-6 col-sm-12";
      if (subscriberCount === 3) return "col-6 justify-content-center";
      if (subscriberCount === 4) return "col-6";
      if (subscriberCount === 5) return index < 3 ? "col-4" : "col-6";
      if (subscriberCount === 6) return "col-4";
      return "col-12"; // Default to full width for unsupported cases
    };

    return (
      <div className="width-full h-100-important bg-dark" id='video_call_container' style={{zIndex: '-5000px'}}>
        {(this.state.localUser && (this.state.localUser?.getStreamManager === null || this.state.localUser?.getStreamManager === undefined)) &&
          <BoxLoader type={'cover'}/>
        }
        {/* Header Section*/}
        <div className='w-95-per margin-x-auto padding-y-1 h-8-per'>
          <div id="session-title" className='card-box p-2 w-100 d-flex bg-light-dark text-white'>
            <div className="d-flex align-items-center">
              <span className='p-1 btn bg-light-dark rounded rounded-5'
                    onClick={this.props.handleSetShowModal}>
                <span className='mx-2 font-18 d-flex align-items-center h-100'>
                  <Icon name='exit-right'/>
                </span>
              </span>
              <span id='pip-diagonal-icon' className='p-1 btn bg-light-dark rounded rounded-5'
                    onClick={() => this.pipRef?.current?.handlePiP()}
              >
                <span className='mx-2 font-18 d-flex align-items-center h-100'>
                  <Icon name='arrow-diagonal'/>
                </span>
              </span>
            </div>
            <div className="d-flex align-items-center me-auto ms-1">
                  <span className='font-18 font-en mx-2'>
                    {meeting ? meeting.title : ''}
                  </span>
              <HDLogo heightAndWidth={30}/>
            </div>
          </div>
        </div>
        {/* Content Section */}
        <div className="d-flex h-84-per position-relative w-95-per margin-x-auto padding-b-2">
          <Lobby
            chatDisplay={this.state.chatDisplay}
            session={this.state.session}
            meeting={this.state.meeting}
            handleShowDrawer={this.handleShowLobbyDrawer}
            connectPatientToMeeting={this.props.setVideoCallId}
            leaveSession={this.leaveSession}
            handleCloseLobbyDrawer={this.handleCloseLobbyDrawer}
            subscribers={this.state.subscribers}
          />
          {this.state.session !== undefined ? (
            <div className={`video-xs h-100-important m-auto ${this.props.showLobbyDrawer ? "w-75-per" : ""}`}>
              <div className="d-flex flex-wrap width-full h-100-important">
                <div className="d-flex flex-wrap width-full h-100-important ">
                  <div
                    className="width-full m-auto video-flex-wrap d-flex align-items-center gap-2 flex-wrap h-100-important">
                    {mainStreamManager !== undefined ? (
                      <div key={0}
                           className={`w-95-per m-auto position-relative ${
                             this.state.subscribers.length === 1 && width <= 900
                               ? "flex-grow-custom-100 h-50-per"
                               : this.state.subscribers.length === 1
                                 ? "flex-grow-custom-50 h-100-important"
                                 : this.state.subscribers.length === 2 && width <= 900
                                   ? "flex-grow-custom-100 h-50-per"
                                   : this.state.subscribers.length >= 2
                                     ? "flex-grow-custom-50 h-50-per"
                                     : "h-100-important"
                           }`}
                      >
                        <UserVideoComponent participant={participant} user={mainStreamManager}
                                            streamManager={mainStreamManager}
                                            toggleFullscreen={this.toggleFullscreen}
                                            updateFloatingDiv={this.props.updateFloatingDiv}
                                            subscriberCount={subscriberCount}
                                            micStatusChange={this.micStatusChanged}
                                            micStatus={localUser.audioActive}
                                            camStatusChange={this.camStatusChanged}
                                            camStatus={localUser.videoActive}
                                            leaveSession={this.leaveSession} index={0}
                        />
                      </div>
                    ) : null}
                    {this.state.subscribers.map((sub, index) => (
                      <div key={index + 1}
                           className={`"w-95-per h-50-per m-auto position-relative 
                                                    ${this.state.subscribers.length === 1 && width <= 900
                             ? "flex-grow-custom-100"
                             : this.state.subscribers.length === 1 ? "flex-grow-custom-50 h-100-important" : "flex-grow-custom-50"}`}>
                        <UserVideoComponent participant={sub?.participant} user={sub}
                                            streamManager={sub} toggleFullscreen={this.toggleFullscreen}
                                            updateFloatingDiv={this.props.updateFloatingDiv}
                                            subscriberCount={subscriberCount}
                                            micStatusChange={this.micStatusChanged}
                                            micStatus={sub.audioActive}
                                            camStatusChange={this.camStatusChanged}
                                            camStatus={sub.videoActive}
                                            leaveSession={this.leaveSession} index={index + 1}
                                            videoComponentRef={index === 0 ? this.pipRef : null}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* Footer Section */}
        <div id="session-footer" className='h-8-per w-95-per margin-x-auto'>
          <div id="session-title" className='card-box p-2 w-100 d-flex bg-light-dark text-white'>
            <div className="d-flex align-items-center">
              {/*<span className='p-1 btn bg-light-dark rounded rounded-5' onClick={this.handleShowChatDrawer}>*/}
              {/*  <span className='mx-2 font-18 text-primary d-flex align-items-center h-100'>*/}
              {/*    <Icon name='messages'/>*/}
              {/*  </span>*/}
              {/*</span>*/}
              <span className='p-1 btn bg-light-dark rounded rounded-5'
                    onClick={this.handleShowLobbyDrawer}>
                    <span className='mx-2 font-18 text-primary d-flex align-items-center h-100'>
                      <Icon name='profile-2user'/>
                    </span>
                  </span>
            </div>
            {this.state.localUser?.getStreamManager &&
              <div className="d-flex align-items-center me-auto ms-2">
                <span className='btn bg-video-call-button-red rounded rounded-5 mx-1 py-2 px-2'
                      onClick={this.leaveSession}
                >
                    <span className='mx-2 font-20 d-flex align-items-center h-100'>
                      <Icon name='phone'/>
                    </span>
                  </span>
                <div
                  className={`btn btn-video-call rounded-5 p-2 mx-1 ${localUser?.videoActive ? 'bg-video-call-button-gray' : 'bg-video-call-button-red'}`}
                  onClick={this.camStatusChanged}>
                    <span className='font-18 d-flex align-items-center'>
                      <Icon name={`${localUser?.videoActive ? 'video' : 'video-close'}`}/>
                    </span>
                </div>
                <div
                  className={`btn btn-video-call rounded-5 p-2 mx-1 ${localUser?.audioActive ? 'bg-video-call-button-gray' : 'bg-video-call-button-red'}`}
                  onClick={this.micStatusChanged}>
                    <span className={`d-flex align-items-center font-18`}>
                      <Icon name={`${localUser?.audioActive ? 'microphone' : 'microphone-mute'}`}/>
                    </span>
                </div>
                <div
                  className={`btn btn-video-call rounded-5 p-2 mx-1 ${localUser?.videoActive ? 'bg-video-call-button-gray' : 'bg-video-call-button-red'}`}
                  onClick={this.screenShare}
                >
                  <span className='font-18 d-flex align-items-center'>
                    <Icon name='screenshare'/>
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }


  /**
   * --------------------------------------------
   * GETTING A TOKEN FROM YOUR APPLICATION SERVER
   * --------------------------------------------
   * The methods below request the creation of a Session and a Token to
   * your application server. This keeps your OpenVidu deployment secure.
   *
   * In this sample code, there is no user control at all. Anybody could
   * access your application server endpoints! In a real production
   * environment, your application server must identify the user to allow
   * access to the endpoints.
   *
   * Visit https://docs.openvidu.io/en/stable/application-server to learn
   * more about the integration of OpenVidu in your application server.
   */
  // async getToken() {
  //   return await this.createToken();
  // }

  async getToken(visitId) {
    let connectRequest = {
      id: visitId || this.props.visitData?.id
    };
    const response = await axios.post(API_PATH.VIDEO_CALL.MEETING, connectRequest, {
      headers: {'Content-Type': 'application/json',},
    });
    return response.data; // The token
  }
}

export default VideoCall;
