import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJDateFormat} from "../../../../../../utils/date";

type PatientAttachmentCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const PatientAttachmentCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}: PatientAttachmentCardsBoxItemProps) => {

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
      <div className='rounded border border-2 d-flex mx-2'>
        <div className='thumbnail rounded my-2 mx-2'
             style={{backgroundImage: `url(${getCellValue(item?.attachment?.url)})`}}>
        </div>
        <div className='me-auto my-3 mx-2'>
          <div className="mb-2 d-flex">
            <span className="me-auto">
              {getCellValue(item?.name)}
              <span className='title-dot'></span>
            </span>
          </div>
          <div className="mb-3 d-flex">
            <span className='me-auto'>
              {getCellValue(MtoJDateFormat(item?.updateDate))}
              <span className='title-dot'></span>
            </span>
          </div>
          <div className="d-flex ms-4">
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0 px-2">
                <span onClick={() => handleShowModal('showPatientAttachmentModal', {id: item?.id, patientData: item?.patient})}
                  className="btn btn-light-blue rounded rounded-3 font-10 d-flex align-items-center fw-bold px-3">
                  مشاهده
                </span>
            </div>
            <a href={item?.attachment?.url} className='p-1 btn btn-light-blue rounded rounded-3'>
              <span className='mx-1 font-20 d-flex  align-items-center h-100'>
                <Icon name='file-download'/>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAttachmentCardsBoxItem);