import TableLoader from "../../../../general/table/tableLoader";
import EmptyRow from "../../../../general/table/emptyRow";
import React from "react";
import PatientVaccineTableRowItem from "./patientVaccineTable_rowItem";

type PatientVaccinesTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientVaccinesTable = ({
  dataList,
  dataListTitles,
  isRequesting
}: PatientVaccinesTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
      return <PatientVaccineTableRowItem item={item} key={i}/>
    });

  return (
    <table>
      <thead>
      <tr>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody>
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default PatientVaccinesTable;
