import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import PatientConditionCardsBox from "../../../../components/patients/ehr/condition/dataList/patientConditionCardsBox";
import PatientConditionsTable from "../../../../components/patients/ehr/condition/dataList/patientConditionsTable";
import {useGetPatientConditions} from "../../../../../hook/request/ehr/patientCondition";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import AddPatientConditionModal from "../../../../components/patients/ehr/condition/addPatientConditionModal";
import DeletePatientConditionModal from "../../../../components/patients/ehr/condition/deletePatientConditionModal";


const dataListTitles = [
  "نام شرایط پزشکی",
  "تاریخ شروع",
  "تاریخ پایان",
  "وضعیت",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type conditionsListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const ConditionsList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: conditionsListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [conditionsData, setConditionsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getConditionsRequest = useGetPatientConditions(getQueryParams(filterValues), openTab === 'conditions');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getConditionsRequest?.data?.data?.result &&
    setConditionsData(getConditionsRequest?.data?.data.result)
  }, [getConditionsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی شرایط پزشکی"
            />
          </div>
          {visitEHRCheck &&
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span onClick={() => handleShowModal('addPatientConditionModal', {patientId: id, patientData: patientData})}
                    className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن شرایط پزشکی
              </span>
          </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientConditionCardsBox
                dataList={conditionsData}
                dataListTitles={dataListTitles}
                isRequesting={getConditionsRequest.isPending}
              /> :
              <PatientConditionsTable
                dataList={conditionsData}
                dataListTitles={dataListTitles}
                isRequesting={getConditionsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...conditionsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      {openTab === 'conditions' && <AddPatientConditionModal/>}
      <DeletePatientConditionModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ConditionsList);
