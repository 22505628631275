import {useFieldArray, useForm} from "react-hook-form";
import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import Icon from "../../../../components/general/icon";
import {general} from "../../../../../store/action";
import {TVisitFields} from "../../../../../types/visit";
import AddPrescriptionParaclinicalItemModal
  from "../../../../components/visits/visitDetail/prescription/paraclinical/addPrescriptionParaclinicalItemModal";
import ParaclinicalPrescriptionRowItem from "../../../../components/visits/visitDetail/prescription/paraclinical/paraclinicalPrescriptionRowItem";
import FavoriteParaclinicalItemModal
  from "../../../../components/visits/visitDetail/prescription/paraclinical/favoriteParaclinicalItem/favoriteParaclinicalItemModal";
import FavoriteParaclinicalPrescriptionModal
  from "../../../../components/visits/visitDetail/prescription/paraclinical/favoriteParaclinicalPrescription/favoriteParaclinicalPrescriptionModal";
import ParaclinicalPrescriptionHistoryModal
  from "../../../../components/visits/visitDetail/prescription/paraclinical/history/recentParaclinicalPrescriptionModal";
import Tooltip from "../../../../components/general/tooltip";
import {
  useAddDraftPrescriptionParaclinicalItem
} from "../../../../../hook/request/visit/draftPrescriptionParaclinicalItem";
import {usePrescriptionItemIhioCheck} from "../../../../../hook/request/visit/prescription";
import {mFormat} from "../../../../../utils/date";
import {
  IhioData,
  TIhioDataAndPrescriptionParaclinicalItemFields
} from "../../../../../types/prescription";
import {ISSUER_TYPE, VISIT_MODE} from "../../../../../constants/constant/enum";
import {useLocation, useParams} from "react-router-dom";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import MedicationPrescriptionEmptyRow
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionEmptyRow";
import {Accordion} from "react-bootstrap";
import MedicationPrescriptionRowItemResponsive
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionRowItemResponsive";
import MedicationPrescriptionRowItem
  from "../../../../components/visits/visitDetail/prescription/medication/medicationPrescriptionRowItem";
import ParaclinicalPrescriptionRowItemResponsive
  from "../../../../components/visits/visitDetail/prescription/paraclinical/paraclinicalPrescriptionRowItemResponsive";

/*
    کامپوننت مربوط به قسمت تجویز پاراکلینیک برای نسخه
    Parent: <Prescription/>
    Children: <ParaclinicalPrescriptionEmptyRow/>, <ParaclinicalPrescriptionRowItem/>
              <AddPrescriptionParaclinicalItemModal/>, <FavoriteParaclinicalItemModal/>,
              <FavoriteParaclinicalPrescriptionModal/>, <ParaclinicalPrescriptionHistoryModal/>
              <FavoritePrescriptionModal/>
    در این کامپوننت لیستی از پاراکلینیک های تجویز شده رندر میشود،
    و در صورتی که بیمه شخص بیمه سلامت باشد، ریکوئست های لازم
    زده میشود و چک کد از بیمه دریافت میشود.
*/

type paraclinicalPrescriptionProps = {
  visitData?: TVisitFields;
  insurance?: number;
  medicationItemsCheckCodes?: any;
  isRequesting?: boolean;
  paraclinicalPrescriptionFieldValues: TIhioDataAndPrescriptionParaclinicalItemFields[]
  submitEndVisitCheck?: boolean;
  handleShowModal: any;
  updateData: any
}

type IhioAndPrescriptionParaclinicalItemFieldArray = {
  root: TIhioDataAndPrescriptionParaclinicalItemFields[]
}

const PrescriptionParaclinicalItem = ({
  visitData,
  insurance,
  medicationItemsCheckCodes,
  paraclinicalPrescriptionFieldValues,
  isRequesting,
  handleShowModal,
  updateData
}: paraclinicalPrescriptionProps) => {

  //<editor-fold desc="States">
  const [width] = useWindowSize();
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const { id } = useParams();
  const [paraclinicalFieldValue, setParaclinicalFieldValue] = useState<IhioAndPrescriptionParaclinicalItemFieldArray>({root: []});
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<IhioAndPrescriptionParaclinicalItemFieldArray>({
    defaultValues: useMemo(() => paraclinicalFieldValue, [paraclinicalFieldValue]),
  });
  const {fields, append, update, remove} = useFieldArray({
    name: 'root',
    control
  })
  //</editor-fold>

  //<editor-fold desc="Services">
  const prescriptionItemIhioCheckRequest = usePrescriptionItemIhioCheck({
    onSuccess: () => {
    }
  });
  const addDraftPrescriptionParaclinicalItemRequest = useAddDraftPrescriptionParaclinicalItem({
    onSuccess: () => {
    }
  })
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    append(paraclinicalPrescriptionFieldValues)
  }, [paraclinicalPrescriptionFieldValues.length]);

  /*
    آپدیت داده ها در کامپوننت پرنت
    @Dependency: paraclinicalFieldValue
  */
  useEffect(() => {
    updateData(fields, 'paraclinical');
  }, [fields]);
  //</editor-fold>

  /*
    ساخت مدل پاراکلینیک برای نسخه
    @param: result
    @returns: TAddPrescriptionParaclinicalItemFields
  */
  const addResultToParaclinicalDataList = async (checkCodes: string[], targetIndex: number, item: any) => {
    let prescriptionParaclinicalItem: TIhioDataAndPrescriptionParaclinicalItemFields = {
      service: item?.service ? item.service : item,
      emergency: item?.emergency || false,
      itemCount: item?.itemCount || '1',
      activeDate: item?.activeDate || Date.now()
    }
    const otherItems = fields.filter((_, index) =>
      index !== targetIndex)
      .map(item => item.checkCode)
      .filter(checkCode => checkCode);

    const body ={
      id: item?.service ? item?.service?.id : item?.id,
      visit: id,
      medication: false,
      itemCount: prescriptionParaclinicalItem.itemCount,
      otherItems: [...otherItems, ...checkCodes, ...medicationItemsCheckCodes]
    }
    let requestResult
    if(insurance === ISSUER_TYPE.IHIO.value) {
      requestResult = await prescriptionItemIhioCheckRequest.mutateAsync(body)
    }
    let ihio: IhioData = {};
    if(requestResult?.status === 200 && requestResult?.data?.result) {
      ihio = requestResult?.data?.result;
    }
    console.log(ihio)
    prescriptionParaclinicalItem = {...prescriptionParaclinicalItem, ...ihio}
    return prescriptionParaclinicalItem;
  }

  /*
    بعد از سابمیت کردن مودال اضافه کردن پاراکلینیک
    @param: fieldValue
    @param: index
  */
  const handleParaclinicalModalSubmit = async (fieldValue: any, index: number) => {
    let targetIndex = index;
    let checkCodes: string[] = [];
    for (const item of fieldValue) {
      try {
        console.log(checkCodes)
        const result = await addResultToParaclinicalDataList(checkCodes, targetIndex, item);
        if (result.checkCode) {
          checkCodes.push(result.checkCode);
        }
        if (targetIndex !== -1) {
          update(targetIndex, result);
          targetIndex = -1
        } else {
          append(result);
        }

        // Move on to the next item
      } catch (error) {
        console.error("Error processing prescription:", error);
      }
    }
  }

  /*
    پاک کردن پاراکلینیک از لیست تجویز.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const deleteParaclinicalItemFromList = (index: any) => {
    if (index >= 0 && index < fields.length) {
      // پاراکلینیک براساس ایندکسش فیلتر میشه
      remove(index)
    }
  }

  /*
    اضافه کردن پاراکلینیک به لیست علاقه مندی ها.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const addParaclinicalItemToFavorites = (index: number) => {
    const body = {
      service: {id: fields[index]?.service?.id},
      emergency: fields[index]?.emergency,
      itemCount: fields[index]?.itemCount,
      note: fields[index]?.note
    }
    addDraftPrescriptionParaclinicalItemRequest.mutate(body)
  }

  /*
    بعد از تغییر مقدار هر اینپوت، استیت اون قسمت تغییر کنه
    و ریکوئست با داده جدید به بیمه سلامت زده بشه.
    @param: name
    @param: value
    @param: index
  */
  const onFieldsChange = (name: string, value: any, index: number, paraItem: any) => {
    // جدا کردن اسم اینپوت از آی دی
    const fieldName = name.split('_')[0];
    let fieldValue = {...paraItem, [fieldName]: value}

    // ریکوئست زدن به بیمه سلامت با دیتا جدید.
    if(fieldName !== 'emergency' && fieldName !== 'note' && insurance === ISSUER_TYPE.IHIO.value) {
      IhioCheck(fieldName, value, index, fieldValue)
    } else {
      update(index, { ...fieldValue})
    }
  }

  /*
    ریکوئست زدن به بیمه سلامت برای دریافت چک کد و ارور ها.
    @param: name
    @param: value
    @param: targetIndex
  */
  const IhioCheck = async (name: string, value: any, targetIndex: number, fieldValue: any) => {
    // ساخت آرایه ای از چک کد ها بر اساس پاراکلینیک های انتخاب شده دیگر در استیت.
    const otherItems = fields.filter((_, index) =>
      index !== targetIndex)
      .map(item => item.checkCode)
      .filter(checkCode => checkCode);
    // در صورتی که نام وارد شده برابر با فیلد بود، مقدار جدید فرستاده شده در بادی قرار میگیرد وگرنه داده ای که از قبل داخل استیت بوده.
    const body ={
      id: fields[targetIndex]?.service?.id,
      visit: id,
      medication: false,
      // emergency: name === 'emergency' ? value : fields[targetIndex]?.emergency,
      activeDate: name === 'activeDate' ? mFormat(value) : mFormat(fields[targetIndex]?.activeDate),
      itemCount: name === 'itemCount' ? value : fields[targetIndex]?.itemCount,
      otherItems: [...otherItems, ...medicationItemsCheckCodes]
    }
    const result = await prescriptionItemIhioCheckRequest.mutateAsync(body)
    let ihio: IhioData = {};
    if(result?.status === 200 && result?.data?.result && result?.data?.result?.checkCode && result?.data?.result?.checkCode != '') {
      ihio = result?.data?.result;
    }
    // جایگزینی فیلد های مرتبط با بیمه سلامت در مدل،‌ با پاسخ دریافت شده از بیمه سلامت.
    update(targetIndex, { ...fieldValue ,...ihio})
  }

  const _renderRowItems = (responsive: boolean) => fields.map((item, index) => {
    return (
      responsive ?
        <ParaclinicalPrescriptionRowItemResponsive
          item={item}
          index={index}
          insurance={insurance}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          addParaclinicalItemToFavorites={addParaclinicalItemToFavorites}
          handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}
          deleteParaclinicalItemFromList={deleteParaclinicalItemFromList}
          register={register}
          watch={watch}
        /> :
        <ParaclinicalPrescriptionRowItem
          item={item}
          index={index}
          insurance={insurance}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          addParaclinicalItemToFavorites={addParaclinicalItemToFavorites}
          handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}
          deleteParaclinicalItemFromList={deleteParaclinicalItemFromList}
          register={register}
          watch={watch}
        />
    )
  })


  return (
    <>
      <h4 className='d-flex border-bottom font-14 mb-4 mt-4 pb-2'>
        <div className="d-flex align-items-center align-content-center ms-auto">
          <span className="d-flex font-22"><Icon name='test'/></span>
          <span className='me-2'>تجویز پاراکلینیک</span>
        </div>
        { mode === VISIT_MODE.EDIT.value && (
          <>
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
              onClick={() => handleShowModal('favoritePrescriptionParaclinicalModal',
                {
                  provider: visitData?.provider?.id,
                  handleParaclinicalModalSubmit: handleParaclinicalModalSubmit
                })}
              className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4 ' : 'justify-content-center p-2'}`}>
                <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="document-add-fill"/></span>
              {width > 900 && 'نسخ پر استفاده'}
              </span>
            </div>
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
              onClick={() => handleShowModal('favoriteParaclinicalItemModal',
                {
                  provider: visitData?.provider?.id,
                  handleParaclinicalModalSubmit: handleParaclinicalModalSubmit
                })}
              className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4' : 'justify-content-center p-2'}`}>
                <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="star-fill"/></span>
              {width > 900 && 'علاقه مندی‌ها'}
              </span>
            </div>
            <Tooltip alt={'آخرین نسخه های ثبت شده برای من'} className="tooltip-pre-line">
              <div
                className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
              onClick={() => {
                handleShowModal('recentParaclinicalPrescriptionModal',
                  {
                    provider: visitData?.provider?.id,
                    patientData: visitData?.patient,
                    handleParaclinicalModalSubmit: handleParaclinicalModalSubmit
                  })
              }}
              className={`btn btn-gray rounded d-flex align-items-center me-2 ${width > 900 ? 'px-4' : 'justify-content-center p-2'}`}>
              <span className={`d-inline-flex font-20 ${width > 900 && 'ms-2'}`}><Icon name="history"/></span>
              {width > 900 && 'سوابق'}
            </span>
              </div>
            </Tooltip>
          </>
        )}
      </h4>
      {/*{*/}
      {/*  fields.length == 0 ?*/}
      {/*    <ParaclinicalPrescriptionEmptyRow*/}
      {/*      control={control}*/}
      {/*      errors={errors}*/}
      {/*      insurance={insurance}*/}
      {/*      handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}*/}
      {/*    />*/}
      {/*    :*/}
      {/*    fields.map((item, index) => {*/}
      {/*      return (*/}
      {/*        <ParaclinicalPrescriptionRowItem*/}
      {/*          item={item}*/}
      {/*          index={index}*/}
      {/*          insurance={insurance}*/}
      {/*          control={control}*/}
      {/*          errors={errors}*/}
      {/*          onFieldsChange={onFieldsChange}*/}
      {/*          addParaclinicalItemToFavorites={addParaclinicalItemToFavorites}*/}
      {/*          handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}*/}
      {/*          deleteParaclinicalItemFromList={deleteParaclinicalItemFromList}*/}
      {/*          register={register}*/}
      {/*          watch={watch}*/}
      {/*        />*/}
      {/*      )*/}
      {/*    })*/}
      {/*}*/}
      {
        fields.length === 0 ?
          <MedicationPrescriptionEmptyRow/>
          :
          width < 900 ?
            <Accordion className='prescription-items-accordion rounded' alwaysOpen>
              {_renderRowItems(true)}
            </Accordion> :
            _renderRowItems(false)

      }
      {
        (mode === VISIT_MODE.EDIT.value) &&
        <div className="d-flex flex-row-reverse">
          <div
            className="flex-grow-1 align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
              onClick={() => handleShowModal('addPrescriptionParaclinicalItemModal',
                {
                  // fieldValue: selectedMedicationItems,
                  handleParaclinicalModalSubmit: handleParaclinicalModalSubmit
                })}
              className="btn btn-primary justify-content-center rounded d-flex align-items-center font-14 fw-bolder px-4 mb-3">
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              {width > 900 ? 'افزودن پاراکلینیک جدید' : 'پاراکلینیک جدید'}
            </span>
          </div>
          {
            fields.length !== 0 &&
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('favoritePrescriptionModal',
                  {
                    prescriptionData: fields,
                    draftMedication: false
                  })}
                className="btn btn-dark rounded d-flex align-items-center px-4 mx-2 mb-3">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="document-add-fill"/></span>
                {width > 900 ? 'افزودن به عنوان نسخه پراستفاده' : 'نسخه پر‌استفاده'}
              </span>
            </div>
          }
        </div>
      }
      <div className="border border-black"></div>
      <AddPrescriptionParaclinicalItemModal/>
      <FavoriteParaclinicalItemModal/>
      <FavoriteParaclinicalPrescriptionModal/>
      <ParaclinicalPrescriptionHistoryModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PrescriptionParaclinicalItem);