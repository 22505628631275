import React from "react";
import { connect } from "react-redux";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";

const DeviceCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="serial">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.serial)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="device">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.device)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="imei">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.imei)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[4]}</label>
        <span className="mr-2">
          {item?.pending ?
            <div>
              <span className='bg-gray4 px-3 py-1 rounded text-nowrap'>
                در انتظار بررسی
              </span>
            </div>
            :
            <div>
              <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
                تایید شده
              </span>
            </div>
          }
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <Tooltip alt={'رد کردن'} placement='bottom' className="tooltip-pre-line">
          <span
            onClick={() => item?.pending && handleShowModal('rejectPendingDeviceModal', item?.id)}
            className={`d-flex mx-2 font-20 cur-pointer ${item?.pending ? 'text-red-hover' : 'disabled text-gray3'}`}
          >
            <Icon name='close-square'/>
          </span>
        </Tooltip>
        <Tooltip alt={'تایید کردن'} placement='bottom' className="tooltip-pre-line">
          <span
            onClick={() => item?.pending && handleShowModal('approvePendingDeviceModal', item?.id)}
            className={`d-flex r mx-2 font-20 cur-pointer ${item?.pending ? 'text-green2-hover' : 'disabled text-gray3'}`}
          >
            <Icon name='check-square'/>
          </span>
        </Tooltip>
        <Tooltip alt={'جزئیات'} placement='bottom' className="tooltip-pre-line">
          <span
            onClick={() => handleShowModal('pendingDeviceInfoModal', item?.id)}
            className="d-flex text-hover mx-2 font-20 cur-pointer"
          >
            <Icon name='eye'/>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DeviceCardsBoxItem);