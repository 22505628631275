import {getCellValue} from "../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";

type PatientAllergyTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const PatientAllergyTableRowItem = ({
  item,
  handleShowModal
}: PatientAllergyTableRowItemProps) => {


  return (
    <tr>
      <td>{getCellValue(item?.allergy?.name)}</td>
      <td>{getCellValue(item?.severity?.name)}</td>
      <td>
        {item?.reactions?.map((reaction:any, i:number) => {
          return getCellValue(reaction?.name) + ((i +1) != item?.reactions?.length ? ', ' : '')
        })}
      </td>
      <td>{getCellValue(MtoJDateFormat(item?.startDate))}</td>
      <td>{item?.endDate ? getCellValue(MtoJDateFormat(item?.endDate)) : '-'}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deletePatientAllergyModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAllergyTableRowItem);
