import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Icon from "../../components/general/icon";
import SearchBox from "../../components/general/searchBox";
import { useWindowSize } from "../../../hook/useWindowSize";
import ScheduleTable from "../../components/schedules/dataList/scheduleTable";
import ScheduleCardsBox from "../../components/schedules/dataList/scheduleCardsBox";
import { useGetSchedules } from "../../../hook/request/schedule";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";
import CloseScheduleModal from "../../components/schedules/closeScheduleModal";

const dataListTitles = [
  "نام برنامه",
  "نام پزشک",
  "تاریخ شروع",
  "تاریخ پایان",
  "وضعیت",
  "تاریخ ایجاد",
  "عملیات",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const ScheduleList = () => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [scheduleData, setScheduleData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getSchedulesRequest = useGetSchedules(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getSchedulesRequest?.data?.data?.result &&
      setScheduleData(getSchedulesRequest?.data?.data.result)
  }, [getSchedulesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی زمان‌بندی"
            />
          </div>
          <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <Link to={ROUTES.SCHEDULE_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              افزودن برنامه زمان‌بندی
            </Link>
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <ScheduleCardsBox
                dataList={scheduleData}
                dataListTitles={dataListTitles}
                isRequesting={getSchedulesRequest.isPending}
              /> :
              <ScheduleTable
                dataList={scheduleData}
                dataListTitles={dataListTitles}
                isRequesting={getSchedulesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...scheduleData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <CloseScheduleModal/>
    </>
  );
};

export default ScheduleList;