// const isLocalhost = process.env.REACT_APP_ENV?.trim() === 'localhost';

export const config = {
  apiUrl: {
    // base: "http://172.16.1.222:41041/api/v1",
    base: "https://dev-sai.hamrahdoctor.com/api/v1",
    // base: isLocalhost ? "http://172.16.1.220:41041/api/v1" : "https://dev-sai.hamrahdoctor.com/api/v1",
    // base: isLocalhost ? "https://dev-sai.hamrahdoctor.com/api/v1" : "https://dev-sai.hamrahdoctor.com/api/v1",
    // base: isLocalhost ? "http://185.120.221.124:41041/api/v1" : "https://dev-sai.hamrahdoctor.com/api/v1",
  },
  asciiMessage: `

  .d8888b.                         8888888                          
  d88P  Y88b                          888                            
  Y88b.                               888                            
   "Y888b.    8888b.   8888b.         888   888d888 8888b.  88888b.  
      "Y88b.     "88b     "88b        888   888P"      "88b 888 "88b 
        "888 .d888888 .d888888        888   888    .d888888 888  888 
  Y88b  d88P 888  888 888  888        888   888    888  888 888  888 
   "Y8888P"  "Y888888 "Y888888      8888888 888    "Y888888 888  888 
                                                                     
                                                                     
                                                                                                                                                   
  `,
};