import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import Icon from "../../../../components/general/icon";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import AddPatientVitalModal from "../../../../components/patients/ehr/vital/addPatientVitalModal";
import DeletePatientVitalModal from "../../../../components/patients/ehr/vital/deletePatientVitalModal";
import {Nav, Tab} from "react-bootstrap";
import PatientSPO2Chart from "../../../../components/patients/ehr/vital/dataCharts/patientSPO2Chart";
import PatientRespiratoryRateChart
  from "../../../../components/patients/ehr/vital/dataCharts/patientRespiratoryRateChart";
import PatientPulseRateChart from "../../../../components/patients/ehr/vital/dataCharts/patientPulseRateChart";
import PatientBloodPressureChart from "../../../../components/patients/ehr/vital/dataCharts/patientBloodPressureChart";
import PatientTemperatureChart from "../../../../components/patients/ehr/vital/dataCharts/patientTemperatureChart";

type vitalsChartsProps = {
  patientData?: any;
  initialOpenTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const VitalsCharts = ({
  patientData,
  initialOpenTab,
  handleShowModal,
  visitEHRCheck = false,
}: vitalsChartsProps) => {

  const [width] = useWindowSize();
  const { id } = useParams();
  const [openTab, setOpenTab] = useState('spo2')

  return (
    <>
      <section className="filter-box d-flex flex-column flex-md-row mb-4">
        {visitEHRCheck &&
          <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-end mb-3 m-md-0 me-md-auto">
            <button
              onClick={() => handleShowModal('addPatientVitalModal', {patientId: id, patientData: patientData})}
              className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4"
            >
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              ثبت شاخص حیاتی
            </button>
          </div>
        }
      </section>

      <section className="table-cont table-responsive d-flex">
        <Tab.Container
          id="vitals-charts-tabs"
          defaultActiveKey="spo2"
          onSelect={(eventKey) => setOpenTab(eventKey || '')}
        >
          <div className='col-2'>
            <Nav variant={'underline'}>
              <div className='flex-column w-100 font-14 vital-chart-nav'>
                <Nav.Item>
                  <Nav.Link eventKey="spo2" className="py-3">
                    اکسیژن اشباع خون
                    <span className="me-1 font-10">(٪)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="respiratoryRate" className="py-3">
                    نرخ تنفس
                    <span className="me-1 font-10">(breaths/min)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pulseRate" className="py-3">
                    ضربان قلب
                    <span className="me-1 font-10">(beats/min)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bloodPressure" className="py-3">
                    فشار خون
                    <span className="me-1 font-10">(mmHg)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="temperature" className="py-3">
                    درجه حرارت
                    <span className="me-1 font-10">(DEG C)</span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </div>
          <div className='col-10'>
            <Tab.Content>
              <Tab.Pane eventKey="spo2">
                <PatientSPO2Chart openTab={openTab} patientId={patientData?.id}/>
              </Tab.Pane>
              <Tab.Pane eventKey="respiratoryRate">
                <PatientRespiratoryRateChart openTab={openTab} patientId={patientData?.id}/>
              </Tab.Pane>
              <Tab.Pane eventKey="pulseRate">
                <PatientPulseRateChart openTab={openTab} patientId={patientData?.id}/>
              </Tab.Pane>
              <Tab.Pane eventKey="bloodPressure">
                <PatientBloodPressureChart openTab={openTab} patientId={patientData?.id}/>
              </Tab.Pane>
              <Tab.Pane eventKey="temperature">
                <PatientTemperatureChart openTab={openTab} patientId={patientData?.id}/>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
      {initialOpenTab === 'vitals' && <AddPatientVitalModal/>}
      <DeletePatientVitalModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VitalsCharts);
