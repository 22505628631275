import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJFullDateFormat} from "../../../../../../utils/date";
import Tooltip from "../../../../general/tooltip";

type PatientAppointmentCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const PatientAppointmentCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}: PatientAppointmentCardsBoxItemProps) => {

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.healthUnit?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.provider?.firstName)} {getCellValue(item?.provider?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="dateTime">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.dateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[3]}</label>
        <span className="mr-2">{getCellValue(item?.visitType?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[4]}</label>
        <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()}`}>
          {getCellValue(item?.status?.name)}
          </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[5]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            <Tooltip alt={'جزئیات'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('changeStatusAppointmentModal', item?.id)}
                className="d-flex text-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='eye'/>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAppointmentCardsBoxItem);