import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientAttachmentCardsBoxItem from "./patientAttachmentCardsBox_Item";

type PatientAttachmentsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientAttachmentCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: PatientAttachmentsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientAttachmentCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <>
      {isRequesting ?
        <div className='justify-content-center'><BoxLoader/></div> : _dataList ?
        <div className='d-flex flex-wrap'>{_dataList}</div> : <EmptyBox content={''} className={''} children={''}/>}
    </>
  )
}

export default PatientAttachmentCardsBox;