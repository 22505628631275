import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientConditionCardsBoxItem from "./patientConditionCardsBox_Item";

type PatientConditionsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientConditionCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: PatientConditionsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientConditionCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientConditionCardsBox;