import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import PatientVaccineCardsBox from "../../../../components/patients/ehr/vaccine/dataList/patientVaccineCardsBox";
import PatientVaccinesTable from "../../../../components/patients/ehr/vaccine/dataList/patientVaccinesTable";
import {useGetPatientVaccines} from "../../../../../hook/request/ehr/patientVaccine";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import AddPatientVaccineModal from "../../../../components/patients/ehr/vaccine/addPatientVaccineModal";
import DeletePatientVaccineModal from "../../../../components/patients/ehr/vaccine/deletePatientVaccineModal";


const dataListTitles = [
  "نام واکسن",
  "تولید کننده",
  "تاریخ واکسن",
  "تاریخ انقضا",
  "سری ساخت",
  "سری دوز",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type vaccinesListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const VaccinesList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: vaccinesListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [vaccinesData, setVaccinesData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getVaccinesRequest = useGetPatientVaccines(getQueryParams(filterValues), openTab === 'immunizations');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVaccinesRequest?.data?.data?.result &&
    setVaccinesData(getVaccinesRequest?.data?.data.result)
  }, [getVaccinesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی واکسیناسیون"
            />
          </div>
          {visitEHRCheck &&
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
                <span onClick={() => handleShowModal('addPatientVaccineModal', {patientId: id, patientData: patientData})}
                      className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
                  <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                  افزودن واکسیناسیون
                </span>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientVaccineCardsBox
                dataList={vaccinesData}
                dataListTitles={dataListTitles}
                isRequesting={getVaccinesRequest.isPending}
              /> :
              <PatientVaccinesTable
                dataList={vaccinesData}
                dataListTitles={dataListTitles}
                isRequesting={getVaccinesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...vaccinesData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      {openTab === 'immunizations' && <AddPatientVaccineModal/>}
      <DeletePatientVaccineModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VaccinesList);
