import {getCellValue} from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Icon from "../../general/icon";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../general/tooltip";
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import {PRESCRIPTION_STATUS_ENUM, VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../constants/constant/enum";
import {usePrescriptionInsurerSave} from "../../../../hook/request/visit/prescription";

type VisitTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const VisitTableRowItem = ({
 item,
 handleShowModal
}: VisitTableRowItemProps) => {

  const navigate = useNavigate();

  const prescriptionInsurerSaveRequest = usePrescriptionInsurerSave()

  const handleResendPrescriptionToInsurer = () => {
    prescriptionInsurerSaveRequest.mutate({id: item?.insurerStatus?.prescriptionId})
  }

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-yellow1 text-yellow'
      case 300:
        return 'bg-light-green text-green2'
      case 400:
        return 'bg-primary-subtle text-primary'
      default:
        return 'bg-light-red text-red'
    }
  }

  const determinePrescriptionBgColor = () => {
    switch (item?.insurerStatus?.status?.id) {
      case PRESCRIPTION_STATUS_ENUM.ADD_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.ADD_ERROR.value:
        return 'bg-light-red text-red'
      case PRESCRIPTION_STATUS_ENUM.EDIT_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.EDIT_ERROR.value:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>{getCellValue(item?.patient?.name)}</td>
      <td>{getCellValue(item?.provider?.name)}</td>
      <td>{getCellValue(item?.healthUnit?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.scheduledDateTime))}</bdi>
      </td>
      <td>{getCellValue(item?.visitType?.name)}</td>
      <td>
        <div>
          <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()} `}>
            {getCellValue(item?.status?.name)}
          </span>
        </div>
      </td>
      <td>
        {getCellValue(item?.trackingCode)}
      </td>
      <td>
        <Tooltip
          alt={
            (item?.insurerStatus?.status?.id === PRESCRIPTION_STATUS_ENUM.ADD_OK.value || item?.insurerStatus?.status?.id === PRESCRIPTION_STATUS_ENUM.EDIT_OK.value) ?
              `<div class="p-2">` +
                `<div><span>کد سماد: </span><span class="fw-bold">${getCellValue(item?.insurerStatus?.samadCode)}</span></div>` +
                `<div><span>کد پیگیری: </span><span class="fw-bold">${getCellValue(item?.insurerStatus?.trackingCode)}</span></div>` +
              `</div>` :
              `<div class="p-2">` +
                `<div><span>${getCellValue(item?.insurerStatus?.errorMessage ?? 'خطا در ارسال')}</span></div>` +
              `</div>`
        }
          className="tooltip-pre-line">
          <div className='d-flex align-items-center justify-content-center'>
            <div className={`d-flex  px-3 py-1 rounded text-nowrap ${determinePrescriptionBgColor()} `}>
              <span className='fw-bold mx-1'>{getCellValue(item?.insurerStatus?.issuerType?.name)}</span>
              <span className='mx-1'>{getCellValue(item?.insurerStatus?.status?.name)}</span>
              {(item?.insurerStatus?.status?.id === PRESCRIPTION_STATUS_ENUM.ADD_ERROR.value || item?.insurerStatus?.status?.id === PRESCRIPTION_STATUS_ENUM.EDIT_ERROR.value) &&
                <span className='d-flex align-items-center text-gray text-hover font-20 mx-1'
                      onClick={() => handleResendPrescriptionToInsurer()}
                >
                  <Icon name='refresh'/>
                </span>
              }
            </div>
          </div>
        </Tooltip>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Tooltip alt={"پذیرش"} className="tooltip-pre-line">
            <span
              onClick={() =>
              item?.status?.id === VISIT_STATUS_ENUM.RESERVED.value &&
                handleShowModal('changeStatusAppointmentModal', {id: item?.id})
              }
              className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.RESERVED.value ? 'text-hover' : 'disabled text-gray3'}`}
            >
              <Icon name='stethoscope'/>
            </span>
          </Tooltip>
          <Tooltip alt={"ویرایش"} className="tooltip-pre-line">
            <span
              onClick={() =>
                item?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value &&
                navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: item?.id}), {state: {mode: VISIT_MODE.EDIT.value}})
              }
              className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value ? 'text-hover' : 'disabled text-gray3'}`}
            >
              <Icon name='pen-square'/>
            </span>
          </Tooltip>
          <Tooltip alt={"مشاهده ویزیت"} className="tooltip-pre-line">
            <span
              onClick={() =>
                item?.status?.id === VISIT_STATUS_ENUM.RESOLVED.value &&
                navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: item?.id}), {state: {mode: VISIT_MODE.SHOW.value}})
              }
              className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.RESOLVED.value ? 'text-hover' : 'disabled text-gray3'}`}
            >
              <Icon name='document'/>
            </span>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitTableRowItem);
