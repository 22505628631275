import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ExaminationService} from "../../../services/requests/visit/examination";

const useGetExaminationList = (params: any) => {
  return useQuery({
    queryKey: ["getExaminationList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ExaminationService.getExaminationList(params);
    },
  });
}

const useExaminationDetail = () => {
  return useMutation({
    mutationFn: ExaminationService.examinationDetail,
  })
}

const useECGLead = () => {
  return useMutation({
    mutationFn: ExaminationService.ECGLead,
  })
}

const useECGSegment = () => {
  return useMutation({
    mutationFn: ExaminationService.ECGSegment,
  })
}

export {
  useGetExaminationList,
  useExaminationDetail,
  useECGLead,
  useECGSegment
}
