import React, { useEffect, useMemo, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxLoader from "../../../components/general/boxLoader";
import TextField from "../../../components/general/form/textField";
import {AsyncSelectField} from "../../../components/general/form/selectField";
import {ROUTES} from "../../../../constants";
import TextAreaField from "../../../components/general/form/textAreaField";
import {TAddConditionFields} from "../../../../types/condition";
import {addConditionSchema} from "../../../../schema/condition-schema";
import {useAddCondition, useGetCondition} from "../../../../hook/request/condition";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {useGetMAXAList} from "../../../../hook/request/maxa";
import {asyncSelectLoadOptions} from "../../../../utils/asyncSelectLoadOptions";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}


const AddCondition = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [fieldValue, setFieldValue] = useState<TAddConditionFields>({
    name: '',
    description: '',
    maxa: ''
  });
  const [maxaList, setMAXAList] = useState([]);
  const [MAXAFilterValues, setMAXAFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddConditionFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addConditionSchema),
  });


  // services
  const getConditionRequest = useGetCondition();
  const addConditionRequest = useAddCondition();
  const getMAXAListRequest = useGetMAXAList(getQueryParams(MAXAFilterValues));


  useEffect(() => {
    id && getConditionRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getConditionRequest?.data?.data?.result) {
      const { name, description, maxa } = getConditionRequest.data.data.result,
        _fieldValue = {
          name, description, maxa
        };
      setFieldValue(_fieldValue);
    }
  }, [getConditionRequest.data])

  useEffect(() => {
    let _MAXAFilterValues = {};
    _MAXAFilterValues = {
      ...MAXAFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setMAXAFilterValues(_MAXAFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getMAXAListRequest?.data?.data?.result) {
      const { data } = getMAXAListRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.value, value: item?.id }
        ))
      setMAXAList(_data);
    }
  }, [getMAXAListRequest.data])

  const maxaListLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, MAXAFilterValues, setMAXAFilterValues, getMAXAListRequest,
      (item) => ({ label: item?.value, value: item?.id })
    );
  };

  const addConditionSubmit: SubmitHandler<TAddConditionFields> = (data) => {
    const { name, description, maxa } = data,
      body = {
        ...(id ? {id} : {}),
        name, description,
        maxa: {
          id: maxa,
          name: ''
        }
      };
    addConditionRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getConditionRequest?.isPending ||
          addConditionRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addConditionSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات شرایط پزشکی</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام</label>
                <TextField
                  name="name"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="maxa">مکسا</label>
                <AsyncSelectField
                  options={maxaList}
                  name="maxa"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                  asyncLoadOptions={maxaListLoadOptions}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  placeholder="توضیاحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.CONDITION?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  { id ? "ویرایش شرایط پزشکی" : "افزودن شرایط پزشکی" }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCondition;