import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import PatientAllergyCardsBox from "../../../../components/patients/ehr/allergy/dataList/patientAllergyCardsBox";
import PatientAllergiesTable from "../../../../components/patients/ehr/allergy/dataList/patientAllergiesTable";
import {useGetPatientAllergies} from "../../../../../hook/request/ehr/patientAllergy";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import DeletePatientAllergyModal from "../../../../components/patients/ehr/allergy/deletePatientAllergyModal";
import AddPatientAllergyModal from "../../../../components/patients/ehr/allergy/addPatientAllergyModal";


const dataListTitles = [
  "نام حساسیت",
  "شدت",
  "واکنش ها",
  "تاریخ شروع",
  "تاریخ پایان",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type allergiesListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const AllergiesList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: allergiesListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [allergiesData, setAllergiesData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getAllergiesRequest = useGetPatientAllergies(getQueryParams(filterValues), openTab === 'allergies');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getAllergiesRequest?.data?.data?.result &&
    setAllergiesData(getAllergiesRequest?.data?.data.result)
  }, [getAllergiesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی حساسیت"
            />
          </div>
          {visitEHRCheck &&
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span onClick={() => openTab === 'allergies' && handleShowModal('addPatientAllergyModal', {
                patientId: id,
                patientData: patientData
              })}
                    className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن حساسیت
              </span>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientAllergyCardsBox
                dataList={allergiesData}
                dataListTitles={dataListTitles}
                isRequesting={getAllergiesRequest.isPending}
              /> :
              <PatientAllergiesTable
                dataList={allergiesData}
                dataListTitles={dataListTitles}
                isRequesting={getAllergiesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...allergiesData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      {openTab === 'allergies' && <AddPatientAllergyModal/>}
      <DeletePatientAllergyModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AllergiesList);
