import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants";
import SelectField from "../../components/general/form/selectField";
import {useForm} from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

const selectOptions = [
  {
    label: ROUTES.MEDICATION.TITLE,
    value: ROUTES.MEDICATION.PATH
  },
  {
    label: ROUTES.MEDICATION_CATEGORY.TITLE,
    value: ROUTES.MEDICATION_CATEGORY.PATH
  },
  {
    label: ROUTES.VACCINE.TITLE,
    value: ROUTES.VACCINE.PATH
  },
  {
    label: ROUTES.CONDITION.TITLE,
    value: ROUTES.CONDITION.PATH
  },
  {
    label: ROUTES.PROGRAM.TITLE,
    value: ROUTES.PROGRAM.PATH
  },
  // {
  //   label: ROUTES.IMAGING_SERVICE.TITLE,
  //   value: ROUTES.IMAGING_SERVICE.PATH
  // },
  // {
  //   label: ROUTES.LABORATORY_SERVICE.TITLE,
  //   value: ROUTES.LABORATORY_SERVICE.PATH
  // },
  // {
  //   label: ROUTES.LABORATORY_SERVICE_CATEGORY.TITLE,
  //   value: ROUTES.LABORATORY_SERVICE_CATEGORY.PATH
  // },
  // {
  //   label: ROUTES.MENU.TITLE,
  //   value: ROUTES.MENU.PATH
  // },
]

const BasicInformation = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [fieldValue, setFieldValue] = useState({
    pageChooser: "/setting/prescription"
  });
  const {
    control,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    setFieldValue({
      pageChooser: location.pathname
    })
  }, [location]);

  const changePage = (data: any) => {
    navigate(data.target.value)
  }

  return (
    <div className="bg-primary bg-opacity-10 rounded pt-4">
      <SelectField
        options={selectOptions}
        name="pageChooser"
        placeholder='صفحه راانتخاب کنید'
        className='w-25 me-5 mb-4'
        control={control}
        error={errors}
        onChange={changePage}
      />
      <Outlet/>
    </div>
  )
}

export default BasicInformation;