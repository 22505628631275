import { combineReducers } from 'redux';
import errorReducer from './error';
import modalReducer from './modal';
import videoCallIdReducer from "../videoCall/videoCallId";
import userDataReducer from "./userData";

const general = combineReducers({
  errorReducer,
  modalReducer,
  videoCallIdReducer,
  userDataReducer
});

export default general;
