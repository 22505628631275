import React, {useMemo} from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../components/general/modal";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector,
} from "../../../../../../store/selector/general";
import { general } from "../../../../../../store/action";
import { Dispatch } from "redux";

// icons
import Icon from "../../../../../components/general/icon";
import TextField from "../../../../../components/general/form/textField";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectField from "../../../../../components/general/form/selectField";
import SwitchField from "../../../../../components/general/form/switchField";
import { useMySearchParams } from "../../../../../../hook/useMySearchParams";
import {
  useAddFamilyHistory,
  useGetFamilyRelation,
} from "../../../../../../hook/request/ehr/familyHistory";
import { useGetIcd11List } from "../../../../../../hook/request/visit/icd11";
import {patientFamilyHistorySchema} from "../../../../../../schema/patient-schema";

// کامپوننت مربوط مودال اتصال بیمه  که پزشک می تواند با
// اطلاعات که وارد می کند  ارتباط خود را به بیمه مورد نظر متصل کند
// Parent:SettingInsurance
// در این کامپوننت لیست بیمه ها رندر می شود
// و در صورت انتخاب هر بیمه با توجه با فعال یا غیر فعال بودن ان نمایش داده می شود

type IModalInfo = {
  relation?: string;
  icd11?: string;
  deceased?:boolean
  description?:string
}

type ModalInfoProps = {
  id: number;
  name: string;
  patientId: number;
  handleGetPatientSummary: any;
}

type AddPatientFamilyHistoryModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const AddPatientFamilyHistoryModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientFamilyHistoryModalProps) => {

  const [showState, setShowState] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const [death, setDeath] = useState(false);
  const [familyRelation, setFamilyRelation] = useState([]);
  const [icd11List,setIcd11List] = useState([])
  const { getQueryParams } = useMySearchParams();
  const [icd11FilterValues, setIcd11FilterValues] = useState({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const fieldValueInitialState = {
    relation: '',
    icd11: '',
    deceased:false,
    description:''
  }
  const [fieldValue, setFieldValue] = useState<IModalInfo>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IModalInfo>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    // @ts-ignore
    resolver: yupResolver(patientFamilyHistorySchema),
  });

  // services
  const getFamilyRelation = useGetFamilyRelation("", modalName === 'showAddFamily');
  const getIcd11ListRequest = useGetIcd11List(
    getQueryParams(icd11FilterValues),
    modalName === "showAddFamily"
  );
  const addHistory = useAddFamilyHistory({
    onSuccess: () => {
      handleSetShowModal();
      modalInfo?.handleGetPatientSummary({id: modalInfo?.patientId});
    },
  });

  useEffect(() => {
    if (modalName === "showAddFamily" && { isShowModalData }) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalName, modalData]);

  useEffect(() => {
    if (getFamilyRelation?.data?.data?.result) {
      setFamilyRelation(
        getFamilyRelation?.data.data.result.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
  }, [getFamilyRelation?.data]);

  useEffect(() => {
    if (getIcd11ListRequest?.data?.data?.result) {
      setIcd11List(
        getIcd11ListRequest?.data?.data.result.data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }

  }, [getIcd11ListRequest?.data]);

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  const handleSubmitInsurer: SubmitHandler<IModalInfo> = (data) => {
    console.log(data)
    const body = {
      patient: {
        id: modalInfo?.patientId,
      },
      relation: {
        id: data?.relation,
      },
      icd11: {
        id: data?.icd11,
      },
      description: data?.description,
      deceased: death,
    };
    addHistory.mutate(body);
  };

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
    setFieldValue(fieldValueInitialState)
  };


  const handleDeath = (e: any) => {
    setDeath(!death);
  };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000  mw-100"
      bodyClassName="py-3 px-3 px-lg-5 pos-rel"
    >
      {/* loading */}
      {/* {providerInsurer?.isPending && <BoxLoader type="cover" />} */}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className="d-flex mb-4 fs-3 px-2">
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {/* اتصال {modalInfo?.name} */}
              فرم ثبت سوابق خانوادگی
            </h4>
          </div>
          <span
            className="text-red-hover align-content-center mt-2"
            onClick={() => handleSetShowModal()}
          >
            <Icon name="close-square" />
          </span>
        </div>
        <form onSubmit={handleSubmit(handleSubmitInsurer)}>
          <div className="row">
            <div className="col-12 col-md-6 px-3">
              <div className="form-group mb-3">
                <SelectField
                  name="relation"
                  placeholder="نسبت خانوادگی"
                  className="font-en"
                  control={control}
                  error={errors}
                  options={familyRelation}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 px-3">
              <div className="form-group mb-3">
                <SelectField
                  name="icd11"
                  placeholder="سابقۀ بیماری"
                  className="font-en"
                  control={control}
                  error={errors}
                  options={icd11List}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 px-3">
              <div
                className="form-group form-control mb-3 d-flex justify-content-between align-items-center"
                style={{ paddingTop: "12px", paddingBottom: "12px" }}
              >
                <span className="font-10 font-weight-medium text-gray">
                  آیا منجر به فوت شده است ؟
                </span>
                <SwitchField
                  onChange={(e) => handleDeath(e)}
                  value={death}
                  name="deceased"
                  label={""}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 px-3">
              <div className="form-group mb-3">
                <TextField
                  name="description"
                  placeholder="ثبت توضیحات (اختیاری)"
                  className="font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-3 d-flex justify-content-end">
              <div className="d-flex form-group mb-0 mr-auto mt-auto pb-1">
                <button
                  type="submit"
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ثبت سابقه جدید
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

// get states
const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

// call actions
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPatientFamilyHistoryModal);
