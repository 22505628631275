import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { general } from "../../../../../store/action";
import {getCellValue, handleChange} from "../../../../../utils/utils";
import {useAddRelatedOrganization} from "../../../../../hook/request/organizations";
import SwitchField from "../../../general/form/switchField";

const AddRelationCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const statusCodeColorArr = ['', 'text-gray', 'text-green2', 'text-red'];

  const { id } = useParams();
  const [fieldValue, setFieldValue] = useState({
    related: item?.related
  });

  useEffect(() => {
    setFieldValue({
      related: item?.related
    });
  }, [item?.related]);

  // services
  const addRelatedOrganizationRequest = useAddRelatedOrganization();

  const addRelatedSubmit = (e) => {
    handleChange(e, setFieldValue, fieldValue);
    const { checked: _value } = e.target,
      body = {
        related: _value,
        originUnit: { id },
        relatedUnit: { id: item?.id }
      };
    addRelatedOrganizationRequest.mutate(body)
  }

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="admin">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.telNo)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="userName">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.address)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="birthDate">{dataListTitles[3]}</label>
        <div className="d-inline-flex justify-content-center pos-rel">
          {
            (
              addRelatedOrganizationRequest?.isPending
            ) && (
              <div
                className='position-absolute top-0 start-0 end-0 bottom-0 translate-middless d-flex align-items-center justify-content-center bg-secondary bg-opacity-50 rounded zIndex-1'>
                <div className="spinner-border spinner-border-sm"></div>
              </div>
            )
          }
          <SwitchField
            onChange={addRelatedSubmit}
            value={fieldValue?.related}
            name="related"
          />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AddRelationCardsBoxItem);